.header-middle li{
  list-style: none;
}
.call{
  a{
    color: $info;
    text-decoration: none;
  }
}
.chat{
  a{
    color: $success;
    text-decoration: none;
  }
}
.news-block{
  max-height: 250px;
}

.news-block::-webkit-scrollbar {
  -webkit-appearance: none;
}

.news-block::-webkit-scrollbar:vertical {
  width: 11px;
}

.news-block::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}
.check_text {
  position: relative;
  p {
    position: absolute;
    top: 10px;
    left: 102px;
    font-size: 0.9rem;
    line-height: 1.2;
  }
  span{
    color: $orange;
  }
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}
.mt-n14 {
  margin-top: -4.5rem !important;
}
@media (width >= 576px) {
  .mt-sm-n45 {
    margin-top: -4.5rem !important;
  }
}

.footer-nav {
  margin-top: 15px;
  padding: 0;
  li{
    display: inline;
    list-style: none;
  }
  li a {
    color: $white;
    margin-right: 5px;
    font-size: 0.85em;
    line-height: 0.7;
    transition: color linear .2s;
    text-decoration: none;

    &:hover{
      color: $orange;
    }
  }
}
.navbar-dark .navbar-toggler-icon {
  background-image: var(--mdb-navbar-toggler-icon-bg);
}