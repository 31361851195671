/*!
* MDB5
* Version: PRO 8.0.0
*
*
* Copyright: Material Design for Bootstrap
* https://mdbootstrap.com/
*
* Read the license: https://mdbootstrap.com/general/license/
*
*
* Documentation: https://mdbootstrap.com/docs/standard/
*
* Support: https://mdbootstrap.com/support/
*
* Contact: contact@mdbootstrap.com
*
*/
:root {
  --mdb-red: #f44336;
  --mdb-pink: #e91e63;
  --mdb-purple: #9c27b0;
  --mdb-indigo: #3f51b5;
  --mdb-blue: #2196f3;
  --mdb-cyan: #00bcd4;
  --mdb-teal: #009688;
  --mdb-green: #4caf50;
  --mdb-yellow: #ffeb3b;
  --mdb-orange: #ff9800;
  --mdb-white: #fff;
  --mdb-black: #000;
  --mdb-gray: #757575;
  --mdb-gray-dark: #4f4f4f;
  --mdb-gray-50: #fbfbfb;
  --mdb-gray-100: #f5f5f5;
  --mdb-gray-200: #eee;
  --mdb-gray-300: #e0e0e0;
  --mdb-gray-400: #bdbdbd;
  --mdb-gray-500: #9e9e9e;
  --mdb-gray-600: #757575;
  --mdb-gray-700: #616161;
  --mdb-gray-800: #4f4f4f;
  --mdb-gray-900: #262626;
  --mdb-primary: #3b71ca;
  --mdb-secondary: #9fa6b2;
  --mdb-success: #14a44d;
  --mdb-danger: #dc4c64;
  --mdb-warning: #e4a11b;
  --mdb-info: #54b4d3;
  --mdb-light: #fbfbfb;
  --mdb-dark: #332d2d;
  --mdb-primary-rgb: 59, 113, 202;
  --mdb-secondary-rgb: 159, 166, 178;
  --mdb-success-rgb: 20, 164, 77;
  --mdb-danger-rgb: 220, 76, 100;
  --mdb-warning-rgb: 228, 161, 27;
  --mdb-info-rgb: 84, 180, 211;
  --mdb-light-rgb: 251, 251, 251;
  --mdb-dark-rgb: 51, 45, 45;
  --mdb-primary-text-emphasis: #2f5aa2;
  --mdb-secondary-text-emphasis: #404247;
  --mdb-success-text-emphasis: #0c622e;
  --mdb-info-text-emphasis: #3b7e94;
  --mdb-warning-text-emphasis: #896110;
  --mdb-danger-text-emphasis: #b03d50;
  --mdb-light-text-emphasis: #616161;
  --mdb-dark-text-emphasis: #eee;
  --mdb-primary-bg-subtle: #e2eaf7;
  --mdb-secondary-bg-subtle: #f1f2f3;
  --mdb-success-bg-subtle: #dcf1e4;
  --mdb-info-bg-subtle: #e5f4f8;
  --mdb-warning-bg-subtle: #fbf1dd;
  --mdb-danger-bg-subtle: #fae4e8;
  --mdb-light-bg-subtle: #f5f5f5;
  --mdb-dark-bg-subtle: #262626;
  --mdb-primary-border-subtle: #b1c6ea;
  --mdb-secondary-border-subtle: #d9dbe0;
  --mdb-success-border-subtle: #a1dbb8;
  --mdb-info-border-subtle: #bbe1ed;
  --mdb-warning-border-subtle: #f4d9a4;
  --mdb-danger-border-subtle: #f1b7c1;
  --mdb-light-border-subtle: #eee;
  --mdb-dark-border-subtle: #9e9e9e;
  --mdb-white-rgb: 255, 255, 255;
  --mdb-black-rgb: 0, 0, 0;
  --mdb-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mdb-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --mdb-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --mdb-body-font-family: var(--mdb-font-roboto);
  --mdb-body-font-size: 1rem;
  --mdb-body-font-weight: 400;
  --mdb-body-line-height: 1.6;
  --mdb-body-color: #4f4f4f;
  --mdb-body-color-rgb: 79, 79, 79;
  --mdb-body-bg: #fff;
  --mdb-body-bg-rgb: 255, 255, 255;
  --mdb-emphasis-color: #000;
  --mdb-emphasis-color-rgb: 0, 0, 0;
  --mdb-secondary-color: #4f4f4fbf;
  --mdb-secondary-color-rgb: 79, 79, 79;
  --mdb-secondary-bg: #eee;
  --mdb-secondary-bg-rgb: 238, 238, 238;
  --mdb-tertiary-color: #4f4f4f80;
  --mdb-tertiary-color-rgb: 79, 79, 79;
  --mdb-tertiary-bg: #fbfbfb;
  --mdb-tertiary-bg-rgb: 251, 251, 251;
  --mdb-heading-color: inherit;
  --mdb-link-color: #3b71ca;
  --mdb-link-color-rgb: 59, 113, 202;
  --mdb-link-decoration: none;
  --mdb-link-hover-color: #386bc0;
  --mdb-link-hover-color-rgb: 56, 107, 192;
  --mdb-link-hover-decoration: none;
  --mdb-code-color: #e91e63;
  --mdb-highlight-color: #4f4f4f;
  --mdb-highlight-bg: #fff9c4;
  --mdb-border-width: 1px;
  --mdb-border-style: solid;
  --mdb-border-color: #e0e0e0;
  --mdb-border-color-translucent: #0000002d;
  --mdb-border-radius: .25rem;
  --mdb-border-radius-sm: .25rem;
  --mdb-border-radius-lg: .5rem;
  --mdb-border-radius-xl: 1rem;
  --mdb-border-radius-xxl: 2rem;
  --mdb-border-radius-2xl: var(--mdb-border-radius-xxl);
  --mdb-border-radius-pill: 50rem;
  --mdb-box-shadow: 0 .5rem 1rem #00000026;
  --mdb-box-shadow-sm: 0 .125rem .25rem #00000013;
  --mdb-box-shadow-lg: 0 1rem 3rem #0000002d;
  --mdb-box-shadow-inset: inset 0 1px 2px #00000013;
  --mdb-focus-ring-width: .25rem;
  --mdb-focus-ring-opacity: .25;
  --mdb-focus-ring-color: #3b71ca40;
  --mdb-form-valid-color: #14a44d;
  --mdb-form-valid-border-color: #14a44d;
  --mdb-form-invalid-color: #dc4c64;
  --mdb-form-invalid-border-color: #dc4c64;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: Roboto, sans-serif;
  font-family: var(--mdb-body-font-family);
  font-size: 1rem;
  font-size: var(--mdb-body-font-size);
  font-weight: 400;
  font-weight: var(--mdb-body-font-weight);
  line-height: 1.6;
  line-height: var(--mdb-body-line-height);
  color: var(--mdb-body-color);
  text-align: var(--mdb-body-text-align);
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: 1px solid;
  border-top: var(--mdb-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--mdb-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--mdb-link-color-rgb), var(--mdb-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --mdb-link-color-rgb: var(--mdb-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-family: var(--mdb-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--mdb-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--mdb-secondary-color);
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #757575;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  border: 1px solid #e0e0e0;
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  border-radius: .25rem;
  border-radius: var(--mdb-border-radius);
  box-shadow: 0 .125rem .25rem #00000013;
  box-shadow: var(--mdb-box-shadow-sm);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.container, .container-fluid, .container-lg {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  padding-right: .75rem;
  padding-right: calc(var(--mdb-gutter-x) * .5);
  padding-left: .75rem;
  padding-left: calc(var(--mdb-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-lg, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-lg, .container {
    max-width: 1320px;
  }
}

:root {
  --mdb-breakpoint-xs: 0;
  --mdb-breakpoint-sm: 576px;
  --mdb-breakpoint-md: 768px;
  --mdb-breakpoint-lg: 992px;
  --mdb-breakpoint-xl: 1200px;
  --mdb-breakpoint-xxl: 1400px;
}

.row {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  margin-top: 0;
  margin-top: calc(-1 * var(--mdb-gutter-y));
  margin-right: -.75rem;
  margin-right: calc(-.5 * var(--mdb-gutter-x));
  margin-left: -.75rem;
  margin-left: calc(-.5 * var(--mdb-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--mdb-gutter-x) * .5);
  padding-left: calc(var(--mdb-gutter-x) * .5);
  margin-top: var(--mdb-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.g-1 {
  --mdb-gutter-x: .25rem;
  --mdb-gutter-y: .25rem;
}

.g-3 {
  --mdb-gutter-x: 1rem;
  --mdb-gutter-y: 1rem;
}

.g-5 {
  --mdb-gutter-x: 3rem;
  --mdb-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }
}

@media (width >= 768px) {
  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }
}

@media (width >= 992px) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }
}

@media (width >= 1200px) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }
}

.table {
  --mdb-table-color-type: initial;
  --mdb-table-bg-type: initial;
  --mdb-table-color-state: initial;
  --mdb-table-bg-state: initial;
  --mdb-table-color: var(--mdb-body-color);
  --mdb-table-bg: var(--mdb-body-bg);
  --mdb-table-border-color: var(--mdb-border-color);
  --mdb-table-accent-bg: transparent;
  --mdb-table-striped-color: var(--mdb-body-color);
  --mdb-table-striped-bg: #00000005;
  --mdb-table-active-color: var(--mdb-body-color);
  --mdb-table-active-bg: rgba(var(--mdb-emphasis-color-rgb), .1);
  --mdb-table-hover-color: var(--mdb-body-color);
  --mdb-table-hover-bg: #00000006;
  vertical-align: top;
  border-color: #e0e0e0;
  border-color: var(--mdb-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--mdb-table-color-state, var(--mdb-table-color-type, var(--mdb-table-color)));
  background-color: var(--mdb-table-bg);
  border-bottom-width: 1px;
  border-bottom-width: var(--mdb-border-width);
  box-shadow: inset 0 0 0 9999px var(--mdb-table-bg-state, var(--mdb-table-bg-type, var(--mdb-table-accent-bg)));
  padding: 1rem 1.4rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-sm > :not(caption) > * > * {
  padding: .5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
  border-width: var(--mdb-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
  border-width: 0 var(--mdb-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --mdb-table-color-type: var(--mdb-table-striped-color);
  --mdb-table-bg-type: var(--mdb-table-striped-bg);
}

.table-hover > tbody > tr:hover > * {
  --mdb-table-color-state: var(--mdb-table-hover-color);
  --mdb-table-bg-state: var(--mdb-table-hover-bg);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.form-label {
  color: var(--mdb-form-control-label-color);
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-top: calc(.375rem + var(--mdb-border-width));
  padding-bottom: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + var(--mdb-border-width));
  font-size: inherit;
  color: var(--mdb-form-control-label-color);
  margin-bottom: 0;
  line-height: 1.6;
}

.form-text {
  color: var(--mdb-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: var(--mdb-surface-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  border: 1px solid #e0e0e0;
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  border-radius: .25rem;
  border-radius: var(--mdb-border-radius);
  box-shadow: inset 0 1px 2px #00000013;
  box-shadow: var(--mdb-box-shadow-inset);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  transition: all .2s linear;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--mdb-surface-color);
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  border-color: #3b71ca;
  border-color: var(--mdb-input-focus-border-color);
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #3b71ca40;
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem #3b71ca40;
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.6em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: rgba(var(--mdb-surface-color-rgb), .8);
  opacity: 1;
}

.form-control:disabled {
  background-color: #eee;
  background-color: var(--mdb-secondary-bg);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  color: var(--mdb-surface-color);
  background-color: #fbfbfb;
  background-color: var(--mdb-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  margin: -.375rem -.75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  color: var(--mdb-surface-color);
  background-color: #fbfbfb;
  background-color: var(--mdb-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  margin: -.375rem -.75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  border-right-width: 1px;
  border-right-width: var(--mdb-border-width);
  margin-right: .75rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: 1px;
  border-right-width: var(--mdb-border-width);
  margin-right: .75rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: 1px;
  border-right-width: var(--mdb-border-width);
  margin-right: .75rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: 1px;
  border-left-width: var(--mdb-border-width);
  margin-left: .75rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: 1px;
  border-left-width: var(--mdb-border-width);
  margin-left: .75rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: 1px;
  border-left-width: var(--mdb-border-width);
  margin-left: .75rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #eee;
  background-color: var(--mdb-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #eee;
  background-color: var(--mdb-secondary-bg);
}

.form-control-sm {
  min-height: calc(1.6em + .5rem + calc(var(--mdb-border-width) * 2));
  border-radius: .25rem;
  border-radius: var(--mdb-border-radius-sm);
  min-height: calc(1.6em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .775rem;
}

.form-control-sm::-webkit-file-upload-button {
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: .5rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .5rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .5rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .5rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .5rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--mdb-border-width) * 2));
  border-radius: .5rem;
  border-radius: var(--mdb-border-radius-lg);
  min-height: calc(1.6em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: 1rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1rem;
}

textarea.form-control {
  min-height: calc(1.6em + .75rem + calc(var(--mdb-border-width) * 2));
  min-height: calc(1.6em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.6em + .5rem + calc(var(--mdb-border-width) * 2));
  min-height: calc(1.6em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--mdb-border-width) * 2));
  min-height: calc(1.6em + 1rem + 2px);
}

.form-select {
  --mdb-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234f4f4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: var(--mdb-surface-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234f4f4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), none;
  background-image: var(--mdb-form-select-bg-img), var(--mdb-form-select-bg-icon, none);
  border: 1px solid #e0e0e0;
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  border-radius: .25rem;
  border-radius: var(--mdb-border-radius);
  box-shadow: inset 0 1px 2px #00000013;
  box-shadow: var(--mdb-box-shadow-inset);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  transition: all .2s linear;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #3b71ca40;
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem #3b71ca40;
  border-color: #3b71ca;
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #eee;
  background-color: var(--mdb-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--mdb-surface-color);
}

.form-check {
  min-height: 1.6rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  --mdb-form-check-bg: var(--mdb-body-bg);
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: var(--mdb-form-check-bg);
  background-image: var(--mdb-form-check-bg-image);
  border: 1px solid #e0e0e0;
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .3em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #3b71ca;
  border-color: var(--mdb-input-focus-border-color);
  outline: 0;
  box-shadow: 0 0 0 .25rem #3b71ca40;
}

.form-check-input:checked {
  background-color: #3b71ca;
  border-color: #3b71ca;
}

.form-check-input:checked[type="checkbox"] {
  --mdb-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --mdb-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --mdb-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #3b71ca;
  border-color: #757575;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--mdb-surface-color);
  text-align: center;
  white-space: nowrap;
  background-color: #fbfbfb;
  background-color: var(--mdb-tertiary-bg);
  border: 1px solid #e0e0e0;
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  border-radius: .25rem;
  border-radius: var(--mdb-border-radius);
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  display: flex;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  margin-left: calc(var(--mdb-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--mdb-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #14a44d;
  border-color: var(--mdb-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2314a44d' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.4em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #14a44d;
  border-color: var(--mdb-form-valid-border-color);
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #14a44d40;
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem rgba(var(--mdb-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.4em + .1875rem) top calc(.4em + .1875rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #14a44d;
  border-color: var(--mdb-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --mdb-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2314a44d' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #14a44d;
  border-color: var(--mdb-form-valid-border-color);
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #14a44d40;
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem rgba(var(--mdb-success-rgb), .25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #14a44d;
  border-color: var(--mdb-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #14a44d;
  background-color: var(--mdb-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #14a44d40;
  box-shadow: 0 0 0 .25rem rgba(var(--mdb-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--mdb-form-valid-color);
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--mdb-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc4c64;
  border-color: var(--mdb-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc4c64'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc4c64' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.4em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc4c64;
  border-color: var(--mdb-form-invalid-border-color);
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #dc4c6440;
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem rgba(var(--mdb-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.4em + .1875rem) top calc(.4em + .1875rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc4c64;
  border-color: var(--mdb-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --mdb-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc4c64'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc4c64' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc4c64;
  border-color: var(--mdb-form-invalid-border-color);
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #dc4c6440;
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem rgba(var(--mdb-danger-rgb), .25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc4c64;
  border-color: var(--mdb-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc4c64;
  background-color: var(--mdb-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc4c6440;
  box-shadow: 0 0 0 .25rem rgba(var(--mdb-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--mdb-form-invalid-color);
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid {
  z-index: 4;
}

.btn {
  --mdb-btn-padding-x: 1.5rem;
  --mdb-btn-padding-y: .375rem;
  --mdb-btn-font-family: ;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-font-weight: 500;
  --mdb-btn-line-height: 1.5;
  --mdb-btn-color: var(--mdb-body-color);
  --mdb-btn-bg: transparent;
  --mdb-btn-border-width: 2px;
  --mdb-btn-border-color: transparent;
  --mdb-btn-border-radius: .25rem;
  --mdb-btn-hover-border-color: transparent;
  --mdb-btn-box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  --mdb-btn-disabled-opacity: .65;
  --mdb-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--mdb-btn-focus-shadow-rgb), .5);
  padding: .375rem 1.5rem;
  padding: var(--mdb-btn-padding-y) var(--mdb-btn-padding-x);
  font-family: ;
  font-family: var(--mdb-btn-font-family);
  font-size: .75rem;
  font-size: var(--mdb-btn-font-size);
  font-weight: 500;
  font-weight: var(--mdb-btn-font-weight);
  line-height: 1.5;
  line-height: var(--mdb-btn-line-height);
  color: var(--mdb-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 2px solid #0000;
  border: var(--mdb-btn-border-width) solid var(--mdb-btn-border-color);
  border-radius: .25rem;
  border-radius: var(--mdb-btn-border-radius);
  background-color: #0000;
  background-color: var(--mdb-btn-bg);
  box-shadow: 0 4px 9px -4px #00000059;
  box-shadow: var(--mdb-btn-box-shadow);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
  border-color: var(--mdb-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--mdb-btn-color);
  background-color: var(--mdb-btn-bg);
  border-color: var(--mdb-btn-border-color);
}

.btn:focus-visible {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
  border-color: var(--mdb-btn-hover-border-color);
  box-shadow: var(--mdb-btn-box-shadow), var(--mdb-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--mdb-btn-hover-border-color);
  box-shadow: var(--mdb-btn-box-shadow), var(--mdb-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--mdb-btn-active-color);
  background-color: var(--mdb-btn-active-bg);
  border-color: var(--mdb-btn-active-border-color);
  box-shadow: var(--mdb-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn-check:checked:focus-visible + .btn {
  box-shadow: var(--mdb-btn-active-shadow), var(--mdb-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled {
  color: var(--mdb-btn-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-btn-disabled-bg);
  border-color: var(--mdb-btn-disabled-border-color);
  opacity: var(--mdb-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #3b71ca;
  --mdb-btn-border-color: #3b71ca;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #386bc0;
  --mdb-btn-hover-border-color: #2f5aa2;
  --mdb-btn-focus-shadow-rgb: 88, 134, 210;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #3566b6;
  --mdb-btn-active-border-color: #2c5598;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #3b71ca;
  --mdb-btn-disabled-border-color: #3b71ca;
}

.btn-secondary {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #9fa6b2;
  --mdb-btn-border-color: #9fa6b2;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #979ea9;
  --mdb-btn-hover-border-color: #7f858e;
  --mdb-btn-focus-shadow-rgb: 173, 179, 190;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #8f95a0;
  --mdb-btn-active-border-color: #777d86;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #9fa6b2;
  --mdb-btn-disabled-border-color: #9fa6b2;
}

.btn-success {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #14a44d;
  --mdb-btn-border-color: #14a44d;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #139c49;
  --mdb-btn-hover-border-color: #10833e;
  --mdb-btn-focus-shadow-rgb: 55, 178, 104;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #129445;
  --mdb-btn-active-border-color: #0f7b3a;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #14a44d;
  --mdb-btn-disabled-border-color: #14a44d;
}

.btn-warning {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #e4a11b;
  --mdb-btn-border-color: #e4a11b;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #d9991a;
  --mdb-btn-hover-border-color: #b68116;
  --mdb-btn-focus-shadow-rgb: 232, 175, 61;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #cd9118;
  --mdb-btn-active-border-color: #ab7914;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #e4a11b;
  --mdb-btn-disabled-border-color: #e4a11b;
}

.btn-info {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #54b4d3;
  --mdb-btn-border-color: #54b4d3;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #50abc8;
  --mdb-btn-hover-border-color: #4390a9;
  --mdb-btn-focus-shadow-rgb: 110, 191, 218;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #4ca2be;
  --mdb-btn-active-border-color: #3f879e;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #54b4d3;
  --mdb-btn-disabled-border-color: #54b4d3;
}

.btn-light {
  --mdb-btn-color: #000;
  --mdb-btn-bg: #fbfbfb;
  --mdb-btn-border-color: #fbfbfb;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #eee;
  --mdb-btn-hover-border-color: #c9c9c9;
  --mdb-btn-focus-shadow-rgb: 213, 213, 213;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #e2e2e2;
  --mdb-btn-active-border-color: #bcbcbc;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #fbfbfb;
  --mdb-btn-disabled-border-color: #fbfbfb;
}

.btn-dark {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #332d2d;
  --mdb-btn-border-color: #332d2d;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #3d3838;
  --mdb-btn-hover-border-color: #474242;
  --mdb-btn-focus-shadow-rgb: 82, 77, 77;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #474242;
  --mdb-btn-active-border-color: #474242;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #332d2d;
  --mdb-btn-disabled-border-color: #332d2d;
}

.btn-outline-secondary {
  --mdb-btn-color: #9fa6b2;
  --mdb-btn-border-color: #9fa6b2;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #9fa6b2;
  --mdb-btn-hover-border-color: #9fa6b2;
  --mdb-btn-focus-shadow-rgb: 159, 166, 178;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #9fa6b2;
  --mdb-btn-active-border-color: #9fa6b2;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #9fa6b2;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #9fa6b2;
  --mdb-gradient: none;
}

.btn-link {
  --mdb-btn-font-weight: 400;
  --mdb-btn-color: #3b71ca;
  --mdb-btn-bg: transparent;
  --mdb-btn-border-color: transparent;
  --mdb-btn-hover-color: #386bc0;
  --mdb-btn-hover-border-color: transparent;
  --mdb-btn-active-color: #386bc0;
  --mdb-btn-active-border-color: transparent;
  --mdb-btn-disabled-color: #9e9e9e;
  --mdb-btn-disabled-border-color: transparent;
  --mdb-btn-box-shadow: 0 0 0 #000;
  --mdb-btn-focus-shadow-rgb: 88, 134, 210;
  text-decoration: none;
}

.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--mdb-btn-color);
}

.btn-link:hover {
  color: var(--mdb-btn-hover-color);
}

.btn-lg {
  --mdb-btn-padding-y: .5rem;
  --mdb-btn-padding-x: 1.6875rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-border-radius: var(--mdb-border-radius-lg);
}

.btn-sm {
  --mdb-btn-padding-y: .25rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-border-radius: var(--mdb-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --mdb-dropdown-zindex: 1000;
  --mdb-dropdown-min-width: 10rem;
  --mdb-dropdown-padding-x: 0;
  --mdb-dropdown-padding-y: .5rem;
  --mdb-dropdown-spacer: .125rem;
  --mdb-dropdown-font-size: .875rem;
  --mdb-dropdown-color: var(--mdb-surface-color);
  --mdb-dropdown-bg: var(--mdb-surface-bg);
  --mdb-dropdown-border-color: var(--mdb-border-color-translucent);
  --mdb-dropdown-border-radius: .5rem;
  --mdb-dropdown-border-width: var(--mdb-border-width);
  --mdb-dropdown-inner-border-radius: calc(.5rem - var(--mdb-border-width));
  --mdb-dropdown-divider-bg: var(--mdb-divider-color);
  --mdb-dropdown-divider-margin-y: .5rem;
  --mdb-dropdown-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-dropdown-link-color: var(--mdb-surface-color);
  --mdb-dropdown-link-hover-color: var(--mdb-surface-color);
  --mdb-dropdown-link-hover-bg: var(--mdb-tertiary-bg);
  --mdb-dropdown-link-active-color: #fff;
  --mdb-dropdown-link-active-bg: #3b71ca;
  --mdb-dropdown-link-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-dropdown-item-padding-x: 1rem;
  --mdb-dropdown-item-padding-y: .5rem;
  --mdb-dropdown-header-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-dropdown-header-padding-x: 1rem;
  --mdb-dropdown-header-padding-y: .5rem;
  z-index: 1000;
  z-index: var(--mdb-dropdown-zindex);
  min-width: var(--mdb-dropdown-min-width);
  padding: .5rem 0;
  padding: var(--mdb-dropdown-padding-y) var(--mdb-dropdown-padding-x);
  font-size: .875rem;
  font-size: var(--mdb-dropdown-font-size);
  color: var(--mdb-dropdown-color);
  text-align: left;
  background-color: #fff;
  background-color: var(--mdb-dropdown-bg);
  border: 1px solid #0000002d;
  border: var(--mdb-dropdown-border-width) solid var(--mdb-dropdown-border-color);
  border-radius: .5rem;
  border-radius: var(--mdb-dropdown-border-radius);
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-dropdown-box-shadow);
  background-clip: padding-box;
  min-width: 10rem;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-mdb-popper] {
  margin-top: var(--mdb-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropup .dropdown-menu[data-mdb-popper] {
  margin-top: 0;
  margin-bottom: var(--mdb-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-mdb-popper] {
  margin-top: 0;
  margin-left: var(--mdb-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-mdb-popper] {
  margin-top: 0;
  margin-right: var(--mdb-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--mdb-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--mdb-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  clear: both;
  color: var(--mdb-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: 0;
  border-radius: var(--mdb-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  display: block;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--mdb-dropdown-link-active-color);
  background-color: var(--mdb-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--mdb-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.btn-group {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
  z-index: 1;
}

.btn-group {
  border-radius: .25rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px #0000001a;
  box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.nav {
  --mdb-nav-link-padding-x: 1rem;
  --mdb-nav-link-padding-y: .5rem;
  --mdb-nav-link-font-weight: ;
  --mdb-nav-link-color: var(--mdb-link-color);
  --mdb-nav-link-hover-color: var(--mdb-link-hover-color);
  --mdb-nav-link-disabled-color: var(--mdb-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--mdb-nav-link-padding-y) var(--mdb-nav-link-padding-x);
  font-size: var(--mdb-nav-link-font-size);
  font-weight: var(--mdb-nav-link-font-weight);
  color: var(--mdb-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--mdb-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #3b71ca40;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--mdb-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --mdb-nav-tabs-border-width: var(--mdb-border-width);
  --mdb-nav-tabs-border-color: var(--mdb-border-color);
  --mdb-nav-tabs-border-radius: var(--mdb-border-radius);
  --mdb-nav-tabs-link-hover-border-color: var(--mdb-secondary-bg) var(--mdb-secondary-bg) var(--mdb-border-color);
  --mdb-nav-tabs-link-active-color: #3b71ca;
  --mdb-nav-tabs-link-active-bg: var(--mdb-body-bg);
  --mdb-nav-tabs-link-active-border-color: #3b71ca;
  border-bottom: 1px solid #e0e0e0;
  border-bottom: var(--mdb-nav-tabs-border-width) solid var(--mdb-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--mdb-nav-tabs-border-width));
  border: var(--mdb-nav-tabs-border-width) solid #0000;
  border-top-left-radius: var(--mdb-nav-tabs-border-radius);
  border-top-right-radius: var(--mdb-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--mdb-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--mdb-nav-tabs-link-active-color);
  background-color: var(--mdb-nav-tabs-link-active-bg);
  border-color: var(--mdb-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--mdb-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --mdb-navbar-padding-x: 0;
  --mdb-navbar-padding-y: .5rem;
  --mdb-navbar-color: rgba(var(--mdb-emphasis-color-rgb), .65);
  --mdb-navbar-hover-color: rgba(var(--mdb-emphasis-color-rgb), .8);
  --mdb-navbar-disabled-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-navbar-active-color: rgba(var(--mdb-emphasis-color-rgb), 1);
  --mdb-navbar-brand-padding-y: .3rem;
  --mdb-navbar-brand-margin-end: 1rem;
  --mdb-navbar-brand-font-size: 1.25rem;
  --mdb-navbar-brand-color: rgba(var(--mdb-emphasis-color-rgb), 1);
  --mdb-navbar-brand-hover-color: rgba(var(--mdb-emphasis-color-rgb), 1);
  --mdb-navbar-nav-link-padding-x: .5rem;
  --mdb-navbar-toggler-padding-y: .25rem;
  --mdb-navbar-toggler-padding-x: .75rem;
  --mdb-navbar-toggler-font-size: 1.25rem;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2879, 79, 79, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --mdb-navbar-toggler-border-color: rgba(var(--mdb-emphasis-color-rgb), .15);
  --mdb-navbar-toggler-border-radius: .25rem;
  --mdb-navbar-toggler-focus-width: .25rem;
  --mdb-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: .5rem 0;
  padding: var(--mdb-navbar-padding-y) var(--mdb-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-nav {
  --mdb-nav-link-padding-x: 0;
  --mdb-nav-link-padding-y: .5rem;
  --mdb-nav-link-font-weight: ;
  --mdb-nav-link-color: var(--mdb-navbar-color);
  --mdb-nav-link-hover-color: var(--mdb-navbar-hover-color);
  --mdb-nav-link-disabled-color: var(--mdb-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--mdb-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--mdb-navbar-toggler-padding-y) var(--mdb-navbar-toggler-padding-x);
  font-size: var(--mdb-navbar-toggler-font-size);
  color: var(--mdb-navbar-color);
  border: 1px solid var(--mdb-navbar-toggler-border-color);
  border: var(--mdb-border-width) solid var(--mdb-navbar-toggler-border-color);
  border-radius: var(--mdb-navbar-toggler-border-radius);
  transition: var(--mdb-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--mdb-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--mdb-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }
}

.card {
  --mdb-card-spacer-y: 1.5rem;
  --mdb-card-spacer-x: 1.5rem;
  --mdb-card-title-spacer-y: .5rem;
  --mdb-card-title-color: ;
  --mdb-card-subtitle-color: ;
  --mdb-card-border-width: var(--mdb-border-width);
  --mdb-card-border-color: #0000002d;
  --mdb-card-border-radius: .5rem;
  --mdb-card-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-card-inner-border-radius: calc(.5rem - (var(--mdb-border-width)));
  --mdb-card-cap-padding-y: .75rem;
  --mdb-card-cap-padding-x: 1.5rem;
  --mdb-card-cap-bg: #fff0;
  --mdb-card-cap-color: ;
  --mdb-card-height: ;
  --mdb-card-color: ;
  --mdb-card-bg: var(--mdb-surface-bg);
  --mdb-card-img-overlay-padding: 1.5rem;
  --mdb-card-group-margin: .75rem;
  height: ;
  height: var(--mdb-card-height);
  color: var(--mdb-body-color);
  word-wrap: break-word;
  background-color: #fff;
  background-color: var(--mdb-card-bg);
  border: 1px solid #0000002d;
  border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  border-radius: .5rem;
  border-radius: var(--mdb-card-border-radius);
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--mdb-card-inner-border-radius);
  border-top-right-radius: var(--mdb-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--mdb-card-inner-border-radius);
  border-bottom-left-radius: var(--mdb-card-inner-border-radius);
  border-bottom-width: 0;
}

.card-body {
  padding: var(--mdb-card-spacer-y) var(--mdb-card-spacer-x);
  color: var(--mdb-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--mdb-card-title-spacer-y);
  color: var(--mdb-card-title-color);
}

.accordion {
  --mdb-accordion-color: var(--mdb-surface-color);
  --mdb-accordion-bg: var(--mdb-body-bg);
  --mdb-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --mdb-accordion-border-color: var(--mdb-border-color);
  --mdb-accordion-border-width: var(--mdb-border-width);
  --mdb-accordion-border-radius: .5rem;
  --mdb-accordion-inner-border-radius: calc(.5rem - (var(--mdb-border-width)));
  --mdb-accordion-btn-padding-x: 1.5rem;
  --mdb-accordion-btn-padding-y: 1.15rem;
  --mdb-accordion-btn-color: var(--mdb-surface-color);
  --mdb-accordion-btn-bg: var(--mdb-accordion-bg);
  --mdb-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%234f4f4f' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --mdb-accordion-btn-icon-width: 1.25rem;
  --mdb-accordion-btn-icon-transform: rotate(-180deg);
  --mdb-accordion-btn-icon-transition: transform .2s ease-in-out;
  --mdb-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%233b71ca' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --mdb-accordion-btn-focus-box-shadow: inset 0 -1px 0 var(--mdb-border-color);
  --mdb-accordion-body-padding-x: 1.5rem;
  --mdb-accordion-body-padding-y: 1.15rem;
  --mdb-accordion-active-color: #3b71ca;
  --mdb-accordion-active-bg: var(--mdb-surface-bg);
}

.accordion-button {
  padding: var(--mdb-accordion-btn-padding-y) var(--mdb-accordion-btn-padding-x);
  color: var(--mdb-accordion-btn-color);
  text-align: left;
  background-color: var(--mdb-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--mdb-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--mdb-accordion-active-color);
  background-color: var(--mdb-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--mdb-accordion-border-width)) 0 var(--mdb-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--mdb-accordion-btn-active-icon);
  transform: var(--mdb-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--mdb-accordion-btn-icon-width);
  height: var(--mdb-accordion-btn-icon-width);
  content: "";
  background-image: var(--mdb-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--mdb-accordion-btn-icon-width);
  transition: var(--mdb-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--mdb-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--mdb-accordion-color);
  background-color: var(--mdb-accordion-bg);
  border: var(--mdb-accordion-border-width) solid var(--mdb-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--mdb-accordion-border-radius);
  border-top-right-radius: var(--mdb-accordion-border-radius);
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--mdb-accordion-inner-border-radius);
  border-top-right-radius: var(--mdb-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--mdb-accordion-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-border-radius);
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--mdb-accordion-inner-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-inner-border-radius);
}

.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--mdb-accordion-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-border-radius);
}

.accordion-body {
  padding: var(--mdb-accordion-body-padding-y) var(--mdb-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed, .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

.breadcrumb {
  --mdb-breadcrumb-padding-x: 0;
  --mdb-breadcrumb-padding-y: 0;
  --mdb-breadcrumb-margin-bottom: 1rem;
  --mdb-breadcrumb-bg: ;
  --mdb-breadcrumb-border-radius: ;
  --mdb-breadcrumb-divider-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-breadcrumb-item-padding-x: .5rem;
  --mdb-breadcrumb-item-active-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  padding: 0;
  padding: var(--mdb-breadcrumb-padding-y) var(--mdb-breadcrumb-padding-x);
  margin-bottom: 1rem;
  margin-bottom: var(--mdb-breadcrumb-margin-bottom);
  font-size: var(--mdb-breadcrumb-font-size);
  background-color: ;
  background-color: var(--mdb-breadcrumb-bg);
  border-radius: ;
  border-radius: var(--mdb-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--mdb-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--mdb-breadcrumb-item-padding-x);
  color: var(--mdb-breadcrumb-divider-color);
  content: "/";
  content: var(--mdb-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--mdb-breadcrumb-item-active-color);
}

.pagination {
  --mdb-pagination-padding-x: .75rem;
  --mdb-pagination-padding-y: .375rem;
  --mdb-pagination-font-size: .9rem;
  --mdb-pagination-color: var(--mdb-body-color);
  --mdb-pagination-bg: var(--mdb-body-bg);
  --mdb-pagination-border-width: var(--mdb-border-width);
  --mdb-pagination-border-color: var(--mdb-border-color);
  --mdb-pagination-border-radius: .25rem;
  --mdb-pagination-hover-color: var(--mdb-body-color);
  --mdb-pagination-hover-bg: var(--mdb-highlight-bg-color);
  --mdb-pagination-hover-border-color: var(--mdb-border-color);
  --mdb-pagination-focus-color: var(--mdb-link-hover-color);
  --mdb-pagination-focus-bg: var(--mdb-highlight-bg-color);
  --mdb-pagination-focus-box-shadow: 0 0 0 .25rem #3b71ca40;
  --mdb-pagination-active-color: var(--mdb-primary-text-emphasis);
  --mdb-pagination-active-bg: var(--mdb-primary-bg-subtle);
  --mdb-pagination-active-border-color: #3b71ca;
  --mdb-pagination-disabled-color: rgba(var(--mdb-body-color-rgb), .55);
  --mdb-pagination-disabled-bg: transparent;
  --mdb-pagination-disabled-border-color: var(--mdb-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--mdb-pagination-padding-y) var(--mdb-pagination-padding-x);
  font-size: var(--mdb-pagination-font-size);
  color: var(--mdb-pagination-color);
  background-color: var(--mdb-pagination-bg);
  border: var(--mdb-pagination-border-width) solid var(--mdb-pagination-border-color);
  transition: all .3s linear;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--mdb-pagination-hover-color);
  background-color: var(--mdb-pagination-hover-bg);
  border-color: var(--mdb-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--mdb-pagination-focus-color);
  background-color: var(--mdb-pagination-focus-bg);
  box-shadow: var(--mdb-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--mdb-pagination-active-color);
  background-color: var(--mdb-pagination-active-bg);
  border-color: var(--mdb-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--mdb-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-pagination-disabled-bg);
  border-color: var(--mdb-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
  margin-left: calc(var(--mdb-border-width) * -1);
}

.badge {
  --mdb-badge-padding-x: .65em;
  --mdb-badge-padding-y: .35em;
  --mdb-badge-font-size: .75em;
  --mdb-badge-font-weight: 700;
  --mdb-badge-color: #fff;
  --mdb-badge-border-radius: .27rem;
  padding: .35em .65em;
  padding: var(--mdb-badge-padding-y) var(--mdb-badge-padding-x);
  font-size: .75em;
  font-size: var(--mdb-badge-font-size);
  font-weight: 700;
  font-weight: var(--mdb-badge-font-weight);
  color: var(--mdb-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .27rem;
  border-radius: var(--mdb-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --mdb-alert-bg: transparent;
  --mdb-alert-padding-x: 1.5rem;
  --mdb-alert-padding-y: 1.25rem;
  --mdb-alert-margin-bottom: 1rem;
  --mdb-alert-color: inherit;
  --mdb-alert-border-color: transparent;
  --mdb-alert-border: var(--mdb-border-width) solid var(--mdb-alert-border-color);
  --mdb-alert-border-radius: .5rem;
  --mdb-alert-link-color: inherit;
  padding: 1.25rem 1.5rem;
  padding: var(--mdb-alert-padding-y) var(--mdb-alert-padding-x);
  margin-bottom: 1rem;
  margin-bottom: var(--mdb-alert-margin-bottom);
  color: var(--mdb-alert-color);
  background-color: #0000;
  background-color: var(--mdb-alert-bg);
  border: 1px solid #0000;
  border: var(--mdb-alert-border);
  border-radius: .5rem;
  border-radius: var(--mdb-alert-border-radius);
  position: relative;
}

.alert-dismissible {
  padding-right: 4.5rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.5625rem 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --mdb-alert-color: var(--mdb-primary-text-emphasis);
  --mdb-alert-bg: var(--mdb-primary-bg-subtle);
  --mdb-alert-border-color: var(--mdb-primary-border-subtle);
  --mdb-alert-link-color: var(--mdb-primary-text-emphasis);
}

.alert-secondary {
  --mdb-alert-color: var(--mdb-secondary-text-emphasis);
  --mdb-alert-bg: var(--mdb-secondary-bg-subtle);
  --mdb-alert-border-color: var(--mdb-secondary-border-subtle);
  --mdb-alert-link-color: var(--mdb-secondary-text-emphasis);
}

.alert-success {
  --mdb-alert-color: var(--mdb-success-text-emphasis);
  --mdb-alert-bg: var(--mdb-success-bg-subtle);
  --mdb-alert-border-color: var(--mdb-success-border-subtle);
  --mdb-alert-link-color: var(--mdb-success-text-emphasis);
}

.alert-danger {
  --mdb-alert-color: var(--mdb-danger-text-emphasis);
  --mdb-alert-bg: var(--mdb-danger-bg-subtle);
  --mdb-alert-border-color: var(--mdb-danger-border-subtle);
  --mdb-alert-link-color: var(--mdb-danger-text-emphasis);
}

.alert-warning {
  --mdb-alert-color: var(--mdb-warning-text-emphasis);
  --mdb-alert-bg: var(--mdb-warning-bg-subtle);
  --mdb-alert-border-color: var(--mdb-warning-border-subtle);
  --mdb-alert-link-color: var(--mdb-warning-text-emphasis);
}

.alert-info {
  --mdb-alert-color: var(--mdb-info-text-emphasis);
  --mdb-alert-bg: var(--mdb-info-bg-subtle);
  --mdb-alert-border-color: var(--mdb-info-border-subtle);
  --mdb-alert-link-color: var(--mdb-info-text-emphasis);
}

.alert-light {
  --mdb-alert-color: var(--mdb-light-text-emphasis);
  --mdb-alert-bg: var(--mdb-light-bg-subtle);
  --mdb-alert-border-color: var(--mdb-light-border-subtle);
  --mdb-alert-link-color: var(--mdb-light-text-emphasis);
}

.alert-dark {
  --mdb-alert-color: var(--mdb-dark-text-emphasis);
  --mdb-alert-bg: var(--mdb-dark-bg-subtle);
  --mdb-alert-border-color: var(--mdb-dark-border-subtle);
  --mdb-alert-link-color: var(--mdb-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 4px;
  }
}

.progress {
  --mdb-progress-height: 4px;
  --mdb-progress-font-size: .75rem;
  --mdb-progress-bg: var(--mdb-secondary-bg);
  --mdb-progress-border-radius: var(--mdb-border-radius);
  --mdb-progress-box-shadow: var(--mdb-box-shadow-inset);
  --mdb-progress-bar-color: #fff;
  --mdb-progress-bar-bg: #3b71ca;
  --mdb-progress-bar-transition: width .6s ease;
  height: var(--mdb-progress-height);
  font-size: .75rem;
  font-size: var(--mdb-progress-font-size);
  background-color: #eee;
  background-color: var(--mdb-progress-bg);
  border-radius: .25rem;
  border-radius: var(--mdb-progress-border-radius);
  box-shadow: inset 0 1px 2px #00000013;
  box-shadow: var(--mdb-progress-box-shadow);
  height: 4px;
  display: flex;
  overflow: hidden;
}

.list-group {
  --mdb-list-group-color: var(--mdb-body-color);
  --mdb-list-group-bg: transparent;
  --mdb-list-group-border-color: var(--mdb-border-color);
  --mdb-list-group-border-width: var(--mdb-border-width);
  --mdb-list-group-border-radius: .5rem;
  --mdb-list-group-item-padding-x: 1.5rem;
  --mdb-list-group-item-padding-y: .5rem;
  --mdb-list-group-action-color: var(--mdb-secondary-color);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-tertiary-bg);
  --mdb-list-group-action-active-color: var(--mdb-body-color);
  --mdb-list-group-action-active-bg: var(--mdb-secondary-bg);
  --mdb-list-group-disabled-color: rgba(var(--mdb-body-color-rgb), .5);
  --mdb-list-group-disabled-bg: transparent;
  --mdb-list-group-active-color: #fff;
  --mdb-list-group-active-bg: #3b71ca;
  --mdb-list-group-active-border-color: #3b71ca;
  border-radius: .5rem;
  border-radius: var(--mdb-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item {
  padding: var(--mdb-list-group-item-padding-y) var(--mdb-list-group-item-padding-x);
  color: var(--mdb-list-group-color);
  background-color: var(--mdb-list-group-bg);
  border: var(--mdb-list-group-border-width) solid var(--mdb-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--mdb-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--mdb-list-group-active-color);
  background-color: var(--mdb-list-group-active-bg);
  border-color: var(--mdb-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--mdb-list-group-border-width));
  border-top-width: var(--mdb-list-group-border-width);
}

.btn-close {
  --mdb-btn-close-color: #000;
  --mdb-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --mdb-btn-close-opacity: .5;
  --mdb-btn-close-hover-opacity: .75;
  --mdb-btn-close-focus-shadow: 0 0 0 .25rem #3b71ca40;
  --mdb-btn-close-focus-opacity: 1;
  --mdb-btn-close-disabled-opacity: .25;
  --mdb-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--mdb-btn-close-color);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  background: #0000 var(--mdb-btn-close-bg) center / 1em auto no-repeat;
  opacity: .5;
  opacity: var(--mdb-btn-close-opacity);
  border: 0;
  border-radius: .25rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--mdb-btn-close-color);
  opacity: var(--mdb-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--mdb-btn-close-focus-shadow);
  opacity: var(--mdb-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: var(--mdb-btn-close-disabled-opacity);
}

.toast {
  --mdb-toast-zindex: 1060;
  --mdb-toast-padding-x: 1rem;
  --mdb-toast-padding-y: .65rem;
  --mdb-toast-spacing: 1.5rem;
  --mdb-toast-max-width: 350px;
  --mdb-toast-font-size: .875rem;
  --mdb-toast-color: ;
  --mdb-toast-bg: var(--mdb-surface-bg);
  --mdb-toast-border-width: var(--mdb-border-width);
  --mdb-toast-border-color: var(--mdb-border-color-translucent);
  --mdb-toast-border-radius: .5rem;
  --mdb-toast-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-toast-header-color: var(--mdb-secondary-color);
  --mdb-toast-header-bg: var(--mdb-surface-bg);
  --mdb-toast-header-border-color: var(--mdb-border-color-translucent);
  width: var(--mdb-toast-max-width);
  font-size: .875rem;
  font-size: var(--mdb-toast-font-size);
  color: var(--mdb-toast-color);
  pointer-events: auto;
  background-color: #fff;
  background-color: var(--mdb-toast-bg);
  border: 1px solid #0000002d;
  border: var(--mdb-toast-border-width) solid var(--mdb-toast-border-color);
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-toast-box-shadow);
  border-radius: .5rem;
  border-radius: var(--mdb-toast-border-radius);
  background-clip: padding-box;
  width: 350px;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-header {
  padding: var(--mdb-toast-padding-y) var(--mdb-toast-padding-x);
  color: var(--mdb-toast-header-color);
  background-color: var(--mdb-toast-header-bg);
  border-bottom: var(--mdb-toast-border-width) solid var(--mdb-toast-header-border-color);
  border-top-left-radius: calc(var(--mdb-toast-border-radius)  - var(--mdb-toast-border-width));
  border-top-right-radius: calc(var(--mdb-toast-border-radius)  - var(--mdb-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.modal {
  --mdb-modal-zindex: 1055;
  --mdb-modal-width: 500px;
  --mdb-modal-padding: 1rem;
  --mdb-modal-margin: .5rem;
  --mdb-modal-color: var(--mdb-surface-color);
  --mdb-modal-bg: var(--mdb-surface-bg);
  --mdb-modal-border-color: var(--mdb-border-color-translucent);
  --mdb-modal-border-width: var(--mdb-border-width);
  --mdb-modal-border-radius: .5rem;
  --mdb-modal-box-shadow: var(--mdb-box-shadow-sm);
  --mdb-modal-inner-border-radius: calc(.5rem - (var(--mdb-border-width)));
  --mdb-modal-header-padding-x: 1rem;
  --mdb-modal-header-padding-y: 1rem;
  --mdb-modal-header-padding: 1rem 1rem;
  --mdb-modal-header-border-color: var(--mdb-divider-color);
  --mdb-modal-header-border-width: 2px;
  --mdb-modal-title-line-height: 1.6;
  --mdb-modal-footer-gap: .5rem;
  --mdb-modal-footer-bg: ;
  --mdb-modal-footer-border-color: var(--mdb-divider-color);
  --mdb-modal-footer-border-width: 2px;
  z-index: 1055;
  z-index: var(--mdb-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  margin: var(--mdb-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--mdb-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-content {
  color: var(--mdb-modal-color);
  pointer-events: auto;
  background-color: var(--mdb-modal-bg);
  border: var(--mdb-modal-border-width) solid var(--mdb-modal-border-color);
  border-radius: var(--mdb-modal-border-radius);
  box-shadow: var(--mdb-modal-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --mdb-backdrop-zindex: 1050;
  --mdb-backdrop-bg: #000;
  --mdb-backdrop-opacity: .5;
  z-index: 1050;
  z-index: var(--mdb-backdrop-zindex);
  background-color: #000;
  background-color: var(--mdb-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--mdb-backdrop-opacity);
}

.modal-header {
  padding: var(--mdb-modal-header-padding);
  border-bottom: var(--mdb-modal-header-border-width) solid var(--mdb-modal-header-border-color);
  border-top-left-radius: var(--mdb-modal-inner-border-radius);
  border-top-right-radius: var(--mdb-modal-inner-border-radius);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--mdb-modal-header-padding-y) * .5) calc(var(--mdb-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--mdb-modal-header-padding-y)) calc(-.5 * var(--mdb-modal-header-padding-x)) calc(-.5 * var(--mdb-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--mdb-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--mdb-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--mdb-modal-padding)  - var(--mdb-modal-footer-gap) * .5);
  background-color: var(--mdb-modal-footer-bg);
  border-top: var(--mdb-modal-footer-border-width) solid var(--mdb-modal-footer-border-color);
  border-bottom-right-radius: var(--mdb-modal-inner-border-radius);
  border-bottom-left-radius: var(--mdb-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--mdb-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --mdb-modal-margin: 1.75rem;
    --mdb-modal-box-shadow: var(--mdb-box-shadow);
  }

  .modal-dialog {
    max-width: var(--mdb-modal-width);
    margin-left: auto;
    margin-right: auto;
  }
}

.tooltip {
  --mdb-tooltip-zindex: 1090;
  --mdb-tooltip-max-width: 200px;
  --mdb-tooltip-padding-x: 16px;
  --mdb-tooltip-padding-y: 6px;
  --mdb-tooltip-margin: ;
  --mdb-tooltip-font-size: .875rem;
  --mdb-tooltip-color: var(--mdb-surface-inverted-color);
  --mdb-tooltip-bg: var(--mdb-surface-inverted-bg);
  --mdb-tooltip-border-radius: .25rem;
  --mdb-tooltip-opacity: .9;
  --mdb-tooltip-arrow-width: .8rem;
  --mdb-tooltip-arrow-height: .4rem;
  z-index: 1090;
  z-index: var(--mdb-tooltip-zindex);
  margin: ;
  margin: var(--mdb-tooltip-margin);
  font-family: Roboto, sans-serif;
  font-family: var(--mdb-font-roboto);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: var(--mdb-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip .tooltip-arrow {
  width: var(--mdb-tooltip-arrow-width);
  height: var(--mdb-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tooltip-inner {
  max-width: var(--mdb-tooltip-max-width);
  padding: var(--mdb-tooltip-padding-y) var(--mdb-tooltip-padding-x);
  color: var(--mdb-tooltip-color);
  text-align: center;
  background-color: var(--mdb-tooltip-bg);
  border-radius: var(--mdb-tooltip-border-radius);
}

.popover {
  --mdb-popover-zindex: 1080;
  --mdb-popover-max-width: 276px;
  --mdb-popover-font-size: .875rem;
  --mdb-popover-bg: var(--mdb-surface-bg);
  --mdb-popover-border-width: 1px;
  --mdb-popover-border-color: var(--mdb-divider-color);
  --mdb-popover-border-radius: .5rem;
  --mdb-popover-inner-border-radius: calc(.5rem - 1px);
  --mdb-popover-box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-popover-header-padding-x: 1rem;
  --mdb-popover-header-padding-y: .5rem;
  --mdb-popover-header-font-size: 1rem;
  --mdb-popover-header-color: var(--mdb-surface-color);
  --mdb-popover-header-bg: var(--mdb-surface-bg);
  --mdb-popover-body-padding-x: 1rem;
  --mdb-popover-body-padding-y: 1rem;
  --mdb-popover-body-color: var(--mdb-surface-color);
  --mdb-popover-arrow-width: 1rem;
  --mdb-popover-arrow-height: .5rem;
  --mdb-popover-arrow-border: var(--mdb-popover-border-color);
  z-index: 1080;
  z-index: var(--mdb-popover-zindex);
  max-width: var(--mdb-popover-max-width);
  font-family: Roboto, sans-serif;
  font-family: var(--mdb-font-roboto);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: var(--mdb-popover-font-size);
  word-wrap: break-word;
  background-color: #fff;
  background-color: var(--mdb-popover-bg);
  border: 1px solid #f5f5f5;
  border: var(--mdb-popover-border-width) solid var(--mdb-popover-border-color);
  border-radius: .5rem;
  border-radius: var(--mdb-popover-border-radius);
  box-shadow: 0 0 3px #00000012, 0 2px 2px #0000000a;
  box-shadow: var(--mdb-popover-box-shadow);
  background-clip: padding-box;
  max-width: 276px;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--mdb-popover-arrow-width);
  height: var(--mdb-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.popover-header {
  padding: var(--mdb-popover-header-padding-y) var(--mdb-popover-header-padding-x);
  font-size: var(--mdb-popover-header-font-size);
  color: var(--mdb-popover-header-color);
  background-color: var(--mdb-popover-header-bg);
  border-bottom: var(--mdb-popover-border-width) solid var(--mdb-popover-border-color);
  border-top-left-radius: var(--mdb-popover-inner-border-radius);
  border-top-right-radius: var(--mdb-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--mdb-popover-body-padding-y) var(--mdb-popover-body-padding-x);
  color: var(--mdb-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: none;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-mdb-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-mdb-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.spinner-grow, .spinner-border {
  width: var(--mdb-spinner-width);
  height: var(--mdb-spinner-height);
  vertical-align: var(--mdb-spinner-vertical-align);
  animation: var(--mdb-spinner-animation-speed) linear infinite var(--mdb-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-border-width: .25em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-border;
  border: .25em solid;
  border: var(--mdb-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --mdb-spinner-animation-speed: 1.5s;
  }
}

.offcanvas {
  --mdb-offcanvas-zindex: 1045;
  --mdb-offcanvas-width: 400px;
  --mdb-offcanvas-height: 30vh;
  --mdb-offcanvas-padding-x: 1rem;
  --mdb-offcanvas-padding-y: 1rem;
  --mdb-offcanvas-color: var(--mdb-body-color);
  --mdb-offcanvas-bg: var(--mdb-body-bg);
  --mdb-offcanvas-border-width: var(--mdb-border-width);
  --mdb-offcanvas-border-color: var(--mdb-border-color-translucent);
  --mdb-offcanvas-box-shadow: var(--mdb-box-shadow-sm);
  --mdb-offcanvas-transition: transform .3s ease-in-out;
  --mdb-offcanvas-title-line-height: 1.6;
  z-index: var(--mdb-offcanvas-zindex);
  color: var(--mdb-offcanvas-color);
  visibility: hidden;
  background-color: var(--mdb-offcanvas-bg);
  box-shadow: var(--mdb-offcanvas-box-shadow);
  transition: var(--mdb-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.link-success {
  color: RGBA(var(--mdb-success-rgb), var(--mdb-link-opacity, 1)) !important;
  -webkit-text-decoration-color: #14a44d !important;
  text-decoration-color: #14a44d !important;
  -webkit-text-decoration-color: RGBA(var(--mdb-success-rgb), var(--mdb-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-success-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(19, 156, 73, var(--mdb-link-opacity, 1)) !important;
  -webkit-text-decoration-color: #139c49 !important;
  text-decoration-color: #139c49 !important;
  -webkit-text-decoration-color: RGBA(19, 156, 73, var(--mdb-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(19, 156, 73, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--mdb-light-rgb), var(--mdb-link-opacity, 1)) !important;
  -webkit-text-decoration-color: #fbfbfb !important;
  text-decoration-color: #fbfbfb !important;
  -webkit-text-decoration-color: RGBA(var(--mdb-light-rgb), var(--mdb-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-light-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(251, 251, 251, var(--mdb-link-opacity, 1)) !important;
  -webkit-text-decoration-color: #fbfbfb !important;
  text-decoration-color: #fbfbfb !important;
  -webkit-text-decoration-color: RGBA(251, 251, 251, var(--mdb-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(251, 251, 251, var(--mdb-link-underline-opacity, 1)) !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--mdb-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.vr {
  width: var(--mdb-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  display: inline-block;
}

.align-middle {
  vertical-align: middle !important;
}

.float-start {
  float: left !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.shadow-2 {
  box-shadow: 0 0 3px #00000012, 0 2px 2px #0000000a !important;
  box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.border {
  border: 1px solid #e0e0e0 !important;
  border: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e0e0e0 !important;
  border-top: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0 !important;
  border-bottom: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.w-100 {
  width: 100% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-content-center {
  align-content: center !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-primary-rgb), var(--mdb-text-opacity)) !important;
}

.text-secondary {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-secondary-rgb), var(--mdb-text-opacity)) !important;
}

.text-success {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-success-rgb), var(--mdb-text-opacity)) !important;
}

.text-danger {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-danger-rgb), var(--mdb-text-opacity)) !important;
}

.text-warning {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-warning-rgb), var(--mdb-text-opacity)) !important;
}

.text-info {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-info-rgb), var(--mdb-text-opacity)) !important;
}

.text-light {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-light-rgb), var(--mdb-text-opacity)) !important;
}

.text-dark {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-dark-rgb), var(--mdb-text-opacity)) !important;
}

.text-white {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-white-rgb), var(--mdb-text-opacity)) !important;
}

.text-muted {
  --mdb-text-opacity: 1;
  color: var(--mdb-secondary-color) !important;
}

.text-light-emphasis {
  color: var(--mdb-light-text-emphasis) !important;
}

.bg-primary {
  --mdb-bg-opacity: 1;
  background-color: #3b71ca !important;
  background-color: rgba(var(--mdb-primary-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-danger {
  --mdb-bg-opacity: 1;
  background-color: #dc4c64 !important;
  background-color: rgba(var(--mdb-danger-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-warning {
  --mdb-bg-opacity: 1;
  background-color: #e4a11b !important;
  background-color: rgba(var(--mdb-warning-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-light {
  --mdb-bg-opacity: 1;
  background-color: #fbfbfb !important;
  background-color: rgba(var(--mdb-light-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-white {
  --mdb-bg-opacity: 1;
  background-color: #fff !important;
  background-color: rgba(var(--mdb-white-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-gradient {
  background-image: linear-gradient(#ffffff26, #fff0) !important;
  background-image: var(--mdb-gradient) !important;
}

.rounded {
  border-radius: .25rem !important;
  border-radius: var(--mdb-border-radius) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
  border-radius: var(--mdb-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (width >= 576px) {
  .float-sm-end {
    float: right !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .d-md-block {
    display: block !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-none {
    display: none !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }
}

@media (width >= 992px) {
  .d-lg-block {
    display: block !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-none {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .d-xl-block {
    display: block !important;
  }

  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

.link-success, .link-light {
  transition: color .15s;
}

:root {
  --mdb-font-roboto: "Roboto", sans-serif;
  --mdb-bg-opacity: 1;
  --mdb-text-hover-opacity: .8;
  --mdb-surface-color: #4f4f4f;
  --mdb-surface-color-rgb: 79, 79, 79;
  --mdb-surface-bg: #fff;
  --mdb-surface-inverted-color: #fff;
  --mdb-surface-inverted-color-rgb: 255, 255, 255;
  --mdb-surface-inverted-bg: #6d6d6d;
  --mdb-divider-color: #f5f5f5;
  --mdb-divider-blurry-color: #666;
  --mdb-highlight-bg-color: #eee;
  --mdb-scrollbar-rail-bg: #eee;
  --mdb-scrollbar-thumb-bg: #9e9e9e;
  --mdb-picker-header-bg: #3b71ca;
  --mdb-timepicker-clock-face-bg: var(--mdb-secondary-bg);
  --mdb-sidenav-backdrop-opacity: .1;
  --mdb-input-focus-border-color: var(--mdb-primary);
  --mdb-input-focus-label-color: var(--mdb-primary);
  --mdb-form-control-border-color: #bdbdbd;
  --mdb-form-control-label-color: #757575;
  --mdb-form-control-disabled-bg: #e0e0e0;
  --mdb-box-shadow-color: #000;
  --mdb-box-shadow-color-rgb: 0, 0, 0;
  --mdb-stepper-mobile-bg: #fbfbfb;
}

hr:not([size]).hr {
  --mdb-divider-height: 2px;
  --mdb-divider-bg: var(--mdb-divider-color);
  --mdb-divider-opacity: 1;
  --mdb-divider-blurry-bg: transparent;
  --mdb-divider-blurry-bg-image: linear-gradient(90deg, transparent, var(--mdb-divider-blurry-color), transparent);
  --mdb-divider-blurry-height: 1px;
  --mdb-divider-blurry-opacity: .25;
  height: var(--mdb-divider-height);
  background-color: #f5f5f5;
  background-color: var(--mdb-divider-bg);
  opacity: 1;
  opacity: var(--mdb-divider-opacity);
  height: 2px;
}

hr:not([size]).vr {
  height: auto;
}

hr.hr, hr.vr {
  border-top: none !important;
}

.vr {
  --mdb-divider-width: 2px;
  --mdb-divider-bg: var(--mdb-divider-color);
  --mdb-divider-opacity: 1;
  width: var(--mdb-divider-width);
  background-color: #f5f5f5;
  background-color: var(--mdb-divider-bg);
  opacity: 1;
  opacity: var(--mdb-divider-opacity);
  width: 2px;
}

a {
  --mdb-link-decoration: none;
  text-decoration: none;
  -webkit-text-decoration: var(--mdb-link-decoration);
  text-decoration: var(--mdb-link-decoration);
}

.note {
  --mdb-note-padding: 10px;
  --mdb-note-border-width: 6px;
  --mdb-note-border-radius: 5px;
  --mdb-note-strong-font-weight: 600;
  padding: 10px;
  padding: var(--mdb-note-padding);
  border-left: 6px solid;
  border-left: var(--mdb-note-border-width) solid;
  border-radius: 5px;
  border-radius: var(--mdb-note-border-radius);
}

.note strong {
  font-weight: var(--mdb-note-strong-font-weight);
}

.note-light {
  background-color: #f5f5f5;
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
}

.bg-primary {
  --mdb--bg-opacity: 1;
  background-color: #3b71ca;
  background-color: rgba(59, 113, 202, var(--mdb--bg-opacity));
}

.bg-danger {
  --mdb--bg-opacity: 1;
  background-color: #dc4c64;
  background-color: rgba(220, 76, 100, var(--mdb--bg-opacity));
}

.bg-warning {
  --mdb--bg-opacity: 1;
  background-color: #e4a11b;
  background-color: rgba(228, 161, 27, var(--mdb--bg-opacity));
}

.bg-light {
  --mdb--bg-opacity: 1;
  background-color: #fbfbfb;
  background-color: rgba(251, 251, 251, var(--mdb--bg-opacity));
}

i.flag:not(.icon) {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: baseline;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 16px;
  height: 11px;
  margin: 0 .5em 0 0;
  line-height: 11px;
  display: inline-block;
}

i.flag:before {
  content: "";
  background: url("https://mdbootstrap.com/img/svg/flags.png") -108px -1976px no-repeat;
  width: 16px;
  height: 11px;
  display: inline-block;
}

i.flag-au:before {
  background-position: 0 -312px !important;
}

i.flag-be:before {
  background-position: 0 -494px !important;
}

i.flag-es:before {
  background-position: 0 -1742px !important;
}

i.flag-fr:before {
  background-position: 0 -1976px !important;
}

i.flag-uk:before {
  background-position: -36px -26px !important;
}

i.flag-it:before {
  background-position: -36px -832px !important;
}

i.flag-us:before {
  background-position: -72px -1950px !important;
}

.form-control {
  box-shadow: none;
  min-height: auto;
  padding: 4.5px 12px 3.68px;
  transition: all .1s linear;
}

.form-control:focus {
  box-shadow: none;
  border-color: #3b71ca;
  transition: all .1s linear;
  box-shadow: inset 0 0 0 1px #3b71ca;
}

.form-control.form-control-sm {
  font-size: .775rem;
  line-height: 1.5;
}

.form-control.form-control-lg {
  border-radius: .25rem;
  line-height: 2.15;
}

.form-outline {
  width: 100%;
  position: relative;
}

.form-outline .form-helper {
  color: #757575;
  width: 100%;
  font-size: .875em;
  position: absolute;
}

.form-outline .form-helper .form-counter {
  text-align: right;
}

.form-outline .form-icon-trailing {
  padding-right: 2rem !important;
}

.form-outline .form-control {
  background: none;
  border: 0;
  min-height: auto;
  padding: .32rem .75rem;
  transition: all .2s linear;
}

.form-outline .form-control ~ .form-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  transform-origin: 0 0;
  color: var(--mdb-form-control-label-color);
  max-width: 90%;
  margin-bottom: 0;
  padding-top: .37rem;
  transition: all .2s ease-out;
  position: absolute;
  top: 0;
  left: .75rem;
  overflow: hidden;
}

.form-outline .form-control ~ .form-notch {
  text-align: left;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.form-outline .form-control ~ .form-notch div {
  pointer-events: none;
  border: 1px solid #bdbdbd;
  border-color: var(--mdb-form-control-border-color);
  box-sizing: border-box;
  background: none;
  transition: all .2s linear;
}

.form-outline .form-control ~ .form-notch .form-notch-leading {
  border-right: none;
  border-radius: .25rem 0 0 .25rem;
  width: .5rem;
  height: 100%;
  top: 0;
  left: 0;
}

.form-outline .form-control ~ .form-notch .form-notch-middle {
  border-left: none;
  border-right: none;
  flex: none;
  width: auto;
  max-width: calc(100% - 1rem);
  height: 100%;
}

.form-outline .form-control ~ .form-notch .form-notch-trailing {
  border-left: none;
  border-radius: 0 .25rem .25rem 0;
  flex-grow: 1;
  height: 100%;
}

.form-outline .form-control:not(.placeholder-active)::placeholder {
  opacity: 0;
}

.form-outline .form-control:focus::placeholder, .form-outline .form-control.active::placeholder {
  opacity: 1;
}

.form-outline .form-control:focus {
  box-shadow: none !important;
}

.form-outline .form-control:focus ~ .form-label, .form-outline .form-control.active ~ .form-label {
  transform: translateY(-1rem)translateY(.1rem)scale(.8);
}

.form-outline .form-control:focus ~ .form-label {
  color: var(--mdb-input-focus-label-color);
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.active ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  border-left: none;
  border-right: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: #3b71ca;
  border-color: var(--mdb-input-focus-border-color);
  box-shadow: 0 1px #3b71ca;
  box-shadow: 0 1px 0 0 var(--mdb-input-focus-border-color);
  border-top: 1px solid #0000;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading, .form-outline .form-control.active ~ .form-notch .form-notch-leading {
  border-right: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: #3b71ca;
  border-color: var(--mdb-input-focus-border-color);
  box-shadow: -1px 0 #3b71ca, 0 1px #3b71ca, 0 -1px #3b71ca;
  box-shadow: -1px 0 0 0 var(--mdb-input-focus-border-color), 0 1px 0 0 var(--mdb-input-focus-border-color), 0 -1px 0 0 var(--mdb-input-focus-border-color);
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing, .form-outline .form-control.active ~ .form-notch .form-notch-trailing {
  border-left: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #3b71ca;
  border-color: var(--mdb-input-focus-border-color);
  box-shadow: 1px 0 #3b71ca, 0 -1px #3b71ca, 0 1px #3b71ca;
  box-shadow: 1px 0 0 0 var(--mdb-input-focus-border-color), 0 -1px 0 0 var(--mdb-input-focus-border-color), 0 1px 0 0 var(--mdb-input-focus-border-color);
}

.form-outline .form-control:disabled, .form-outline .form-control.disabled, .form-outline .form-control[readonly] {
  background-color: #e0e0e0;
  background-color: var(--mdb-form-control-disabled-bg);
}

.form-outline .form-control:disabled ~ .timepicker-toggle-button, .form-outline .form-control:disabled ~ .datepicker-toggle-button, .form-outline .form-control:disabled ~ .datetimepicker-toggle-button, .form-outline .form-control:disabled ~ .select-arrow, .form-outline .form-control.disabled ~ .timepicker-toggle-button, .form-outline .form-control.disabled ~ .datepicker-toggle-button, .form-outline .form-control.disabled ~ .datetimepicker-toggle-button, .form-outline .form-control.disabled ~ .select-arrow, .form-outline .form-control[readonly] ~ .timepicker-toggle-button, .form-outline .form-control[readonly] ~ .datepicker-toggle-button, .form-outline .form-control[readonly] ~ .datetimepicker-toggle-button, .form-outline .form-control[readonly] ~ .select-arrow {
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-outline .form-control.form-control-lg {
  font-size: 1rem;
  line-height: 2.15;
}

.form-outline .form-control.form-control-lg ~ .form-label {
  padding-top: .7rem;
}

.form-outline .form-control.form-control-lg:focus ~ .form-label, .form-outline .form-control.form-control-lg.active ~ .form-label {
  transform: translateY(-1.25rem)translateY(.1rem)scale(.8);
}

.form-outline .form-control.form-control-sm {
  padding-top: .32rem;
  padding-bottom: .32rem;
  font-size: .775rem;
  line-height: 1.5;
}

.form-outline .form-control.form-control-sm ~ .form-label {
  padding-top: .33rem;
  font-size: .775rem;
}

.form-outline .form-control.form-control-sm:focus ~ .form-label, .form-outline .form-control.form-control-sm.active ~ .form-label {
  transform: translateY(-.85rem)translateY(.1rem)scale(.8);
}

.form-outline.form-white .form-control {
  color: #fff;
}

.form-outline.form-white .form-control ~ .form-label {
  color: #fbfbfb;
}

.form-outline.form-white .form-control ~ .form-notch div {
  border-color: #fbfbfb;
}

.form-outline.form-white .form-control:focus ~ .form-label {
  color: #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: #0000 #fff #fff;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0 1px #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: #fff;
  box-shadow: -1px 0 #fff, 0 1px #fff, 0 -1px #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #fff;
  box-shadow: 1px 0 #fff, 0 -1px #fff, 0 1px #fff;
}

.form-outline.form-white .form-control::placeholder {
  color: #ffffffb3;
}

.form-outline.form-white .form-control:disabled, .form-outline.form-white .form-control.disabled, .form-outline.form-white .form-control[readonly] {
  background-color: #ffffff73;
}

.select-input.form-control[readonly]:not([disabled]) {
  background-color: #0000;
}

.form-select {
  transition: all .2s linear;
}

.form-select:focus {
  border-color: #3b71ca;
  outline: 0;
  box-shadow: inset 0 0 0 1px #3b71ca;
}

.form-check {
  min-height: 1.5rem;
}

.form-check-input {
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  border: .125rem solid #bdbdbd;
  border: .125rem solid var(--mdb-form-control-border-color);
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
}

.form-check-input:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: #0000;
  border-radius: 50%;
  width: .875rem;
  height: .875rem;
  position: absolute;
  transform: scale(0);
  box-shadow: 0 0 0 13px #0000;
}

.form-check-input:hover {
  cursor: pointer;
}

.form-check-input:hover:before {
  opacity: .04;
  box-shadow: 0 0 0 13px #0009;
  box-shadow: 0px 0px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #bdbdbd;
  border-color: var(--mdb-form-control-border-color);
  transition: border-color .2s;
}

.form-check-input:focus:before {
  opacity: .12;
  box-shadow: 0 0 0 13px #0009;
  box-shadow: 0px 0px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
}

.form-check-input:checked {
  border-color: #3b71ca;
}

.form-check-input:checked:before {
  opacity: .16;
}

.form-check-input:checked:after {
  content: "";
  position: absolute;
}

.form-check-input:checked:focus {
  border-color: #3b71ca;
}

.form-check-input:checked:focus:before {
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 0 0 0 13px #3b71ca;
}

.form-check-input:indeterminate:focus:before {
  box-shadow: 0 0 0 13px #3b71ca;
}

.form-check-input[type="checkbox"] {
  border-radius: .25rem;
  margin-top: .19em;
  margin-right: 6px;
}

.form-check-input[type="checkbox"]:focus:after {
  content: "";
  z-index: 1;
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  border-radius: 0;
  width: .875rem;
  height: .875rem;
  display: block;
  position: absolute;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #3b71ca;
  background-image: none;
}

.form-check-input[type="checkbox"]:checked:after {
  background-color: #0000;
  border: .125rem solid #fff;
  border-top: 0;
  border-left: 0;
  width: .375rem;
  height: .8125rem;
  margin-top: -1px;
  margin-left: .25rem;
  display: block;
  transform: rotate(45deg);
}

.form-check-input[type="checkbox"]:checked:focus {
  background-color: #3b71ca;
}

.form-check-input[type="checkbox"]:indeterminate {
  border-color: #3b71ca;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .125em;
  margin-right: 4px;
}

.form-check-input[type="radio"]:before {
  width: 1rem;
  height: 1rem;
}

.form-check-input[type="radio"]:after {
  content: "";
  z-index: 1;
  background-color: #fff;
  background-color: var(--mdb-body-bg);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
}

.form-check-input[type="radio"]:checked {
  background-color: #fff;
  background-image: none;
  background-color: var(--mdb-body-bg);
}

.form-check-input[type="radio"]:checked:after {
  background-color: #3b71ca;
  border-color: #3b71ca;
  border-radius: 50%;
  width: .625rem;
  height: .625rem;
  transition: border-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-check-input[type="radio"]:checked:focus {
  background-color: #fff;
  background-color: var(--mdb-body-bg);
}

.form-check-label {
  padding-left: .15rem;
}

.form-check-label:hover {
  cursor: pointer;
}

.form-control[type="file"] {
  border-color: #bdbdbd;
  border-color: var(--mdb-form-control-border-color);
}

.form-control[type="file"]::-webkit-file-upload-button {
  background-color: #0000;
}

.form-control[type="file"]:disabled {
  background-color: #e0e0e0;
  background-color: var(--mdb-form-control-disabled-bg);
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-control[type="file"]:disabled::-webkit-file-upload-button {
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-control[type="file"]:disabled::file-selector-button {
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #0000;
}

.input-group {
  flex-wrap: nowrap;
}

.input-group > .form-control {
  height: calc(2.08rem + 2px);
  min-height: calc(2.08rem + 2px);
  padding-top: .27rem;
  padding-bottom: .27rem;
  transition: all .2s linear;
}

.input-group > .form-control:focus {
  border-color: #3b71ca;
  outline: 0;
  transition: all .2s linear;
  box-shadow: inset 0 0 0 1px #3b71ca;
}

.input-group-text {
  background-color: #0000;
  padding-top: .26rem;
  padding-bottom: .26rem;
}

.input-group-text > .form-check-input[type="checkbox"] {
  margin-left: 1px;
  margin-right: 1px;
}

.input-group-text > .form-check-input[type="radio"] {
  margin-right: 0;
}

.input-group.form-outline .input-group-text {
  border-left: 0;
}

.input-group.form-outline input + .input-group-text {
  border: 0;
  border-left: 1px solid #bdbdbd;
}

.input-group .form-outline:not(:first-child), .input-group .select-wrapper:not(:first-child), .input-group .form-outline:not(:first-child) .form-notch-leading, .input-group .select-wrapper:not(:first-child) .form-notch-leading {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group .form-outline:not(:last-child), .input-group .select-wrapper:not(:last-child), .input-group .form-outline:not(:last-child) .form-notch-trailing, .input-group .select-wrapper:not(:last-child) .form-notch-trailing {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > [class*="btn-outline-"] + [class*="btn-outline-"] {
  border-left: 0;
}

.input-group > .btn[class*="btn-outline-"] {
  padding-top: .47rem;
}

.input-group > .btn {
  padding-top: .59rem;
}

.input-group .input-group-text {
  height: calc(2.08rem + 2px);
}

.input-group .btn {
  line-height: 1;
}

INPUT:-webkit-autofill {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

SELECT:-webkit-autofill {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

TEXTAREA:-webkit-autofill {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

INPUT:-webkit-autofill:hover {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

INPUT:-webkit-autofill:focus {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

INPUT:-webkit-autofill:active {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

SELECT:-webkit-autofill:hover {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

SELECT:-webkit-autofill:focus {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

SELECT:-webkit-autofill:active {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

TEXTAREA:-webkit-autofill:hover {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

TEXTAREA:-webkit-autofill:focus {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

TEXTAREA:-webkit-autofill:active {
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  box-shadow: inset 0 0 20px 20px #fff;
  -webkit-box-shadow: inset 0 0 20px 20px #fff !important;
}

INPUT:not(:autofill) {
  animation-name: onautofillcancel;
}

SELECT:not(:autofill) {
  animation-name: onautofillcancel;
}

TEXTAREA:not(:autofill) {
  animation-name: onautofillcancel;
}

@keyframes onautofillstart {
}

@keyframes onautofillcancel {
}

.was-validated .input-group .invalid-feedback, .was-validated .input-group .valid-feedback, .input-group .invalid-feedback, .input-group .valid-feedback {
  margin-top: 2.5rem;
}

.valid-feedback {
  color: #14a44d;
  width: auto;
  margin-top: -.75rem;
  font-size: .875rem;
  display: none;
  position: absolute;
}

.was-validated :valid ~ .valid-feedback, .is-valid ~ .valid-feedback {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none;
  border-color: #14a44d;
  margin-bottom: 1rem;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #14a44d;
  box-shadow: 0 0 0 .25rem #14a44d40;
}

.was-validated .form-outline .form-control:valid ~ .form-label, .form-outline .form-control.is-valid ~ .form-label {
  color: #14a44d;
}

.was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-valid ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid ~ .form-notch .form-notch-trailing {
  border-color: #14a44d;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid.active ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid.active ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-middle {
  box-shadow: 0 1px #14a44d;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-leading {
  border-color: #14a44d;
  box-shadow: -1px 0 #14a44d, 0 1px #14a44d, 0 -1px #14a44d;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-trailing {
  border-color: #14a44d;
  box-shadow: 1px 0 #14a44d, 0 -1px #14a44d, 0 1px #14a44d;
}

.was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #14a44d, 0 1px #14a44d, 0 -1px #14a44d;
}

.was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  box-shadow: 0 1px #14a44d;
}

.was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #14a44d, 0 -1px #14a44d, 0 1px #14a44d;
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #14a44d;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #14a44d;
  box-shadow: 0 0 0 .25rem #14a44d40;
}

.was-validated .form-select:valid ~ .valid-feedback, .form-select.is-valid ~ .valid-feedback {
  margin-top: 0;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid {
  margin-bottom: 0;
}

.was-validated input[type="file"].form-control:valid .valid-feedback, input[type="file"].form-control.is-valid .valid-feedback {
  margin-top: 0;
}

.was-validated input[type="file"].form-control:valid:focus, input[type="file"].form-control.is-valid:focus {
  border-color: #14a44d;
  box-shadow: inset 0 0 0 1px #14a44d;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #14a44d;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #14a44d;
}

.was-validated .form-check-input:valid:checked:focus:before, .form-check-input.is-valid:checked:focus:before {
  box-shadow: 0 0 0 13px #14a44d;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:valid:focus:before, .form-check-input.is-valid:focus:before {
  box-shadow: 0 0 0 13px #14a44d;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #14a44d;
  margin-bottom: 1rem;
}

.was-validated .form-check-input:valid[type="checkbox"]:checked:focus, .form-check-input.is-valid[type="checkbox"]:checked:focus {
  background-color: #14a44d;
  border-color: #14a44d;
}

.was-validated .form-check-input:valid[type="radio"]:checked, .form-check-input.is-valid[type="radio"]:checked {
  background-color: #fff;
  border-color: #14a44d;
}

.was-validated .form-check-input:valid[type="radio"]:checked:focus:before, .form-check-input.is-valid[type="radio"]:checked:focus:before {
  box-shadow: 0 0 0 13px #14a44d;
}

.was-validated .form-check-input:valid[type="radio"]:checked:after, .form-check-input.is-valid[type="radio"]:checked:after {
  background-color: #14a44d;
  border-color: #14a44d;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.invalid-feedback {
  color: #dc4c64;
  width: auto;
  margin-top: -.75rem;
  font-size: .875rem;
  display: none;
  position: absolute;
}

.was-validated :invalid ~ .invalid-feedback, .is-invalid ~ .invalid-feedback {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
  border-color: #dc4c64;
  margin-bottom: 1rem;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc4c64;
  box-shadow: 0 0 0 .25rem #dc4c6440;
}

.was-validated .form-outline .form-control:invalid ~ .form-label, .form-outline .form-control.is-invalid ~ .form-label {
  color: #dc4c64;
}

.was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-trailing {
  border-color: #dc4c64;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid.active ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid.active ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-middle {
  box-shadow: 0 1px #dc4c64;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-leading {
  border-color: #dc4c64;
  box-shadow: -1px 0 #dc4c64, 0 1px #dc4c64, 0 -1px #dc4c64;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-trailing {
  border-color: #dc4c64;
  box-shadow: 1px 0 #dc4c64, 0 -1px #dc4c64, 0 1px #dc4c64;
}

.was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #dc4c64, 0 1px #dc4c64, 0 -1px #dc4c64;
}

.was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  box-shadow: 0 1px #dc4c64;
}

.was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #dc4c64, 0 -1px #dc4c64, 0 1px #dc4c64;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc4c64;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc4c64;
  box-shadow: 0 0 0 .25rem #dc4c6440;
}

.was-validated .form-select:invalid ~ .invalid-feedback, .form-select.is-invalid ~ .invalid-feedback {
  margin-top: 0;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid {
  margin-bottom: 0;
}

.was-validated input[type="file"].form-control:invalid .invalid-feedback, input[type="file"].form-control.is-invalid .invalid-feedback {
  margin-top: 0;
}

.was-validated input[type="file"].form-control:invalid:focus, input[type="file"].form-control.is-invalid:focus {
  border-color: #dc4c64;
  box-shadow: inset 0 0 0 1px #dc4c64;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc4c64;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc4c64;
}

.was-validated .form-check-input:invalid:checked:focus:before, .form-check-input.is-invalid:checked:focus:before {
  box-shadow: 0 0 0 13px #dc4c64;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:invalid:focus:before, .form-check-input.is-invalid:focus:before {
  box-shadow: 0 0 0 13px #dc4c64;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc4c64;
  margin-bottom: 1rem;
}

.was-validated .form-check-input:invalid[type="checkbox"]:checked:focus, .form-check-input.is-invalid[type="checkbox"]:checked:focus {
  background-color: #dc4c64;
  border-color: #dc4c64;
}

.was-validated .form-check-input:invalid[type="radio"]:checked, .form-check-input.is-invalid[type="radio"]:checked {
  background-color: #fff;
  border-color: #dc4c64;
}

.was-validated .form-check-input:invalid[type="radio"]:checked:focus:before, .form-check-input.is-invalid[type="radio"]:checked:focus:before {
  box-shadow: 0 0 0 13px #dc4c64;
}

.was-validated .form-check-input:invalid[type="radio"]:checked:after, .form-check-input.is-invalid[type="radio"]:checked:after {
  background-color: #dc4c64;
  border-color: #dc4c64;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.table {
  --mdb-table-font-size: .9rem;
  --mdb-table-divider-color: currentcolor;
  font-size: .9rem;
  font-size: var(--mdb-table-font-size);
}

.table th {
  font-weight: 500;
}

.table tbody {
  font-weight: 400;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

.table-light {
  --mdb-table-color: #000;
  --mdb-table-bg: #fbfbfb;
  --mdb-table-border-color: #c9c9c9;
  --mdb-table-striped-bg: #eee;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e2e2e2;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #e8e8e8;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: #c9c9c9;
  border-color: var(--mdb-table-border-color);
}

.table-hover > tbody > tr {
  transition: all .5s;
}

.table-hover > tbody > tr:hover {
  --mdb-table-accent-bg: transparent;
  background-color: var(--mdb-table-hover-bg);
}

.btn {
  --mdb-btn-padding-top: .625rem;
  --mdb-btn-padding-bottom: .5rem;
  --mdb-btn-border-width: 0;
  --mdb-btn-border-color: none;
  --mdb-btn-border-radius: .25rem;
  --mdb-btn-box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  --mdb-btn-hover-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-focus-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-active-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  padding-top: .625rem;
  padding-top: var(--mdb-btn-padding-top);
  padding-bottom: .5rem;
  padding-bottom: var(--mdb-btn-padding-bottom);
  text-transform: uppercase;
  vertical-align: bottom;
  border-radius: .25rem;
  border-radius: var(--mdb-btn-border-radius);
  box-shadow: 0 4px 9px -4px #00000059;
  box-shadow: var(--mdb-btn-box-shadow);
  border: 0;
}

:not(.btn-check) + .btn:hover, .btn:first-child:hover, .btn:hover, .btn:focus-visible {
  box-shadow: var(--mdb-btn-hover-box-shadow);
}

.btn-check:focus + .btn, .btn:focus, .btn-check:focus-visible + .btn {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  box-shadow: var(--mdb-btn-active-box-shadow);
}

.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

[class*="btn-outline-"] {
  --mdb-btn-padding-top: .5rem;
  --mdb-btn-padding-bottom: .375rem;
  --mdb-btn-padding-x: 1.375rem;
  --mdb-btn-border-width: 2px;
  --mdb-btn-line-height: 1.5;
  padding: .5rem 1.375rem .375rem;
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  border-width: 2px;
  border-width: var(--mdb-btn-border-width);
  box-shadow: none;
  border-style: solid;
}

:not(.btn-check) + [class*="btn-outline-"]:hover, [class*="btn-outline-"]:first-child:hover, [class*="btn-outline-"]:hover, [class*="btn-outline-"]:focus-visible, .btn-check:focus + [class*="btn-outline-"], [class*="btn-outline-"]:focus, .btn-check:focus-visible + [class*="btn-outline-"], .btn-check:checked + [class*="btn-outline-"], .btn-check:active + [class*="btn-outline-"], [class*="btn-outline-"]:active, [class*="btn-outline-"].active, [class*="btn-outline-"].show, .btn-check:checked + [class*="btn-outline-"]:focus, .btn-check:active + [class*="btn-outline-"]:focus, [class*="btn-outline-"]:active:focus, [class*="btn-outline-"].active:focus, [class*="btn-outline-"].show:focus, [class*="btn-outline-"]:disabled, [class*="btn-outline-"].disabled {
  box-shadow: none;
}

[class*="btn-outline-"].btn-lg {
  --mdb-btn-padding-top: .625rem;
  --mdb-btn-padding-bottom: .5625rem;
  --mdb-btn-padding-x: 1.5625rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-line-height: 1.6;
}

[class*="btn-outline-"].btn-sm {
  --mdb-btn-padding-top: .25rem;
  --mdb-btn-padding-bottom: .1875rem;
  --mdb-btn-padding-x: .875rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-secondary {
  box-shadow: none;
}

.btn-secondary:focus-visible {
  box-shadow: none !important;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-check:focus-visible + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .btn-secondary.show, .btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .btn-secondary.show:focus, .btn-secondary:disabled, .btn-secondary.disabled {
  box-shadow: none;
}

.btn-primary {
  --mdb-btn-bg: #3b71ca;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #386bc0;
  --mdb-btn-hover-bg: #386bc0;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #386bc0;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #3566b6;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #386bc04d, 0 4px 18px 0 #386bc033;
}

:not(.btn-check) + .btn-primary:hover, .btn-primary:first-child:hover, .btn-primary:hover, .btn-primary:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-check:focus-visible + .btn-primary {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show, .btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .btn-primary.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-primary:hover, .btn-check:active + .btn-primary:hover, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-primary:disabled, .btn-primary.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-secondary {
  --mdb-btn-bg: #e2eaf7;
  --mdb-btn-color: #294f8d;
  --mdb-btn-box-shadow: 0 4px 9px -4px #e3ebf7;
  --mdb-btn-hover-bg: #d7deeb;
  --mdb-btn-hover-color: #294f8d;
  --mdb-btn-focus-bg: #d7deeb;
  --mdb-btn-focus-color: #294f8d;
  --mdb-btn-active-bg: #d7deeb;
  --mdb-btn-active-color: #294f8d;
  --mdb-btn-box-shadow-state: transparent;
}

:not(.btn-check) + .btn-secondary:hover, .btn-secondary:first-child:hover, .btn-secondary:hover, .btn-secondary:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-check:focus-visible + .btn-secondary {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .btn-secondary.show, .btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .btn-secondary.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-secondary:hover, .btn-check:active + .btn-secondary:hover, .btn-secondary:active:hover, .btn-secondary.active:hover, .btn-secondary.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-success {
  --mdb-btn-bg: #14a44d;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #139c49;
  --mdb-btn-hover-bg: #139c49;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #139c49;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #129445;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #139c494d, 0 4px 18px 0 #139c4933;
}

:not(.btn-check) + .btn-success:hover, .btn-success:first-child:hover, .btn-success:hover, .btn-success:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-success, .btn-success:focus, .btn-check:focus-visible + .btn-success {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .btn-success.show, .btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .btn-success.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-success:hover, .btn-check:active + .btn-success:hover, .btn-success:active:hover, .btn-success.active:hover, .btn-success.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-success:disabled, .btn-success.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-warning {
  --mdb-btn-bg: #e4a11b;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #d9991a;
  --mdb-btn-hover-bg: #d9991a;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #d9991a;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #cd9118;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #d9991a4d, 0 4px 18px 0 #d9991a33;
}

:not(.btn-check) + .btn-warning:hover, .btn-warning:first-child:hover, .btn-warning:hover, .btn-warning:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-warning, .btn-warning:focus, .btn-check:focus-visible + .btn-warning {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .btn-warning.show, .btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .btn-warning.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-warning:hover, .btn-check:active + .btn-warning:hover, .btn-warning:active:hover, .btn-warning.active:hover, .btn-warning.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-warning:disabled, .btn-warning.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-info {
  --mdb-btn-bg: #54b4d3;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #50abc8;
  --mdb-btn-hover-bg: #50abc8;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #50abc8;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #4ca2be;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #50abc84d, 0 4px 18px 0 #50abc833;
}

:not(.btn-check) + .btn-info:hover, .btn-info:first-child:hover, .btn-info:hover, .btn-info:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-info, .btn-info:focus, .btn-check:focus-visible + .btn-info {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .btn-info.show, .btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .btn-info.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-info:hover, .btn-check:active + .btn-info:hover, .btn-info:active:hover, .btn-info.active:hover, .btn-info.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-info:disabled, .btn-info.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-light {
  --mdb-btn-bg: #f5f5f5;
  --mdb-btn-color: #616161;
  --mdb-btn-box-shadow: 0 4px 9px -4px #f6f6f6;
  --mdb-btn-hover-bg: #e9e9e9;
  --mdb-btn-hover-color: #616161;
  --mdb-btn-focus-bg: #f6f6f6;
  --mdb-btn-focus-color: #616161;
  --mdb-btn-active-bg: #ddd;
  --mdb-btn-active-color: #616161;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #eeeeee4d, 0 4px 18px 0 #eee3;
}

:not(.btn-check) + .btn-light:hover, .btn-light:first-child:hover, .btn-light:hover, .btn-light:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-light, .btn-light:focus, .btn-check:focus-visible + .btn-light {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .btn-light.show, .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .btn-light.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-light:hover, .btn-check:active + .btn-light:hover, .btn-light:active:hover, .btn-light.active:hover, .btn-light.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-light:disabled, .btn-light.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-dark {
  --mdb-btn-bg: #262626;
  --mdb-btn-color: #eee;
  --mdb-btn-box-shadow: 0 4px 9px -4px #313131;
  --mdb-btn-hover-bg: #313131;
  --mdb-btn-hover-color: #eee;
  --mdb-btn-focus-bg: #313131;
  --mdb-btn-focus-color: #eee;
  --mdb-btn-active-bg: #3c3c3c;
  --mdb-btn-active-color: #eee;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #302b2b4d, 0 4px 18px 0 #302b2b33;
}

:not(.btn-check) + .btn-dark:hover, .btn-dark:first-child:hover, .btn-dark:hover, .btn-dark:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus + .btn-dark, .btn-dark:focus, .btn-check:focus-visible + .btn-dark {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .btn-dark.show, .btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .btn-dark.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-dark:hover, .btn-check:active + .btn-dark:hover, .btn-dark:active:hover, .btn-dark.active:hover, .btn-dark.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-dark:disabled, .btn-dark.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-outline-secondary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #294f8d;
  --mdb-btn-hover-bg: #f4f6f9;
  --mdb-btn-hover-color: #294f8d;
  --mdb-btn-focus-bg: #f4f6f9;
  --mdb-btn-focus-color: #294f8d;
  --mdb-btn-active-bg: #f4f6f9;
  --mdb-btn-active-color: #294f8d;
  --mdb-btn-outline-border-color: #e2eaf7;
  --mdb-btn-outline-focus-border-color: #d7deeb;
  --mdb-btn-outline-hover-border-color: #d7deeb;
  border-color: #e2eaf7;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-secondary:hover, .btn-outline-secondary:first-child:hover, .btn-outline-secondary:hover, .btn-outline-secondary:focus-visible {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus, .btn-check:focus-visible + .btn-outline-secondary {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  border-color: var(--mdb-btn-outline-border-color);
}

.btn-link {
  --mdb-btn-font-weight: 500;
  --mdb-btn-color: #3b71ca;
  --mdb-btn-hover-color: #386bc0;
  --mdb-btn-hover-bg: #f5f5f5;
  --mdb-btn-focus-color: #3566b6;
  --mdb-btn-active-color: #3260ac;
  --mdb-btn-disabled-color: #9e9e9e;
  --mdb-btn-box-shadow: none;
  box-shadow: none;
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: none;
}

:not(.btn-check) + .btn-link:hover, .btn-link:first-child:hover, .btn-link:hover, .btn-link:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: none;
}

.btn-check:focus + .btn-link, .btn-link:focus, .btn-check:focus-visible + .btn-link {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-link, .btn-check:active + .btn-link, .btn-link:active, .btn-link.active, .btn-link.show {
  color: var(--mdb-btn-active-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-link:focus, .btn-check:active + .btn-link:focus, .btn-link:active:focus, .btn-link.active:focus, .btn-link.show:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-link:disabled, .btn-link.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-tertiary {
  --mdb-btn-font-weight: 500;
  --mdb-btn-color: #3b71ca;
  --mdb-btn-hover-color: #386bc0;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-focus-color: #3566b6;
  --mdb-btn-active-color: #3260ac;
  --mdb-btn-disabled-color: #9e9e9e;
  --mdb-btn-box-shadow: none;
  box-shadow: none;
  box-shadow: var(--mdb-btn-box-shadow);
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
}

:not(.btn-check) + .btn-tertiary:hover, .btn-tertiary:first-child:hover, .btn-tertiary:hover, .btn-tertiary:focus-visible {
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: none;
}

.btn-check:focus + .btn-tertiary, .btn-tertiary:focus, .btn-check:focus-visible + .btn-tertiary {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-tertiary, .btn-check:active + .btn-tertiary, .btn-tertiary:active, .btn-tertiary.active, .btn-tertiary.show {
  color: var(--mdb-btn-active-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-tertiary:focus, .btn-check:active + .btn-tertiary:focus, .btn-tertiary:active:focus, .btn-tertiary.active:focus, .btn-tertiary.show:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-tertiary:disabled, .btn-tertiary.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-lg {
  --mdb-btn-padding-top: .75rem;
  --mdb-btn-padding-bottom: .6875rem;
  --mdb-btn-padding-x: 1.6875rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-line-height: 1.6;
}

.btn-sm {
  --mdb-btn-padding-top: .375rem;
  --mdb-btn-padding-bottom: .3125rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-floating, [class*="btn-outline-"].btn-floating {
  --mdb-btn-border-radius: 50%;
  border-radius: 50%;
  border-radius: var(--mdb-btn-border-radius);
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
  position: relative;
}

.btn-floating {
  --mdb-btn-width: 2.3125rem;
  --mdb-btn-height: 2.3125rem;
  --mdb-btn-icon-width: 2.3125rem;
  --mdb-btn-icon-line-height: 2.3125rem;
  --mdb-btn-width-lg: 2.8125rem;
  --mdb-btn-height-lg: 2.8125rem;
  --mdb-btn-icon-width-lg: 2.8125rem;
  --mdb-btn-icon-line-height-lg: 2.8125rem;
  --mdb-btn-width-sm: 1.8125rem;
  --mdb-btn-height-sm: 1.8125rem;
  --mdb-btn-icon-width-sm: 1.8125rem;
  --mdb-btn-icon-line-height-sm: 1.8125rem;
  width: var(--mdb-btn-width);
  height: var(--mdb-btn-height);
  width: 2.3125rem;
  height: 2.3125rem;
}

.btn-floating .fas, .btn-floating .far {
  width: var(--mdb-btn-icon-width);
  line-height: var(--mdb-btn-icon-line-height);
}

.btn-floating.btn-lg {
  width: var(--mdb-btn-width-lg);
  height: var(--mdb-btn-height-lg);
}

.btn-floating.btn-lg .fas, .btn-floating.btn-lg .far {
  width: var(--mdb-btn-icon-width-lg);
  line-height: var(--mdb-btn-icon-line-height-lg);
}

.btn-floating.btn-sm {
  width: var(--mdb-btn-width-sm);
  height: var(--mdb-btn-height-sm);
}

.btn-floating.btn-sm .fas, .btn-floating.btn-sm .far {
  width: var(--mdb-btn-icon-width-sm);
  line-height: var(--mdb-btn-icon-line-height-sm);
}

[class*="btn-outline-"].btn-floating {
  --mdb-btn-icon-width: 2.0625rem;
  --mdb-btn-icon-width-lg: 2.5625rem;
  --mdb-btn-icon-width-sm: 1.5625rem;
  --mdb-btn-icon-line-height: 2.0625rem;
  --mdb-btn-icon-line-height-lg: 2.5625rem;
  --mdb-btn-icon-line-height-sm: 1.5625rem;
}

[class*="btn-outline-"].btn-floating .fas, [class*="btn-outline-"].btn-floating .far {
  width: var(--mdb-btn-icon-width);
  line-height: var(--mdb-btn-icon-line-height);
}

[class*="btn-outline-"].btn-floating.btn-lg .fas, [class*="btn-outline-"].btn-floating.btn-lg .far {
  width: var(--mdb-btn-icon-width-lg);
  line-height: var(--mdb-btn-icon-line-height-lg);
}

[class*="btn-outline-"].btn-floating.btn-sm .fas, [class*="btn-outline-"].btn-floating.btn-sm .far {
  width: var(--mdb-btn-icon-width-sm);
  line-height: var(--mdb-btn-icon-line-height-sm);
}

.fixed-action-btn {
  --mdb-btn-right: 2.1875rem;
  --mdb-btn-bottom: 2.1875rem;
  --mdb-btn-zindex: 1030;
  --mdb-btn-padding-top: .9375rem;
  --mdb-btn-padding-bottom: 1.25rem;
  --mdb-btn-padding-x: 1.25rem;
  --mdb-btn-margin-bottom: 1.5rem;
  right: 2.1875rem;
  right: var(--mdb-btn-right);
  bottom: 2.1875rem;
  bottom: var(--mdb-btn-bottom);
  z-index: 1030;
  z-index: var(--mdb-btn-zindex);
  padding: .9375rem 1.25rem 1.25rem;
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  flex-flow: column-reverse;
  align-items: center;
  height: auto;
  margin-bottom: 0;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.fixed-action-btn > .btn-floating {
  z-index: 10;
  position: relative;
  transform: scale(1.2);
}

.fixed-action-btn ul {
  text-align: center;
  opacity: 0;
  z-index: -1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  transition: transform .4s, opacity .4s;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.fixed-action-btn ul li {
  z-index: 0;
  margin-right: auto;
  margin-bottom: var(--mdb-btn-margin-bottom);
  margin-left: auto;
  display: flex;
}

.fixed-action-btn ul li:first-of-type {
  margin-top: calc(var(--mdb-btn-margin-bottom) * .5);
}

.fixed-action-btn ul a.btn {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.fixed-action-btn ul a.btn.shown, .fixed-action-btn.active ul {
  opacity: 1;
}

.btn-block {
  --mdb-btn-margin-top: .5rem;
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: var(--mdb-btn-margin-top);
}

.dropdown-menu {
  --mdb-dropdown-item-border-radius: .5rem;
  color: var(--mdb-dropdown-color);
  box-shadow: var(--mdb-dropdown-box-shadow);
  font-size: var(--mdb-dropdown-font-size);
  margin: 0;
  margin-top: var(--mdb-dropdown-spacer);
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  top: 100%;
  left: 0;
}

.dropdown-menu > li {
  border-radius: 0;
}

.dropdown-menu > li:first-child, .dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--mdb-dropdown-item-border-radius);
  border-top-right-radius: var(--mdb-dropdown-item-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-menu > li:not(:first-child):not(:last-child) .dropdown-item {
  border-radius: 0;
}

.dropdown-menu > li:last-child, .dropdown-menu > li:last-child .dropdown-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--mdb-dropdown-item-border-radius);
  border-bottom-right-radius: var(--mdb-dropdown-item-border-radius);
}

.dropdown-menu.animation {
  --mdb-dropdown-menu-animated-animation-duration: .55s;
  --mdb-dropdown-menu-animated-animation-timing-function: ease;
  animation-duration: .55s;
  animation-duration: var(--mdb-dropdown-menu-animated-animation-duration);
  animation-timing-function: ease;
  animation-timing-function: var(--mdb-dropdown-menu-animated-animation-timing-function);
  display: block;
}

.dropdown-item {
  --mdb-dropdown-state-color: var(--mdb-surface-color);
  --mdb-dropdown-state-background-color: var(--mdb-highlight-bg-color);
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  color: var(--mdb-dropdown-color);
  border-radius: 0;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item.active, .dropdown-item:active {
  color: var(--mdb-dropdown-state-color);
  background-color: var(--mdb-dropdown-state-background-color);
}

.dropdown-item:focus {
  outline: none;
}

.animation {
  padding: auto;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@media (prefers-reduced-motion) {
  .animation {
    animation: unset !important;
    transition: none !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
}

.dropdown-divider {
  --mdb-dropdown-divider-border-top-width: 2px;
  --mdb-dropdown-divider-border-top-bg: var(--mdb-divider-color);
  border-top: 2px solid #f5f5f5;
  border-top: var(--mdb-dropdown-divider-border-top-width) solid var(--mdb-dropdown-divider-border-top-bg);
  opacity: 1;
}

.dropdown-menu INPUT:not(:autofill) {
  animation-name: none !important;
}

.dropdown-menu SELECT:not(:autofill) {
  animation-name: none !important;
}

.dropdown-menu TEXTAREA:not(:autofill) {
  animation-name: none !important;
}

.btn-group {
  --mdb-btn-box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  --mdb-btn-hover-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-focus-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-active-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-group-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 4px 9px -4px #00000059;
  box-shadow: var(--mdb-btn-box-shadow);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: var(--mdb-btn-group-transition);
}

.btn-group:hover {
  box-shadow: var(--mdb-btn-hover-box-shadow);
}

.btn-group:focus, .btn-group.focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-group:active, .btn-group.active {
  box-shadow: var(--mdb-btn-active-box-shadow);
}

.btn-group:active:focus, .btn-group.active:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-group:disabled, .btn-group.disabled {
  box-shadow: var(--mdb-btn-box-shadow);
  border: 0;
}

.btn-group > .btn {
  box-shadow: none;
}

:not(.btn-check) + .btn-group > .btn:hover, .btn-group > .btn:first-child:hover, .btn-group > .btn:hover, .btn-group > .btn:focus-visible {
  box-shadow: none !important;
}

.btn-check:focus + .btn-group > .btn, .btn-group > .btn:focus, .btn-check:focus-visible + .btn-group > .btn, .btn-check:checked + .btn-group > .btn, .btn-check:active + .btn-group > .btn, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group > .btn.show, .btn-check:checked + .btn-group > .btn:focus, .btn-check:active + .btn-group > .btn:focus, .btn-group > .btn:active:focus, .btn-group > .btn.active:focus, .btn-group > .btn.show:focus, .btn-group > .btn:disabled, .btn-group > .btn.disabled, .btn-group > .btn-group {
  box-shadow: none;
}

.btn-group > .btn-link:first-child, .btn-group > .btn-tertiary:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-link:last-child, .btn-group > .btn-tertiary:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group {
  --mdb-btn-border-radius: .25rem;
  border-radius: .25rem;
  border-radius: var(--mdb-btn-border-radius);
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  --mdb-nav-tabs-link-font-weight: 500;
  --mdb-nav-tabs-link-font-size: 12px;
  --mdb-nav-tabs-link-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-nav-tabs-link-padding-top: 17px;
  --mdb-nav-tabs-link-padding-bottom: 16px;
  --mdb-nav-tabs-link-padding-x: 29px;
  --mdb-nav-tabs-link-hover-bgc: var(--mdb-highlight-bg-color);
  --mdb-nav-tabs-link-border-bottom-width: 2px;
  --mdb-nav-tabs-link-active-color: #3b71ca;
  --mdb-nav-tabs-link-active-border-color: #3b71ca;
  border-width: 0 0 2px;
  border-bottom-style: solid;
  border-bottom-color: #0000;
  border-bottom: var(--mdb-nav-tabs-link-border-bottom-width) solid #0000;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  font-weight: var(--mdb-nav-tabs-link-font-weight);
  font-size: 12px;
  font-size: var(--mdb-nav-tabs-link-font-size);
  color: var(--mdb-nav-tabs-link-color);
  padding: 17px 29px 16px;
  padding: var(--mdb-nav-tabs-link-padding-top) var(--mdb-nav-tabs-link-padding-x) var(--mdb-nav-tabs-link-padding-bottom) var(--mdb-nav-tabs-link-padding-x);
  border-radius: 0;
}

.nav-tabs .nav-link:hover {
  background-color: var(--mdb-nav-tabs-link-hover-bgc);
  border-color: #0000;
}

.nav-tabs .nav-link:focus {
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--mdb-nav-tabs-link-active-color);
  border-color: var(--mdb-nav-tabs-link-active-border-color);
}

.nav-justified .nav-item .nav-link {
  width: auto;
}

.navbar {
  --mdb-navbar-box-shadow: 0 4px 12px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 4px rgba(var(--mdb-box-shadow-color-rgb), .05);
  --mdb-navbar-padding-top: .5625rem;
  --mdb-navbar-brand-img-margin-right: .25rem;
  box-shadow: 0 4px 12px #00000012, 0 2px 4px #0000000d;
  box-shadow: var(--mdb-navbar-box-shadow);
  padding-top: .5625rem;
  padding-top: var(--mdb-navbar-padding-top);
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-dark .navbar-toggler {
  border: 0;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-dark {
  --mdb-navbar-color: #ffffff8c;
  --mdb-navbar-hover-color: #ffffffbf;
  --mdb-navbar-disabled-color: #ffffff40;
  --mdb-navbar-active-color: #fff;
  --mdb-navbar-brand-color: #fff;
  --mdb-navbar-brand-hover-color: #fff;
  --mdb-navbar-toggler-border-color: #ffffff1a;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  border: 0;
}

.card-body[class*="bg-"] {
  border-bottom-left-radius: var(--mdb-card-border-radius);
  border-bottom-right-radius: var(--mdb-card-border-radius);
}

.navbar .breadcrumb {
  --mdb-breadcrumb-item-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-breadcrumb-item-hover-color: rgba(var(--mdb-emphasis-color-rgb), .7);
  --mdb-breadcrumb-item-before-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-breadcrumb-item-transition: color .15s ease-in-out;
  background-color: #0000;
  margin-bottom: 0;
}

.navbar .breadcrumb .breadcrumb-item a {
  color: var(--mdb-breadcrumb-item-color);
  transition: var(--mdb-breadcrumb-item-transition);
}

.navbar .breadcrumb .breadcrumb-item a:hover, .navbar .breadcrumb .breadcrumb-item a:focus {
  color: var(--mdb-breadcrumb-item-hover-color);
}

.navbar .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: var(--mdb-breadcrumb-item-before-color);
}

.pagination {
  --mdb-pagination-border-radius: .25rem;
  --mdb-pagination-active-transition: all .2s linear;
  --mdb-pagination-active-font-weight: 500;
  --mdb-pagination-circle-border-radius: 50%;
  --mdb-pagination-circle-padding-x: .841rem;
  --mdb-pagination-circle-padding-l-lg: 1.39941rem;
  --mdb-pagination-circle-padding-r-lg: 1.39942rem;
  --mdb-pagination-circle-padding-l-sm: .696rem;
  --mdb-pagination-circle-padding-r-sm: .688rem;
}

.page-link {
  border-radius: var(--mdb-pagination-border-radius);
  background-color: #0000;
  border: 0;
  outline: 0;
}

.page-link:focus {
  box-shadow: none;
}

.page-link.active, .active > .page-link {
  transition: var(--mdb-pagination-active-transition);
  font-weight: var(--mdb-pagination-active-font-weight);
  border: 0;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--mdb-pagination-border-radius);
  border-bottom-left-radius: var(--mdb-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--mdb-pagination-border-radius);
  border-bottom-right-radius: var(--mdb-pagination-border-radius);
}

.badge-notification {
  --mdb-badge-font-size: .6rem;
  --mdb-badge-padding-x: .45em;
  --mdb-badge-padding-y: .2em;
  --mdb-badge-margin-top: -.1rem;
  --mdb-badge-margin-left: -.5rem;
  font-size: .6rem;
  font-size: var(--mdb-badge-font-size);
  padding: .2em .45em;
  padding: var(--mdb-badge-padding-y) var(--mdb-badge-padding-x);
  margin-top: -.1rem;
  margin-top: var(--mdb-badge-margin-top);
  margin-left: -.5rem;
  margin-left: var(--mdb-badge-margin-left);
  position: absolute;
}

.alert {
  border: 0;
}

.alert-absolute {
  position: absolute;
}

.alert-fixed {
  --mdb-alert-fixed-z-index: 1070;
  z-index: 1070;
  z-index: var(--mdb-alert-fixed-z-index);
  position: fixed;
}

.parent-alert-relative {
  position: relative;
}

.alert-primary {
  background-color: #e2eaf7;
  background-color: var(--mdb-primary-bg-subtle);
  color: var(--mdb-primary-text-emphasis);
}

.alert-primary i {
  color: var(--mdb-primary-text-emphasis);
}

.alert-secondary {
  background-color: #f1f2f3;
  background-color: var(--mdb-secondary-bg-subtle);
  color: var(--mdb-secondary-text-emphasis);
}

.alert-secondary i {
  color: var(--mdb-secondary-text-emphasis);
}

.alert-success {
  background-color: #dcf1e4;
  background-color: var(--mdb-success-bg-subtle);
  color: var(--mdb-success-text-emphasis);
}

.alert-success i {
  color: var(--mdb-success-text-emphasis);
}

.alert-danger {
  background-color: #fae4e8;
  background-color: var(--mdb-danger-bg-subtle);
  color: var(--mdb-danger-text-emphasis);
}

.alert-danger i {
  color: var(--mdb-danger-text-emphasis);
}

.alert-warning {
  background-color: #fbf1dd;
  background-color: var(--mdb-warning-bg-subtle);
  color: var(--mdb-warning-text-emphasis);
}

.alert-warning i {
  color: var(--mdb-warning-text-emphasis);
}

.alert-info {
  background-color: #e5f4f8;
  background-color: var(--mdb-info-bg-subtle);
  color: var(--mdb-info-text-emphasis);
}

.alert-info i {
  color: var(--mdb-info-text-emphasis);
}

.alert-light {
  background-color: #f5f5f5;
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
}

.alert-light i {
  color: var(--mdb-light-text-emphasis);
}

.alert-dark {
  background-color: #262626;
  background-color: var(--mdb-dark-bg-subtle);
  color: var(--mdb-dark-text-emphasis);
}

.alert-dark i {
  color: var(--mdb-dark-text-emphasis);
}

.progress {
  box-shadow: none;
  border-radius: 0;
}

.list-group {
  --mdb-list-group-item-transition-time: .5s;
}

.list-group-light {
  --mdb-list-group-light-item-py: 1rem;
  --mdb-list-group-light-item-border: 2px solid var(--mdb-divider-color);
  --mdb-list-group-light-item-border-width: 2px;
  --mdb-list-group-light-active-border-radius: .5rem;
  --mdb-list-group-light-active-bg: var(--mdb-primary-bg-subtle);
  --mdb-list-group-light-active-color: var(--mdb-primary-text-emphasis);
}

.list-group-light .list-group-item {
  padding: var(--mdb-list-group-light-item-py) 0;
  border: var(--mdb-list-group-light-item-border);
}

.list-group-light > .list-group-item {
  border-width: 0 0 var(--mdb-list-group-light-item-border-width);
}

.list-group-light > .list-group-item:last-of-type {
  border: none;
}

.list-group-light .active {
  border-radius: var(--mdb-list-group-light-active-border-radius);
  background-color: var(--mdb-list-group-light-active-bg);
  color: var(--mdb-list-group-light-active-color);
  border: none;
}

.list-group-small {
  --mdb-list-group-small-item-py: .5rem;
}

.list-group-small .list-group-item {
  padding: var(--mdb-list-group-small-item-py) 0;
}

.btn-close:focus {
  box-shadow: none;
}

.modal-content {
  --mdb-modal-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-modal-box-shadow);
  border: 0;
}

.toast {
  --mdb-toast-border-bottom-width: 2px;
  --mdb-toast-btn-close-width: 1.3em;
  --mdb-toast-btn-close-mr: -.375rem;
  --mdb-toast-btn-close-ml: .75rem;
  --mdb-toast-bg: var(--mdb-surface-bg);
  --mdb-toast-header-bg: var(--mdb-surface-bg);
  border: 0;
}

.toast .btn-close {
  width: var(--mdb-toast-btn-close-width);
}

.toast-header {
  border-bottom-width: var(--mdb-toast-border-bottom-width);
}

.toast-header .btn-close {
  margin-right: var(--mdb-toast-btn-close-mr);
  margin-left: var(--mdb-toast-btn-close-ml);
}

.parent-toast-relative {
  position: relative;
}

.toast-absolute {
  position: absolute;
}

.toast-fixed {
  z-index: var(--mdb-toast-zindex);
  position: fixed;
}

.tooltip {
  --mdb-tooltip-font-size: 14px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  display: none;
}

.tooltip-inner {
  font-size: var(--mdb-tooltip-font-size);
}

.popover {
  --mdb-popover-border-bottom-width: 2px;
}

.popover .popover-arrow {
  display: none;
}

.popover-header {
  border-bottom: var(--mdb-popover-border-bottom-width) solid var(--mdb-popover-border-color);
}

.ripple-surface {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ripple-surface-unbound {
  overflow: visible;
}

.ripple-wave {
  --mdb-ripple-wave-cubicBezier: cubic-bezier(0, 0, .15, 1);
  --mdb-ripple-wave-border-radius: 50%;
  --mdb-ripple-wave-opacity: .5;
  --mdb-ripple-wave-transform: scale(0);
  --mdb-ripple-wave-z-index: 999;
  --mdb-ripple-wave-active-transform: scale(1);
  border-radius: 50%;
  border-radius: var(--mdb-ripple-wave-border-radius);
  opacity: .5;
  opacity: var(--mdb-ripple-wave-opacity);
  pointer-events: none;
  touch-action: none;
  transform: scale(0);
  transform: var(--mdb-ripple-wave-transform);
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, 0, .15, 1), cubic-bezier(0, 0, .15, 1);
  transition-timing-function: var(--mdb-ripple-wave-cubicBezier), var(--mdb-ripple-wave-cubicBezier);
  z-index: 999;
  z-index: var(--mdb-ripple-wave-z-index);
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
  position: absolute;
}

.ripple-wave.active {
  transform: var(--mdb-ripple-wave-active-transform);
  opacity: 0;
}

.btn .ripple-wave, .input-wrapper .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.range {
  --mdb-range-thumb-height: 30px;
  --mdb-range-thumb-width: 30px;
  --mdb-range-thumb-top: -35px;
  --mdb-range-thumb-margin-left: -15px;
  --mdb-range-thumb-border-radius: 50% 50% 50% 0;
  --mdb-range-thumb-transform: scale(0);
  --mdb-range-thumb-transition: transform .2s ease-in-out;
  --mdb-range-thumb-value-font-size: 12px;
  --mdb-range-thumb-value-line-height: 30px;
  --mdb-range-thumb-value-color: #fff;
  --mdb-range-thumb-value-font-weight: 500;
  --mdb-range-thumb-background: #3b71ca;
  position: relative;
}

.range .thumb {
  height: var(--mdb-range-thumb-height);
  width: var(--mdb-range-thumb-width);
  top: var(--mdb-range-thumb-top);
  margin-left: var(--mdb-range-thumb-margin-left);
  text-align: center;
  border-radius: var(--mdb-range-thumb-border-radius);
  transform: var(--mdb-range-thumb-transform);
  transform-origin: bottom;
  transition: var(--mdb-range-thumb-transition);
  display: block;
  position: absolute;
}

.range .thumb:after {
  content: "";
  border-radius: var(--mdb-range-thumb-border-radius);
  background: var(--mdb-range-thumb-background);
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  transform: rotate(-45deg);
}

.range .thumb .thumb-value {
  font-size: var(--mdb-range-thumb-value-font-size);
  line-height: var(--mdb-range-thumb-value-line-height);
  color: var(--mdb-range-thumb-value-color);
  font-weight: var(--mdb-range-thumb-value-font-weight);
  z-index: 2;
  display: block;
}

.range .thumb.thumb-active {
  transform: scale(1);
}

.accordion-button:not(.collapsed):focus {
  box-shadow: var(--mdb-accordion-btn-focus-box-shadow);
}

.accordion-button:focus {
  border-color: var(--mdb-accordion-btn-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.accordion-flush {
  --mdb-accordion-flush-btn-box-shadow: inset 0 -2px 0 var(--mdb-divider-color);
  --mdb-accordion-flush-border-bottom: 2px solid var(--mdb-divider-color);
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: var(--mdb-accordion-flush-btn-box-shadow);
}

.accordion-flush .accordion-item {
  border-bottom: var(--mdb-accordion-flush-border-bottom);
}

.accordion-borderless {
  --mdb-accordion-borderless-btn-border-radius: .5rem;
  --mdb-accordion-borderless-btn-bg: var(--mdb-primary-bg-subtle);
  --mdb-accordion-borderless-btn-color: var(--mdb-primary-text-emphasis);
}

.accordion-borderless .accordion-item {
  border: 0;
}

.accordion-borderless .accordion-item .accordion-button {
  border-radius: var(--mdb-accordion-borderless-btn-border-radius);
}

.accordion-borderless .accordion-item .accordion-button:not(.collapsed) {
  background-color: var(--mdb-accordion-borderless-btn-bg);
  color: var(--mdb-accordion-borderless-btn-color);
  box-shadow: none;
}

.carousel-control-prev-icon:after {
  content: "";
  --mdb-carousel-control-icon-font-size: 1.7rem;
  --mdb-carousel-control-icon-font-weight: 700;
  font-weight: 700;
  font-weight: var(--mdb-carousel-control-icon-font-weight);
  font-size: 1.7rem;
  font-size: var(--mdb-carousel-control-icon-font-size);
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
}

.carousel-control-next-icon:after {
  content: "";
  --mdb-carousel-control-icon-font-size: 1.7rem;
  --mdb-carousel-control-icon-font-weight: 700;
  font-weight: 700;
  font-weight: var(--mdb-carousel-control-icon-font-weight);
  font-size: 1.7rem;
  font-size: var(--mdb-carousel-control-icon-font-size);
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
}

.modal {
  --mdb-modal-top-left-top: 10px;
  --mdb-modal-top-left-left: 10px;
  --mdb-modal-top-right-top: 10px;
  --mdb-modal-top-right-right: 10px;
  --mdb-modal-bottom-left-bottom: 10px;
  --mdb-modal-bottom-left-left: 10px;
  --mdb-modal-bottom-right-bottom: 10px;
  --mdb-modal-bottom-right-right: 10px;
  --mdb-modal-fade-top-transform: translate3d(0, -25%, 0);
  --mdb-modal-fade-right-transform: translate3d(25%, 0, 0);
  --mdb-modal-fade-bottom-transform: translate3d(0, 25%, 0);
  --mdb-modal-fade-left-transform: translate3d(-25%, 0, 0);
  --mdb-modal-side-right: 10px;
  --mdb-modal-side-bottom: 10px;
  --mdb-modal-non-invasive-box-shadow: 0 2px 6px -1px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 6px 18px -1px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-modal-non-invasive-box-shadow-top: 0 -10px 20px 0 rgba(var(--mdb-box-shadow-color-rgb), .05);
}

@media (width >= 768px) {
  .modal .modal-dialog.modal-top-left {
    top: var(--mdb-modal-top-left-top);
    left: var(--mdb-modal-top-left-left);
  }

  .modal .modal-dialog.modal-top-right {
    top: var(--mdb-modal-top-right-top);
    right: var(--mdb-modal-top-right-right);
  }

  .modal .modal-dialog.modal-bottom-left {
    bottom: var(--mdb-modal-bottom-left-bottom);
    left: var(--mdb-modal-bottom-left-left);
  }

  .modal .modal-dialog.modal-bottom-right {
    right: var(--mdb-modal-bottom-right-right);
    bottom: var(--mdb-modal-bottom-right-bottom);
  }
}

.modal .modal-dialog.modal-bottom {
  bottom: 0;
}

.modal.fade.top:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-top-transform);
}

.modal.fade.right:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-right-transform);
}

.modal.fade.bottom:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-bottom-transform);
}

.modal.fade.left:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-left-transform);
}

.modal-open .modal.frame {
  overflow-y: hidden;
}

.modal-non-invasive-open {
  overflow-y: auto;
}

.modal-non-invasive-open .modal.modal-non-invasive-show {
  display: table;
}

@media (width >= 992px) {
  .modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-right, .modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-left {
    bottom: 0;
  }

  .modal-non-invasive-open .modal.modal-non-invasive-show.modal.frame.bottom, .modal-non-invasive-open .modal.modal-non-invasive-show.modal.frame.bottom .modal-content {
    box-shadow: var(--mdb-modal-non-invasive-box-shadow-top);
  }

  .modal-non-invasive-open .modal.modal-non-invasive-show.modal.frame.top {
    box-shadow: var(--mdb-modal-non-invasive-box-shadow);
  }
}

.modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-right {
  right: 0;
}

.modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-left, .modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-top-left {
  left: 0;
}

.ps {
  --mdb-scrollbar-rail-x-y-transition-opacity-bg: background-color .2s linear, opacity .2s linear;
  --mdb-scrollbar-z-index: 1035;
  --mdb-scrollbar-rail-x-y-length: .9375rem;
  --mdb-scrollbar-rail-x-y-opacity: .6;
  --mdb-scrollbar-rail-x-y-hover-opacity: .9;
  --mdb-scrollbar-rail-x-y-bg-color: var(--mdb-scrollbar-rail-bg);
  --mdb-scrollbar-rail-x-y-clicking-length: .6875rem;
  --mdb-scrollbar-rail-x-transition-height-bg: background-color .2s linear, height .2s ease-in-out;
  --mdb-scrollbar-rail-y-transition-width-bg: background-color .2s linear, width .2s ease-in-out;
  --mdb-scrollbar-thumb-x-y-color: var(--mdb-scrollbar-thumb-bg);
  --mdb-scrollbar-thumb-x-y-border-radius: .375rem;
  --mdb-scrollbar-thumb-x-y-length: .375rem;
  --mdb-scrollbar-thumb-x-y-position-length: .125rem;
  overflow-anchor: none;
  touch-action: auto;
  overflow: hidden !important;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.sidenav {
  --mdb-sidenav-transform: translateX(-100%);
  --mdb-sidenav-zindex: 1035;
  --mdb-sidenav-color: var(--mdb-surface-color);
  --mdb-sidenav-background-color: var(--mdb-surface-bg);
  --mdb-sidenav-width: 240px;
  --mdb-sidenav-height: 100vh;
  --mdb-sidenav-box-shadow: 0 4px 12px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 4px rgba(var(--mdb-box-shadow-color-rgb), .05);
  --mdb-sidenav-data-hidden-false-transform: translateX(0%);
  --mdb-sidenav-data-color-light-color: #fff9;
  --mdb-sidenav-data-right-true-transform: translateX(100%);
  --mdb-sidenav-data-slim-collapsed-true-width: 77px;
  --mdb-sidenav-menu-padding: .2rem;
  --mdb-sidenav-collapse-sidenav-link-font-size: .78rem;
  --mdb-sidenav-collapse-sidenav-link-height: 1.5rem;
  --mdb-sidenav-link-font-size: .89rem;
  --mdb-sidenav-link-padding-y: 1rem;
  --mdb-sidenav-link-padding-x: 1.5rem;
  --mdb-sidenav-collapse-sidenav-link-padding-left: 3.4rem;
  --mdb-sidenav-link-height: 3rem;
  --mdb-sidenav-link-border-radius: 5px;
  --mdb-sidenav-link-transition: all .3s linear;
  --mdb-sidenav-link-hover-color: inherit;
  --mdb-sidenav-link-hover-background-color: var(--mdb-highlight-bg-color);
  --mdb-sidenav-link-active-focus-background-color: var(--mdb-highlight-bg-color);
  --mdb-sidenav-link-active-color: inherit;
  --mdb-sidenav-link-active-focus-color: inherit;
  --mdb-sidenav-subheading-font-size: .6rem;
  --mdb-sidenav-subheading-padding-y: 1rem;
  --mdb-sidenav-subheading-padding-x: 1.5rem;
  --mdb-sidenav-subheading-fw: 700;
  --mdb-sidenav-sm-link-pt: .4rem;
  --mdb-sidenav-sm-link-pb: .4rem;
  --mdb-sidenav-rotate-icon-margin-right: .8rem;
  --mdb-sidenav-rotate-icon-transition: transform .3s;
  --mdb-sidenav-light-color: #fff9;
  transform: translateX(-100%);
  transform: var(--mdb-sidenav-transform);
  z-index: 1035;
  z-index: var(--mdb-sidenav-zindex);
  color: var(--mdb-sidenav-color);
  background-color: #fff;
  background-color: var(--mdb-sidenav-background-color);
  width: var(--mdb-sidenav-width);
  height: var(--mdb-sidenav-height);
  box-shadow: 0 4px 12px #00000012, 0 2px 4px #0000000d;
  box-shadow: var(--mdb-sidenav-box-shadow);
  width: 240px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sidenav[data-mdb-color="light"] {
  color: var(--mdb-sidenav-data-color-light-color);
}

.sidenav [data-mdb-slim="true"] {
  display: none;
}

.sidenav-menu, .sidenav-collapse {
  padding: 0 var(--mdb-sidenav-menu-padding);
  margin: 0;
  list-style: none;
  position: relative;
}

.sidenav-collapse {
  padding: 0;
  display: none;
}

.sidenav-collapse.show, .sidenav-collapse.collapsing {
  display: block;
}

.sidenav-collapse .sidenav-link {
  font-size: var(--mdb-sidenav-collapse-sidenav-link-font-size);
  height: var(--mdb-sidenav-collapse-sidenav-link-height);
  padding-left: var(--mdb-sidenav-collapse-sidenav-link-padding-left);
}

.sidenav-link {
  cursor: pointer;
  font-size: var(--mdb-sidenav-link-font-size);
  padding: var(--mdb-sidenav-link-padding-y) var(--mdb-sidenav-link-padding-x);
  height: var(--mdb-sidenav-link-height);
  color: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--mdb-sidenav-link-border-radius);
  transition: var(--mdb-sidenav-link-transition);
  align-items: center;
  display: flex;
  overflow: hidden;
}

.sidenav-link:hover {
  color: inherit;
  background-color: var(--mdb-sidenav-link-hover-background-color);
  outline: none;
}

.sidenav-link:active, .sidenav-link:focus {
  color: inherit;
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
  outline: none;
}

.sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-link i {
  color: #9fa6b2;
}

.rotate-icon {
  margin-left: auto;
  margin-right: var(--mdb-sidenav-rotate-icon-margin-right);
  transition: var(--mdb-sidenav-rotate-icon-transition);
  position: absolute;
  right: 0;
}

.sidenav-backdrop {
  --mdb-sidenav-backdrop-zindex: 1034;
  --mdb-sidenav-backdrop-background-color: rgba(0, 0, 0, var(--mdb-sidenav-backdrop-opacity));
  z-index: 1034;
  z-index: var(--mdb-sidenav-backdrop-zindex);
  background-color: #0000001a;
  background-color: var(--mdb-sidenav-backdrop-background-color);
  top: 0;
  left: 0;
}

.sidenav-slim {
  --mdb-sidenav-slim-link-padding-left: 1rem;
}

.sidenav-slim .sidenav-link {
  padding-left: var(--mdb-sidenav-slim-link-padding-left);
}

.animation {
  --mdb-animation-delay-1s: 1s;
  --mdb-animation-delay-2s: 3s;
  --mdb-animation-delay-3s: 3s;
  --mdb-animation-delay-4s: 4s;
  --mdb-animation-delay-5s: 5s;
  --mdb-animation-fast-duration: .8s;
  --mdb-animation-faster-duration: .5s;
  --mdb-animation-slow-duration: 2s;
  --mdb-animation-slower-duration: 3s;
}

.animation.infinite {
  animation-iteration-count: infinite;
}

.animation.fast {
  animation-duration: var(--mdb-animation-fast-duration);
}

.animation.faster {
  animation-duration: var(--mdb-animation-faster-duration);
}

.animation.slow {
  animation-duration: var(--mdb-animation-slow-duration);
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-down-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-down-transform-to);
  }
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-left-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-left-transform-to);
  }
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-right-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-right-transform-to);
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-up-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-up-transform-to);
  }
}

@keyframes fade-out-down {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-down-transform-to);
  }
}

@keyframes fade-out-left {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-left-transform-to);
  }
}

@keyframes fade-out-right {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-right-transform-to);
  }
}

@keyframes fade-out-up {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-up-transform-to);
  }
}

@keyframes slide-in-down {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-down-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-down-transform-to);
  }
}

@keyframes slide-in-left {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-left-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-left-transform-to);
  }
}

.slide-in-left {
  --mdb-animation-slide-in-left-transform-from: translate3d(-100%, 0, 0);
  --mdb-animation-slide-in-left-transform-to: translate3d(0, 0, 0);
  animation-name: slide-in-left;
}

@keyframes slide-in-right {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-right-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-right-transform-to);
  }
}

.slide-in-right {
  --mdb-animation-slide-in-right-transform-from: translate3d(100%, 0, 0);
  --mdb-animation-slide-in-right-transform-to: translate3d(0, 0, 0);
  animation-name: slide-in-right;
}

@keyframes slide-in-up {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-up-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-up-transform-to);
  }
}

@keyframes slide-out-down {
  from {
    transform: var(--mdb-animation-slide-out-down-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-down-transform-to);
  }
}

@keyframes slide-out-left {
  from {
    transform: var(--mdb-animation-slide-out-left-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-left-transform-to);
  }
}

.slide-out-left {
  --mdb-animation-slide-out-left-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-out-left-transform-to: translate3d(-100%, 0, 0);
  animation-name: slide-out-left;
}

@keyframes slide-out-right {
  from {
    transform: var(--mdb-animation-slide-out-right-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-right-transform-to);
  }
}

.slide-out-right {
  --mdb-animation-slide-out-right-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-out-right-transform-to: translate3d(100%, 0, 0);
  animation-name: slide-out-right;
}

@keyframes slide-out-up {
  from {
    transform: var(--mdb-animation-slide-out-up-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-up-transform-to);
  }
}

@keyframes slide-down {
  from {
    transform: var(--mdb-animation-slide-down-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-down-transform-to);
  }
}

@keyframes slide-left {
  from {
    transform: var(--mdb-animation-slide-left-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-left-transform-to);
  }
}

@keyframes slide-right {
  from {
    transform: var(--mdb-animation-slide-right-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-right-transform-to);
  }
}

@keyframes slide-up {
  from {
    transform: var(--mdb-animation-slide-up-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-up-transform-to);
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: var(--mdb-animation-zoom-in-transform-from);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: var(--mdb-animation-zoom-out-transform-50);
  }

  to {
    opacity: 0;
  }
}

@keyframes tada {
  from {
    transform: var(--mdb-animation-tada-transform-from);
  }

  10%, 20% {
    transform: var(--mdb-animation-tada-transform-20);
  }

  30%, 50%, 70%, 90% {
    transform: var(--mdb-animation-tada-transform-90);
  }

  40%, 60%, 80% {
    transform: var(--mdb-animation-tada-transform-80);
  }

  to {
    transform: var(--mdb-animation-tada-transform-to);
  }
}

@keyframes pulse {
  from {
    transform: var(--mdb-animation-pulse-transform-from);
  }

  50% {
    transform: var(--mdb-animation-pulse-transform-50);
  }

  to {
    transform: var(--mdb-animation-pulse-transform-to);
  }
}

.lightbox img:not(.lightbox-disabled) {
  cursor: zoom-in;
}

.lightbox-gallery {
  --mdb-lightbox-zindex: 1100;
  --mdb-lightbox-toolbar-zindex: 1110;
  --mdb-lightbox-gallery-background-color: #000000e6;
  --mdb-lightbox-gallery-transition: all .3s ease-out;
  --mdb-lightbox-gallery-toolbar-height: 50px;
  --mdb-lightbox-gallery-toolbar-transition: opacity .4s;
  --mdb-lightbox-gallery-toolbar-button-width: 50px;
  --mdb-lightbox-gallery-toolbar-button-height: 50px;
  --mdb-lightbox-gallery-toolbar-button-color: #b3b3b3;
  --mdb-lightbox-gallery-toolbar-button-transition: color .2s;
  --mdb-lightbox-gallery-toolbar-button-hover-color: #fff;
  --mdb-lightbox-gallery-content-top: 50px;
  --mdb-lightbox-gallery-content-left: 50px;
  --mdb-lightbox-gallery-content-width: calc(100% - 100px);
  --mdb-lightbox-gallery-content-height: calc(100% - 100px);
  --mdb-lightbox-gallery-arrow-width: 50px;
  --mdb-lightbox-gallery-arrow-transition: opacity .4s;
  --mdb-lightbox-gallery-arrow-button-width: 50px;
  --mdb-lightbox-gallery-arrow-button-height: 50px;
  --mdb-lightbox-gallery-arrow-button-color: #b3b3b3;
  --mdb-lightbox-gallery-arrow-button-transition: color .2s;
  --mdb-lightbox-gallery-arrow-button-hover-color: #fff;
  --mdb-lightbox-gallery-button-focus-color: #fff;
  --mdb-lightbox-gallery-image-transform: scale(.25);
  --mdb-lightbox-gallery-image-transition: all .4s ease-out;
  --mdb-lightbox-gallery-counter-color: #b3b3b3;
  --mdb-lightbox-gallery-counter-padding-x: 10px;
  --mdb-lightbox-gallery-caption-color: #fff;
  --mdb-lightbox-gallery-caption-margin-x: 10px;
  --mdb-lightbox-gallery-caption-wrapper-height: 50px;
  --mdb-lightbox-gallery-loader-transition: opacity 1s;
  --mdb-lightbox-icons-font: var(--fa-font-solid);
  visibility: hidden;
  z-index: 1100;
  z-index: var(--mdb-lightbox-zindex);
  background-color: #000000e6;
  background-color: var(--mdb-lightbox-gallery-background-color);
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease-out;
  transition: var(--mdb-lightbox-gallery-transition);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-toolbar {
  height: var(--mdb-lightbox-gallery-toolbar-height);
  z-index: var(--mdb-lightbox-toolbar-zindex);
  transition: var(--mdb-lightbox-gallery-toolbar-transition);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-toolbar .lightbox-gallery-left-tools {
  float: left;
  height: 100%;
}

.lightbox-gallery .lightbox-gallery-toolbar .lightbox-gallery-right-tools {
  float: right;
}

.lightbox-gallery .lightbox-gallery-toolbar button {
  width: var(--mdb-lightbox-gallery-toolbar-button-width);
  height: var(--mdb-lightbox-gallery-toolbar-button-height);
  color: var(--mdb-lightbox-gallery-toolbar-button-color);
  transition: var(--mdb-lightbox-gallery-toolbar-button-transition);
  background: none;
  border: none;
}

.lightbox-gallery .lightbox-gallery-toolbar button:hover {
  color: var(--mdb-lightbox-gallery-toolbar-button-hover-color);
}

.lightbox-gallery .lightbox-gallery-toolbar button:before {
  font: var(--mdb-lightbox-icons-font);
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-fullscreen-btn:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-fullscreen-btn.active:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-zoom-btn:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-zoom-btn.active:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-close-btn:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-counter {
  color: var(--mdb-lightbox-gallery-counter-color);
  padding: 0 var(--mdb-lightbox-gallery-counter-padding-x);
  direction: ltr;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  display: flex;
}

.lightbox-gallery .lightbox-gallery-content {
  top: var(--mdb-lightbox-gallery-content-top);
  left: var(--mdb-lightbox-gallery-content-left);
  width: var(--mdb-lightbox-gallery-content-width);
  height: var(--mdb-lightbox-gallery-content-height);
  position: fixed;
}

.lightbox-gallery .lightbox-gallery-content .lightbox-gallery-image {
  opacity: 0;
  transform: var(--mdb-lightbox-gallery-image-transform);
  transition: var(--mdb-lightbox-gallery-image-transition);
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-content .lightbox-gallery-image img {
  cursor: pointer;
  pointer-events: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-content .lightbox-gallery-image img.vertical {
  max-width: initial;
  width: auto;
  height: 100%;
  max-height: 100%;
}

.lightbox-gallery .lightbox-gallery-arrow-right {
  width: var(--mdb-lightbox-gallery-arrow-width);
  transition: var(--mdb-lightbox-gallery-arrow-transition);
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.lightbox-gallery .lightbox-gallery-arrow-right button {
  width: var(--mdb-lightbox-gallery-arrow-button-width);
  height: var(--mdb-lightbox-gallery-arrow-button-height);
  color: var(--mdb-lightbox-gallery-arrow-button-color);
  transition: var(--mdb-lightbox-gallery-arrow-button-transition);
  background: none;
  border: none;
}

.lightbox-gallery .lightbox-gallery-arrow-right button:hover {
  color: var(--mdb-lightbox-gallery-arrow-button-hover-color);
}

.lightbox-gallery .lightbox-gallery-arrow-right button:before {
  font: var(--mdb-lightbox-icons-font);
  content: "";
}

.lightbox-gallery .lightbox-gallery-arrow-left {
  width: var(--mdb-lightbox-gallery-arrow-width);
  transition: var(--mdb-lightbox-gallery-arrow-transition);
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-arrow-left button {
  width: var(--mdb-lightbox-gallery-arrow-button-width);
  height: var(--mdb-lightbox-gallery-arrow-button-height);
  color: var(--mdb-lightbox-gallery-arrow-button-color);
  transition: var(--mdb-lightbox-gallery-arrow-button-transition);
  background: none;
  border: none;
}

.lightbox-gallery .lightbox-gallery-arrow-left button:hover {
  color: var(--mdb-lightbox-gallery-arrow-button-hover-color);
}

.lightbox-gallery .lightbox-gallery-arrow-left button:before {
  font: var(--mdb-lightbox-icons-font);
  content: "";
}

.lightbox-gallery .lightbox-gallery-caption-wrapper {
  height: var(--mdb-lightbox-gallery-caption-wrapper-height);
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-caption-wrapper .lightbox-gallery-caption {
  color: var(--mdb-lightbox-gallery-caption-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 var(--mdb-lightbox-gallery-caption-margin-x);
  text-align: center;
  overflow: hidden;
}

.lightbox-gallery .lightbox-gallery-loader {
  z-index: 2;
  opacity: 1;
  pointer-events: none;
  transition: var(--mdb-lightbox-gallery-loader-transition);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.lightbox-gallery button:focus {
  color: var(--mdb-lightbox-gallery-button-focus-color);
  outline: none;
}

.disabled-scroll {
  position: relative;
  overflow-y: hidden;
}

@media only screen and (device-width >= 768px) {
  .disabled-scroll.replace-scrollbar {
    --mdb-lightbox-disabled-scroll-media-padding-right: 17px;
    padding-right: 17px;
    padding-right: var(--mdb-lightbox-disabled-scroll-media-padding-right);
  }
}

.rating {
  --mdb-rating-icon-padding: .25rem;
  margin: 0;
  padding: 0;
  display: flex;
}

.rating li {
  list-style: none;
}

.rating i {
  padding: var(--mdb-rating-icon-padding);
}

.rating i.fa-sm {
  line-height: 1;
}

.rating:not([data-mdb-readonly="true"]) li {
  cursor: pointer;
}

.timepicker-wrapper {
  --mdb-timepicker-wrapper-bg: #0006;
  --mdb-timepicker-elements-min-width: 310px;
  --mdb-timepicker-elements-min-height: 325px;
  --mdb-timepicker-elements-background: var(--mdb-surface-bg);
  --mdb-timepicker-elements-border-top-right-radius: .6rem;
  --mdb-timepicker-elements-border-top-left-radius: .6rem;
  --mdb-timepicker-elements-media-border-bottom-left-radius: 0;
  --mdb-timepicker-head-bg: var(--mdb-picker-header-bg);
  --mdb-timepicker-head-height: 100px;
  --mdb-timepicker-head-border-top-right-radius: .5rem;
  --mdb-timepicker-head-border-top-left-radius: .5rem;
  --mdb-timepicker-head-padding-y: 10px;
  --mdb-timepicker-head-padding-right: 24px;
  --mdb-timepicker-head-padding-left: 50px;
  --mdb-timepicker-head-media-padding: 10px;
  --mdb-timepicker-head-media-padding-right: 10px;
  --mdb-timepicker-head-media-min-height: 305px;
  --mdb-timepicker-head-inline-border-bottom-right-radius: .5rem;
  --mdb-timepicker-head-inline-border-bottom-left-radius: .5rem;
  --mdb-timepicker-button-font-size: .8rem;
  --mdb-timepicker-button-min-width: 64px;
  --mdb-timepicker-button-font-weight: 500;
  --mdb-timepicker-button-line-height: 40px;
  --mdb-timepicker-button-border-radius: 10px;
  --mdb-timepicker-button-letter-spacing: .1rem;
  --mdb-timepicker-button-color: var(--mdb-surface-color);
  --mdb-timepicker-button-transition: background-color .25s cubic-bezier(.4, 0, .2, 1) 0s, box-shadow .25s cubic-bezier(.4, 0, .2, 1) 0s, border .25s cubic-bezier(.4, 0, .2, 1) 0s;
  --mdb-timepicker-button-padding-x: 10px;
  --mdb-timepicker-button-height: 40px;
  --mdb-timepicker-button-margin-bottom: 10px;
  --mdb-timepicker-button-hover-bg: rgba(var(--mdb-emphasis-color-rgb), .08);
  --mdb-timepicker-button-focus-bg: rgba(var(--mdb-emphasis-color-rgb), .08);
  --mdb-timepicker-submit-inline-height: 48px;
  --mdb-timepicker-submit-inline-min-width: 48px;
  --mdb-timepicker-submit-inline-margin-left: 30px;
  --mdb-timepicker-submit-inline-color: #fff;
  --mdb-timepicker-current-font-size: 3.75rem;
  --mdb-timepicker-current-font-weight: 300;
  --mdb-timepicker-current-line-height: 1.2;
  --mdb-timepicker-current-letter-spacing: -.00833em;
  --mdb-timepicker-current-color: #fff;
  --mdb-timepicker-current-opacity: .54;
  --mdb-timepicker-clock-wrapper-min-width: 310px;
  --mdb-timepicker-clock-wrapper-max-width: 325px;
  --mdb-timepicker-clock-wrapper-min-height: 305px;
  --mdb-timepicker-clock-wrapper-text-color: var(--mdb-surface-color);
  --mdb-timepicker-mode-wrapper-font-size: 18px;
  --mdb-timepicker-mode-wrapper-color: #ffffff8a;
  --mdb-timepicker-clock-inner-top: 50%;
  --mdb-timepicker-clock-inner-left: 50%;
  --mdb-timepicker-clock-inner-transform: translate(-50%, -50%);
  --mdb-timepicker-clock-inner-width: 160px;
  --mdb-timepicker-clock-inner-height: 160px;
  --mdb-timepicker-time-tips-inner-width: 32px;
  --mdb-timepicker-time-tips-inner-height: 32px;
  --mdb-timepicker-time-tips-inner-font-size: 1.1rem;
  --mdb-timepicker-time-tips-inner-font-weight: 300;
  --mdb-timepicker-clock-width: 260px;
  --mdb-timepicker-clock-height: 260px;
  --mdb-timepicker-clock-bg: var(--mdb-timepicker-clock-face-bg);
  --mdb-timepicker-time-tips-inner-active-color: #fff;
  --mdb-timepicker-time-tips-inner-active-bg: #3b71ca;
  --mdb-timepicker-time-tips-inner-active-font-weight: 400;
  --mdb-timepicker-time-tips-inner-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-timepicker-dot-font-weight: 300;
  --mdb-timepicker-dot-line-height: 1.2;
  --mdb-timepicker-dot-letter-spacing: -.00833em;
  --mdb-timepicker-dot-color: #fff;
  --mdb-timepicker-dot-media-font-size: 3rem;
  --mdb-timepicker-dot-media-font-weight: 400;
  --mdb-timepicker-dot-font-size: 3.75rem;
  --mdb-timepicker-dot-opacity: .54;
  --mdb-timepicker-current-inline-font-size: 2.5rem;
  --mdb-timepicker-item-width: 20px;
  --mdb-timepicker-item-height: 20px;
  --mdb-timepicker-item-padding: 10px;
  --mdb-timepicker-item-font-size: 1.1em;
  --mdb-timepicker-item-middle-dot-top: 50%;
  --mdb-timepicker-item-middle-dot-left: 50%;
  --mdb-timepicker-item-middle-dot-width: 6px;
  --mdb-timepicker-item-middle-dot-height: 6px;
  --mdb-timepicker-item-middle-dot-transform: translate(-50%, -50%);
  --mdb-timepicker-item-middle-dot-border-radius: 50%;
  --mdb-timepicker-item-middle-dot-bg: #3b71ca;
  --mdb-timepicker-hand-pointer-bg: #3b71ca;
  --mdb-timepicker-hand-pointer-bottom: 50%;
  --mdb-timepicker-hand-pointer-height: 40%;
  --mdb-timepicker-hand-pointer-left: calc(50% - 1px);
  --mdb-timepicker-hand-pointer-width: 2px;
  --mdb-timepicker-transform-transition: transform .4s cubic-bezier(.4, 0, .2, 1) 0s, height .4s cubic-bezier(.4, 0, .2, 1) 0s;
  --mdb-timepicker-time-tips-width: 32px;
  --mdb-timepicker-time-tips-height: 32px;
  --mdb-timepicker-time-tips-font-size: 1.1rem;
  --mdb-timepicker-time-tips-active-color: #fff;
  --mdb-timepicker-circle-top: -21px;
  --mdb-timepicker-circle-left: -15px;
  --mdb-timepicker-circle-width: 4px;
  --mdb-timepicker-circle-border-width: 14px;
  --mdb-timepicker-circle-border-color: #3b71ca;
  --mdb-timepicker-circle-height: 4px;
  --mdb-timepicker-circle-active-background-color: #fff;
  --mdb-timepicker-hour-mode-color: #fff;
  --mdb-timepicker-hour-mode-opacity: .54;
  --mdb-timepicker-hour-mode-hover-bg: rgba(var(--mdb-emphasis-color-rgb), .15);
  --mdb-timepicker-hour-mode-font-size: 2.5rem;
  --mdb-timepicker-hour-mode-active-color: #fff;
  --mdb-timepicker-footer-border-bottom-left-radius: .5rem;
  --mdb-timepicker-footer-border-bottom-right-radius: .5rem;
  --mdb-timepicker-footer-height: 56px;
  --mdb-timepicker-footer-padding-x: 12px;
  --mdb-timepicker-footer-bg: var(--mdb-surface-bg);
  --mdb-timepicker-container-max-height: calc(100% - 64px);
  --mdb-timepicker-container-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-timepicker-icon-up-down-transition: .2s ease all;
  --mdb-timepicker-icon-up-down-transform: translate(-50%, -50%);
  --mdb-timepicker-icon-up-down-left: 50%;
  --mdb-timepicker-icon-up-down-width: 30px;
  --mdb-timepicker-icon-up-down-height: 30px;
  --mdb-timepicker-icon-up-top: -35px;
  --mdb-timepicker-icon-down-bottom: -47px;
  --mdb-timepicker-icon-btn-width: 30px;
  --mdb-timepicker-icon-btn-height: 30px;
  --mdb-timepicker-input-color: #3b71ca;
  --mdb-timepicker-clock-animation: show-up-clock .35s linear;
  touch-action: none;
  z-index: var(--mdb-timepicker-zindex);
  opacity: 0;
  background-color: #0006;
  background-color: var(--mdb-timepicker-wrapper-bg);
  inset: 0;
}

.timepicker-wrapper-inline {
  --mdb-timepicker-wrapper-inline-border-radius: .5rem;
  border-radius: .5rem;
  border-radius: var(--mdb-timepicker-wrapper-inline-border-radius);
  overflow: hidden;
}

.timepicker-elements {
  min-width: var(--mdb-timepicker-elements-min-width);
  min-height: var(--mdb-timepicker-elements-min-height);
  background: var(--mdb-timepicker-elements-background);
  border-top-right-radius: var(--mdb-timepicker-elements-border-top-right-radius);
  border-top-left-radius: var(--mdb-timepicker-elements-border-top-left-radius);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-elements {
    border-bottom-left-radius: var(--mdb-timepicker-elements-media-border-bottom-left-radius);
    min-width: auto;
    min-height: auto;
    overflow-y: auto;
    flex-direction: row !important;
  }
}

.timepicker-elements-inline {
  min-height: auto;
}

.timepicker-head {
  background-color: var(--mdb-timepicker-head-bg);
  height: var(--mdb-timepicker-head-height);
  border-top-right-radius: var(--mdb-timepicker-head-border-top-right-radius);
  border-top-left-radius: var(--mdb-timepicker-head-border-top-left-radius);
  padding: var(--mdb-timepicker-head-padding-y) var(--mdb-timepicker-head-padding-right) var(--mdb-timepicker-head-padding-y) var(--mdb-timepicker-head-padding-left);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-head {
    padding: var(--mdb-timepicker-head-media-padding);
    min-height: var(--mdb-timepicker-head-media-min-height);
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    height: auto;
    padding-right: var(--mdb-timepicker-head-media-padding-right) !important;
  }
}

.timepicker-head-inline {
  border-bottom-right-radius: var(--mdb-timepicker-head-inline-border-bottom-right-radius);
  border-bottom-left-radius: var(--mdb-timepicker-head-inline-border-bottom-left-radius);
  padding: 0;
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-head-content {
    flex-direction: column;
  }
}

.timepicker-button {
  font-size: var(--mdb-timepicker-button-font-size);
  min-width: var(--mdb-timepicker-button-min-width);
  box-sizing: border-box;
  font-weight: var(--mdb-timepicker-button-font-weight);
  line-height: var(--mdb-timepicker-button-line-height);
  border-radius: var(--mdb-timepicker-button-border-radius);
  letter-spacing: var(--mdb-timepicker-button-letter-spacing);
  text-transform: uppercase;
  color: var(--mdb-timepicker-button-color);
  transition: var(--mdb-timepicker-button-transition);
  padding: 0 var(--mdb-timepicker-button-padding-x);
  height: var(--mdb-timepicker-button-height);
  margin-bottom: var(--mdb-timepicker-button-margin-bottom);
  background-color: #0000;
  border: none;
  outline: none;
}

.timepicker-button:hover {
  background-color: var(--mdb-timepicker-button-hover-bg);
}

.timepicker-button:focus {
  background-color: var(--mdb-timepicker-button-focus-bg);
  outline: none;
}

.timepicker-submit-inline {
  height: var(--mdb-timepicker-submit-inline-height);
  min-width: var(--mdb-timepicker-submit-inline-min-width);
  margin-left: var(--mdb-timepicker-submit-inline-margin-left);
  color: var(--mdb-timepicker-submit-inline-color);
  border-radius: 100%;
  display: inline-block;
}

.timepicker-current {
  font-size: var(--mdb-timepicker-current-font-size);
  font-weight: var(--mdb-timepicker-current-font-weight);
  line-height: var(--mdb-timepicker-current-line-height);
  letter-spacing: var(--mdb-timepicker-current-letter-spacing);
  color: var(--mdb-timepicker-current-color);
  opacity: var(--mdb-timepicker-current-opacity);
  vertical-align: unset;
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.timepicker-current.active {
  opacity: 1;
}

.timepicker-current-wrapper {
  direction: ltr;
}

.timepicker-mode-wrapper {
  font-size: var(--mdb-timepicker-mode-wrapper-font-size);
  color: var(--mdb-timepicker-mode-wrapper-color);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-mode-wrapper {
    flex-direction: row !important;
    justify-content: space-around !important;
  }
}

.timepicker-mode-wrapper.active {
  opacity: 1;
}

.timepicker-clock-wrapper {
  min-width: var(--mdb-timepicker-clock-wrapper-min-width);
  max-width: var(--mdb-timepicker-clock-wrapper-max-width);
  min-height: var(--mdb-timepicker-clock-wrapper-min-height);
  color: var(--mdb-timepicker-clock-wrapper-text-color);
  height: 100%;
  overflow-x: hidden;
}

.timepicker-clock {
  width: var(--mdb-timepicker-clock-width);
  height: var(--mdb-timepicker-clock-height);
  cursor: default;
  background-color: var(--mdb-timepicker-clock-bg);
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
}

.timepicker-clock-inner {
  top: var(--mdb-timepicker-clock-inner-top);
  left: var(--mdb-timepicker-clock-inner-left);
  transform: var(--mdb-timepicker-clock-inner-transform);
  width: var(--mdb-timepicker-clock-inner-width);
  height: var(--mdb-timepicker-clock-inner-height);
  border-radius: 100%;
  position: absolute;
}

.timepicker-time-tips-inner {
  width: var(--mdb-timepicker-time-tips-inner-width);
  height: var(--mdb-timepicker-time-tips-inner-height);
  text-align: center;
  cursor: pointer;
  font-size: var(--mdb-timepicker-time-tips-inner-font-size);
  font-weight: var(--mdb-timepicker-time-tips-inner-font-weight);
  background-color: #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.timepicker-time-tips-inner.active {
  color: var(--mdb-timepicker-time-tips-inner-active-color);
  background-color: var(--mdb-timepicker-time-tips-inner-active-bg);
  font-weight: var(--mdb-timepicker-time-tips-inner-active-font-weight);
}

.timepicker-time-tips-inner:focus {
  outline: none;
}

.timepicker-time-tips-inner.disabled {
  color: var(--mdb-timepicker-time-tips-inner-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.timepicker-time-tips-inner::-moz-selection {
  background-color: #0000;
}

.timepicker-time-tips-inner::selection {
  background-color: #0000;
}

.timepicker-current, .timepicker-dot {
  font-weight: var(--mdb-timepicker-dot-font-weight);
  line-height: var(--mdb-timepicker-dot-line-height);
  letter-spacing: var(--mdb-timepicker-dot-letter-spacing);
  color: var(--mdb-timepicker-dot-color);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-current, .timepicker-dot {
    font-size: var(--mdb-timepicker-dot-media-font-size);
    font-weight: var(--mdb-timepicker-dot-media-font-weight);
  }
}

.timepicker-dot {
  font-size: var(--mdb-timepicker-dot-font-size);
  opacity: var(--mdb-timepicker-dot-opacity);
  background: none;
  border: none;
  padding: 0;
}

.timepicker-current-inline {
  font-size: var(--mdb-timepicker-current-inline-font-size);
}

.timepicker-middle-dot {
  top: var(--mdb-timepicker-item-middle-dot-top);
  left: var(--mdb-timepicker-item-middle-dot-left);
  width: var(--mdb-timepicker-item-middle-dot-width);
  height: var(--mdb-timepicker-item-middle-dot-height);
  transform: var(--mdb-timepicker-item-middle-dot-transform);
  border-radius: var(--mdb-timepicker-item-middle-dot-border-radius);
  background-color: var(--mdb-timepicker-item-middle-dot-bg);
}

.timepicker-hand-pointer {
  background-color: var(--mdb-timepicker-hand-pointer-bg);
  bottom: var(--mdb-timepicker-hand-pointer-bottom);
  height: var(--mdb-timepicker-hand-pointer-height);
  left: var(--mdb-timepicker-hand-pointer-left);
  transform-origin: center bottom 0;
  width: var(--mdb-timepicker-hand-pointer-width);
}

.timepicker-circle {
  top: var(--mdb-timepicker-circle-top);
  left: var(--mdb-timepicker-circle-left);
  width: var(--mdb-timepicker-circle-width);
  border: var(--mdb-timepicker-circle-border-width) solid var(--mdb-timepicker-circle-border-color);
  height: var(--mdb-timepicker-circle-height);
  box-sizing: content-box;
  background-color: #0000;
  border-radius: 100%;
}

.timepicker-circle.active {
  background-color: var(--mdb-timepicker-circle-active-background-color);
}

.timepicker-hour-mode {
  color: var(--mdb-timepicker-hour-mode-color);
  opacity: var(--mdb-timepicker-hour-mode-opacity);
  background-color: #0000;
  border: none;
  padding: 0;
}

.timepicker-hour-mode, .timepicker-hour, .timepicker-minute {
  cursor: pointer;
}

.timepicker-hour-mode:hover, .timepicker-hour-mode:focus, .timepicker-hour:hover, .timepicker-hour:focus, .timepicker-minute:hover, .timepicker-minute:focus {
  background-color: var(--mdb-timepicker-hour-mode-hover-bg);
  outline: none;
}

.timepicker-hour-mode.active, .timepicker-hour.active, .timepicker-minute.active {
  color: #fff;
  opacity: 1;
}

.timepicker-footer {
  border-bottom-left-radius: var(--mdb-timepicker-footer-border-bottom-left-radius);
  border-bottom-right-radius: var(--mdb-timepicker-footer-border-bottom-right-radius);
  height: var(--mdb-timepicker-footer-height);
  padding-left: var(--mdb-timepicker-footer-padding-x);
  padding-right: var(--mdb-timepicker-footer-padding-x);
  background-color: var(--mdb-timepicker-footer-bg);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.timepicker-container {
  max-height: var(--mdb-timepicker-container-max-height);
  box-shadow: var(--mdb-timepicker-container-box-shadow);
  overflow-y: auto;
}

.timepicker-icon-up, .timepicker-icon-down {
  opacity: 0;
  transition: var(--mdb-timepicker-icon-up-down-transition);
  cursor: pointer;
  transform: var(--mdb-timepicker-icon-up-down-transform);
  left: var(--mdb-timepicker-icon-up-down-left);
  width: var(--mdb-timepicker-icon-up-down-width);
  height: var(--mdb-timepicker-icon-up-down-height);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timepicker-icon-up.active, .timepicker-icon-up:hover, .timepicker-icon-down.active, .timepicker-icon-down:hover {
  opacity: 1;
}

.timepicker-icon-up {
  top: var(--mdb-timepicker-icon-up-top);
}

.timepicker-icon-down {
  bottom: var(--mdb-timepicker-icon-down-bottom);
}

.timepicker-inline-hour-icons button, .timepicker-inline-minutes-icons button {
  cursor: default;
  opacity: 1 !important;
}

.timepicker-inline-hour-icons button:hover, .timepicker-inline-minutes-icons button:hover {
  background-color: unset !important;
}

.timepicker-toggle-button {
  --mdb-timepicker-btn-icon-right: -10px;
  --mdb-timepicker-btn-icon-top: 50%;
  --mdb-timepicker-btn-icon-transform: translate(-50%, -50%);
  --mdb-timepicker-btn-icon-transition: all .3s ease;
  --mdb-timepicker-btn-icon-hover-color: #3b71ca;
  --mdb-timepicker-btn-icon-focus-color: #3b71ca;
  right: -10px;
  right: var(--mdb-timepicker-btn-icon-right);
  top: 50%;
  top: var(--mdb-timepicker-btn-icon-top);
  transform: translate(-50%, -50%);
  transform: var(--mdb-timepicker-btn-icon-transform);
  transition: all .3s;
  transition: var(--mdb-timepicker-btn-icon-transition);
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  position: absolute;
}

.timepicker-toggle-button:hover {
  color: var(--mdb-timepicker-btn-icon-hover-color);
}

.timepicker-toggle-button:focus {
  color: var(--mdb-timepicker-btn-icon-focus-color);
}

.timepicker a.timepicker-toggle-button, .timepicker-toggle-button.timepicker-icon {
  right: 1px;
}

@keyframes show-up-clock {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.navbar {
  --mdb-navbar-scroll-transition: background .5s ease-in-out, padding .5s ease-in-out;
  --mdb-navbar-scroll-scrolled-padding-y: 5px;
  --mdb-navbar-scroll-scrolled-color: #4f4f4f;
  --mdb-navbar-scroll-color: #fff;
  --mdb-navbar-scrolled-bg: #fff;
}

.navbar-scrolled .nav-link {
  color: var(--mdb-navbar-scroll-scrolled-color);
}

.navbar-scrolled {
  background-color: var(--mdb-navbar-scrolled-bg);
}

:root {
  --mdb-datepicker-backdrop-background-color: #0006;
  --mdb-datepicker-zindex: 1065;
  --mdb-datepicker-container-zindex: 1066;
  --mdb-datepicker-toggle-right: -10px;
  --mdb-datepicker-toggle-top: 50%;
  --mdb-datepicker-toggle-focus-color: #3b71ca;
}

.datepicker-toggle-button {
  right: -10px;
  right: var(--mdb-datepicker-toggle-right);
  top: 50%;
  top: var(--mdb-datepicker-toggle-top);
  background-color: #0000;
  border: none;
  outline: none;
  position: absolute;
  transform: translate(-50%, -50%);
}

.datepicker-toggle-button:focus, .datepicker-toggle-button:hover {
  color: var(--mdb-datepicker-toggle-focus-color);
}

.datepicker-backdrop {
  background-color: #0006;
  background-color: var(--mdb-datepicker-backdrop-background-color);
  z-index: 1065;
  z-index: var(--mdb-datepicker-zindex);
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.datepicker-dropdown-container {
  --mdb-datepicker-dropdown-container-width: 328px;
  --mdb-datepicker-dropdown-container-height: 380px;
  --mdb-datepicker-dropdown-container-background-color: var(--mdb-surface-bg);
  --mdb-datepicker-dropdown-container-border-radius: .5rem;
  --mdb-datepicker-dropdown-container-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  width: var(--mdb-datepicker-dropdown-container-width);
  height: var(--mdb-datepicker-dropdown-container-height);
  background-color: #fff;
  background-color: var(--mdb-datepicker-dropdown-container-background-color);
  border-radius: .5rem;
  border-radius: var(--mdb-datepicker-dropdown-container-border-radius);
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-datepicker-dropdown-container-box-shadow);
  z-index: 1066;
  z-index: var(--mdb-datepicker-container-zindex);
  width: 328px;
  height: 380px;
}

.datepicker-modal-container {
  --mdb-datepicker-modal-container-transform: translate(-50%, -50%);
  --mdb-datepicker-modal-container-width: 328px;
  --mdb-datepicker-modal-container-height: 512px;
  --mdb-datepicker-modal-container-background-color: var(--mdb-surface-bg);
  --mdb-datepicker-modal-container-border-radius: .6rem .6rem .5rem .5rem;
  --mdb-datepicker-modal-container-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-datepicker-modal-container-date-media-margin-top: 100px;
  --mdb-datepicker-modal-container-day-cell-media-width: 32px;
  --mdb-datepicker-modal-container-day-cell-media-height: 32px;
  --mdb-datepicker-modal-container-media-width: 475px;
  --mdb-datepicker-modal-container-media-height: 360px;
  --mdb-datepicker-header-border-radius-landscape: .5rem 0 0 .5rem;
  --mdb-datepicker-header-height: 120px;
  --mdb-datepicker-header-padding-x: 24px;
  --mdb-datepicker-header-background-color: var(--mdb-picker-header-bg);
  --mdb-datepicker-header-border-radius: .5rem .5rem 0 0;
  --mdb-datepicker-title-height: 32px;
  --mdb-datepicker-title-text-font-size: 10px;
  --mdb-datepicker-title-text-font-weight: 400;
  --mdb-datepicker-title-text-letter-spacing: 1.7px;
  --mdb-datepicker-title-text-color: #fff;
  --mdb-datepicker-date-height: 72px;
  --mdb-datepicker-date-text-font-size: 34px;
  --mdb-datepicker-date-text-font-weight: 400;
  --mdb-datepicker-date-text-color: #fff;
  --mdb-datepicker-footer-height: 56px;
  --mdb-datepicker-footer-padding-x: 12px;
  --mdb-datepicker-footer-btn-background-color: var(--mdb-surface-bg);
  --mdb-datepicker-footer-btn-color: var(--mdb-surface-color);
  --mdb-datepicker-footer-btn-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-footer-btn-padding-x: 10px;
  --mdb-datepicker-footer-btn-font-size: .8rem;
  --mdb-datepicker-footer-btn-font-weight: 500;
  --mdb-datepicker-footer-btn-height: 40px;
  --mdb-datepicker-footer-btn-line-height: 40px;
  --mdb-datepicker-footer-btn-letter-spacing: .1rem;
  --mdb-datepicker-footer-btn-border-radius: 10px;
  --mdb-datepicker-footer-btn-margin-bottom: 10px;
  --mdb-datepicker-footer-btn-state-background-color: var(--mdb-highlight-bg-color);
  transform: translate(-50%, -50%);
  transform: var(--mdb-datepicker-modal-container-transform);
  width: var(--mdb-datepicker-modal-container-width);
  height: var(--mdb-datepicker-modal-container-height);
  background-color: #fff;
  background-color: var(--mdb-datepicker-modal-container-background-color);
  border-radius: .6rem .6rem .5rem .5rem;
  border-radius: var(--mdb-datepicker-modal-container-border-radius);
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-datepicker-modal-container-box-shadow);
  z-index: 1066;
  z-index: var(--mdb-datepicker-container-zindex);
  flex-direction: column;
  width: 328px;
  height: 512px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
}

@media screen and (width >= 320px) and (width <= 820px) and (orientation: landscape) {
  .datepicker-modal-container .datepicker-header {
    height: 100%;
  }

  .datepicker-modal-container .datepicker-date {
    margin-top: var(--mdb-datepicker-modal-container-date-media-margin-top);
  }

  .datepicker-modal-container {
    width: var(--mdb-datepicker-modal-container-media-width);
    height: var(--mdb-datepicker-modal-container-media-height);
    flex-direction: row;
  }

  .datepicker-modal-container .datepicker-day-cell {
    width: var(--mdb-datepicker-modal-container-day-cell-media-width);
    height: var(--mdb-datepicker-modal-container-day-cell-media-height);
  }
}

.datepicker-header {
  height: var(--mdb-datepicker-header-height);
  padding-right: var(--mdb-datepicker-header-padding-x);
  padding-left: var(--mdb-datepicker-header-padding-x);
  background-color: var(--mdb-datepicker-header-background-color);
  border-radius: var(--mdb-datepicker-header-border-radius);
  flex-direction: column;
  display: flex;
}

@media screen and (width >= 320px) and (width <= 820px) and (orientation: landscape) {
  .datepicker-header {
    border-radius: var(--mdb-datepicker-header-border-radius-landscape);
  }
}

.datepicker-title {
  height: var(--mdb-datepicker-title-height);
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.datepicker-title-text {
  font-size: var(--mdb-datepicker-title-text-font-size);
  font-weight: var(--mdb-datepicker-title-text-font-weight);
  text-transform: uppercase;
  letter-spacing: var(--mdb-datepicker-title-text-letter-spacing);
  color: var(--mdb-datepicker-title-text-color);
}

.datepicker-date {
  height: var(--mdb-datepicker-date-height);
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.datepicker-date-text {
  font-size: var(--mdb-datepicker-date-text-font-size);
  font-weight: var(--mdb-datepicker-date-text-font-weight);
  color: var(--mdb-datepicker-date-text-color);
}

.datepicker-main {
  --mdb-datepicker-date-controls-padding-top: 10px;
  --mdb-datepicker-date-controls-padding-x: 12px;
  --mdb-datepicker-date-controls-color: #000000a3;
  --mdb-datepicker-view-change-button-padding: 10px;
  --mdb-datepicker-view-change-button-color: var(--mdb-surface-color);
  --mdb-datepicker-view-change-button-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-view-change-button-font-weight: 500;
  --mdb-datepicker-view-change-button-font-size: .9rem;
  --mdb-datepicker-view-change-button-border-radius: 10px;
  --mdb-datepicker-view-change-button-state-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-view-change-button-after-border-width: 5px;
  --mdb-datepicker-view-change-button-after-margin-left: 5px;
  --mdb-datepicker-arrow-controls-margin-top: 10px;
  --mdb-datepicker-previous-button-width: 40px;
  --mdb-datepicker-previous-button-height: 40px;
  --mdb-datepicker-previous-button-line-height: 40px;
  --mdb-datepicker-previous-button-color: var(--mdb-surface-color);
  --mdb-datepicker-previous-button-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-previous-button-margin-right: 24px;
  --mdb-datepicker-previous-button-state-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-previous-button-state-border-radius: 50%;
  --mdb-datepicker-previous-button-after-margin: 15.5px;
  --mdb-datepicker-previous-button-after-border-width: 2px;
  --mdb-datepicker-previous-button-after-transform: translateX(2px) rotate(-45deg);
  --mdb-datepicker-next-button-width: 40px;
  --mdb-datepicker-next-button-height: 40px;
  --mdb-datepicker-next-button-line-height: 40px;
  --mdb-datepicker-next-button-color: var(--mdb-surface-color);
  --mdb-datepicker-next-button-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-next-button-margin-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-next-button-state-border-radius: 50%;
  --mdb-datepicker-next-button-after-margin: 15.5px;
  --mdb-datepicker-next-button-after-border-width: 2px;
  --mdb-datepicker-next-button-after-transform: translateX(-2px) rotate(45deg);
  --mdb-datepicker-view-padding-x: 12px;
  --mdb-datepicker-table-width: 304px;
  --mdb-datepicker-day-heading-width: 40px;
  --mdb-datepicker-day-heading-height: 40px;
  --mdb-datepicker-day-heading-font-size: 12px;
  --mdb-datepicker-day-heading-font-weight: 400;
  --mdb-datepicker-day-heading-color: var(--mdb-surface-color);
  --mdb-datepicker-cell-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-cell-hover-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-cell-selected-background-color: #3b71ca;
  --mdb-datepicker-cell-selected-color: #fff;
  --mdb-datepicker-cell-focused-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-cell-focused-selected-background-color: #3b71ca;
  --mdb-datepicker-cell-border-width: 1px;
  --mdb-datepicker-cell-border-color: var(--mdb-surface-color);
  --mdb-datepicker-cell-color: var(--mdb-surface-color);
  --mdb-datepicker-small-cell-width: 40px;
  --mdb-datepicker-small-cell-height: 40px;
  --mdb-datepicker-small-cell-content-width: 40px;
  --mdb-datepicker-small-cell-content-height: 40px;
  --mdb-datepicker-small-cell-content-line-height: 40px;
  --mdb-datepicker-small-cell-content-border-radius: 50%;
  --mdb-datepicker-small-cell-content-font-size: 13px;
  --mdb-datepicker-large-cell-width: 76px;
  --mdb-datepicker-large-cell-height: 42px;
  --mdb-datepicker-large-cell-content-width: 72px;
  --mdb-datepicker-large-cell-content-height: 40px;
  --mdb-datepicker-large-cell-content-line-height: 40px;
  --mdb-datepicker-large-cell-content-padding-y: 1px;
  --mdb-datepicker-large-cell-content-padding-x: 2px;
  --mdb-datepicker-large-cell-content-border-radius: 999px;
  height: 100%;
  position: relative;
}

.datepicker-date-controls {
  padding: var(--mdb-datepicker-date-controls-padding-top) var(--mdb-datepicker-date-controls-padding-x) 0 var(--mdb-datepicker-date-controls-padding-x);
  color: var(--mdb-datepicker-date-controls-color);
  justify-content: space-between;
  display: flex;
}

.datepicker-view-change-button {
  padding: var(--mdb-datepicker-view-change-button-padding);
  color: var(--mdb-datepicker-view-change-button-color);
  font-weight: var(--mdb-datepicker-view-change-button-font-weight);
  font-size: var(--mdb-datepicker-view-change-button-font-size);
  border-radius: var(--mdb-datepicker-view-change-button-border-radius);
  box-shadow: none;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
}

.datepicker-view-change-button:hover, .datepicker-view-change-button:focus {
  background-color: var(--mdb-datepicker-view-change-button-state-background-color);
}

.datepicker-view-change-button:after {
  content: "";
  border-left: var(--mdb-datepicker-view-change-button-after-border-width) solid #0000;
  border-right: var(--mdb-datepicker-view-change-button-after-border-width) solid #0000;
  border-top-width: var(--mdb-datepicker-view-change-button-after-border-width);
  margin: 0 0 0 var(--mdb-datepicker-view-change-button-after-margin-left);
  vertical-align: middle;
  border-top-style: solid;
  width: 0;
  height: 0;
  display: inline-block;
}

.datepicker-view-change-button.disabled {
  color: var(--mdb-datepicker-view-change-button-disabled-color);
}

.datepicker-arrow-controls {
  margin-top: var(--mdb-datepicker-arrow-controls-margin-top);
}

.datepicker-previous-button {
  width: var(--mdb-datepicker-previous-button-width);
  height: var(--mdb-datepicker-previous-button-height);
  line-height: var(--mdb-datepicker-previous-button-line-height);
  color: var(--mdb-datepicker-previous-button-color);
  margin: 0;
  margin-right: var(--mdb-datepicker-previous-button-margin-right);
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
}

.datepicker-previous-button:hover, .datepicker-previous-button:focus {
  background-color: var(--mdb-datepicker-previous-button-state-background-color);
  border-radius: var(--mdb-datepicker-previous-button-state-border-radius);
}

.datepicker-previous-button.disabled {
  color: var(--mdb-datepicker-previous-button-disabled-color);
}

.datepicker-previous-button:after {
  content: "";
  margin: var(--mdb-datepicker-previous-button-after-margin);
  border: 0 solid;
  border-top-width: var(--mdb-datepicker-previous-button-after-border-width);
  border-left-width: var(--mdb-datepicker-previous-button-after-border-width);
  transform: var(--mdb-datepicker-previous-button-after-transform);
  position: absolute;
  inset: 0;
}

.datepicker-next-button {
  width: var(--mdb-datepicker-next-button-width);
  height: var(--mdb-datepicker-next-button-height);
  line-height: var(--mdb-datepicker-next-button-line-height);
  color: var(--mdb-datepicker-next-button-color);
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.datepicker-next-button:hover, .datepicker-next-button:focus {
  background-color: var(--mdb-datepicker-next-button-margin-background-color);
  border-radius: var(--mdb-datepicker-next-button-state-border-radius);
}

.datepicker-next-button.disabled {
  color: var(--mdb-datepicker-next-button-disabled-color);
}

.datepicker-next-button:after {
  content: "";
  margin: var(--mdb-datepicker-next-button-after-margin);
  border: 0 solid;
  border-top-width: var(--mdb-datepicker-next-button-after-border-width);
  border-right-width: var(--mdb-datepicker-next-button-after-border-width);
  transform: var(--mdb-datepicker-next-button-after-transform);
  position: absolute;
  inset: 0;
}

.datepicker-view {
  padding-left: var(--mdb-datepicker-view-padding-x);
  padding-right: var(--mdb-datepicker-view-padding-x);
  outline: none;
}

.datepicker-table {
  width: var(--mdb-datepicker-table-width);
  margin-left: auto;
  margin-right: auto;
}

.datepicker-day-heading {
  width: var(--mdb-datepicker-day-heading-width);
  height: var(--mdb-datepicker-day-heading-height);
  text-align: center;
  font-size: var(--mdb-datepicker-day-heading-font-size);
  font-weight: var(--mdb-datepicker-day-heading-font-weight);
  color: var(--prefixdatepicker-day-heading-color);
}

.datepicker-cell {
  text-align: center;
  color: var(--mdb-datepicker-cell-color);
}

.datepicker-cell.disabled {
  color: var(--mdb-datepicker-cell-disabled-color);
  cursor: default;
  pointer-events: none;
}

.datepicker-cell.disabled:hover {
  cursor: default;
}

.datepicker-cell:hover {
  cursor: pointer;
}

.datepicker-cell:not(.disabled):not(.selected):hover .datepicker-cell-content {
  background-color: var(--mdb-datepicker-cell-hover-background-color);
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: var(--mdb-datepicker-cell-selected-background-color);
  color: var(--mdb-datepicker-cell-selected-color);
}

.datepicker-cell:not(.selected).focused .datepicker-cell-content {
  background-color: var(--mdb-datepicker-cell-focused-background-color);
}

.datepicker-cell.focused .datepicker-cell-content.selected {
  background-color: var(--mdb-datepicker-cell-focused-selected-background-color);
}

.datepicker-cell.current .datepicker-cell-content {
  border: var(--mdb-datepicker-cell-border-width) solid var(--mdb-datepicker-cell-border-color);
}

.datepicker-small-cell {
  width: var(--mdb-datepicker-small-cell-width);
  height: var(--mdb-datepicker-small-cell-height);
}

.datepicker-small-cell-content {
  width: var(--mdb-datepicker-small-cell-content-width);
  height: var(--mdb-datepicker-small-cell-content-height);
  line-height: var(--mdb-datepicker-small-cell-content-line-height);
  border-radius: var(--mdb-datepicker-small-cell-content-border-radius);
  font-size: var(--mdb-datepicker-small-cell-content-font-size);
}

.datepicker-large-cell {
  width: var(--mdb-datepicker-large-cell-width);
  height: var(--mdb-datepicker-large-cell-height);
}

.datepicker-large-cell-content {
  width: var(--mdb-datepicker-large-cell-content-width);
  height: var(--mdb-datepicker-large-cell-content-height);
  line-height: var(--mdb-datepicker-large-cell-content-line-height);
  padding: var(--mdb-datepicker-large-cell-content-padding-y) var(--mdb-datepicker-large-cell-content-padding-x);
  border-radius: var(--mdb-datepicker-large-cell-content-border-radius);
}

.datepicker-footer {
  height: var(--mdb-datepicker-footer-height);
  padding-left: var(--mdb-datepicker-footer-padding-x);
  padding-right: var(--mdb-datepicker-footer-padding-x);
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

.datepicker-footer-btn {
  background-color: var(--mdb-datepicker-footer-btn-background-color);
  color: var(--mdb-datepicker-footer-btn-color);
  cursor: pointer;
  padding: 0 var(--mdb-datepicker-footer-btn-padding-x);
  text-transform: uppercase;
  font-size: var(--mdb-datepicker-footer-btn-font-size);
  font-weight: var(--mdb-datepicker-footer-btn-font-weight);
  height: var(--mdb-datepicker-footer-btn-height);
  line-height: var(--mdb-datepicker-footer-btn-line-height);
  letter-spacing: var(--mdb-datepicker-footer-btn-letter-spacing);
  border-radius: var(--mdb-datepicker-footer-btn-border-radius);
  margin-bottom: var(--mdb-datepicker-footer-btn-margin-bottom);
  border: none;
  outline: none;
}

.datepicker-footer-btn:hover, .datepicker-footer-btn:focus {
  background-color: var(--mdb-datepicker-footer-btn-state-background-color);
}

.datepicker-footer-btn.disabled {
  color: var(--mdb-datepicker-footer-btn-disabled-color);
}

.datepicker-clear-btn {
  margin-right: auto;
}

input[disabled] ~ .datepicker-toggle-button, input[readonly] ~ .datepicker-toggle-button {
  pointer-events: none;
}

:root {
  --mdb-popconfirm-zindex: 1080;
  --mdb-popconfirm-border-radius: .5rem;
}

.popconfirm {
  --mdb-popconfirm-padding: 1rem;
  --mdb-popconfirm-background-color: var(--mdb-surface-bg);
  --mdb-popconfirm-text-color: var(--mdb-surface-color);
  padding: 1rem;
  padding: var(--mdb-popconfirm-padding);
  background-color: #fff;
  background-color: var(--mdb-popconfirm-background-color);
  border-radius: .5rem;
  border-radius: var(--mdb-popconfirm-border-radius);
  opacity: 0;
  color: var(--mdb-popconfirm-text-color);
}

.popconfirm.show {
  opacity: 1;
}

.popconfirm-popover {
  --mdb-popconfirm-popover-width: 300px;
  --mdb-popconfirm-border: 1px solid var(--mdb-divider-color);
  z-index: 1080;
  z-index: var(--mdb-popconfirm-zindex);
  width: var(--mdb-popconfirm-popover-width);
  border-radius: .5rem;
  border-radius: var(--mdb-popconfirm-border-radius);
  border: 1px solid #f5f5f5;
  border: var(--mdb-popconfirm-border);
  width: 300px;
}

.popconfirm-modal {
  --mdb-popconfirm-modal-width: 300px;
  z-index: 1080;
  z-index: var(--mdb-popconfirm-zindex);
  width: var(--mdb-popconfirm-modal-width);
  border-radius: .5rem;
  border-radius: var(--mdb-popconfirm-border-radius);
  width: 300px;
  position: absolute;
}

.popconfirm-buttons-container {
  justify-content: flex-end;
  display: flex;
}

.popconfirm-buttons-container .btn {
  --mdb-popconfirm-buttons-container-btn-ml: .5rem;
  margin-left: .5rem;
  margin-left: var(--mdb-popconfirm-buttons-container-btn-ml);
}

.popconfirm-backdrop {
  --mdb-popconfirm-backdrop-zindex: 1070;
  --mdb-popconfirm-backdrop-background-color: #0006;
  z-index: 1070;
  z-index: var(--mdb-popconfirm-backdrop-zindex);
  background-color: #0006;
  background-color: var(--mdb-popconfirm-backdrop-background-color);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.popconfirm-icon-container i {
  --mdb-popconfirm-icon-text-color: var(--mdb-surface-color);
  color: var(--mdb-popconfirm-icon-text-color);
}

.datatable {
  --mdb-datatable-color: var(--mdb-body-color);
  --mdb-datatable-border-color: var(--mdb-border-color);
  --mdb-datatable-striped-color: var(--mdb-body-color);
  --mdb-datatable-accent-bg: rgba(var(--mdb-emphasis-color-rgb), .02);
  --mdb-datatable-hover-color: var(--mdb-body-color);
  --mdb-datatable-hover-bg: rgba(var(--mdb-emphasis-color-rgb), .025);
  --mdb-datatable-muted-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-datatable-active-color: rgba(var(--mdb-emphasis-color-rgb), .1);
  --mdb-datatable-font-size: .9rem;
  --mdb-datatable-background-color: var(--mdb-body-bg);
  --mdb-datatable-table-th-td-max-width: 250px;
  --mdb-datatable-table-th-td-padding-y: 1rem;
  --mdb-datatable-table-th-td-padding-x: 1.4rem;
  --mdb-datatable-thead-tr-border-width: 1px;
  --mdb-datatable-thead-th-font-weight: 500;
  --mdb-datatable-thead-fixed-cell-background-color: var(--mdb-body-bg);
  --mdb-datatable-tbody-font-weight: 300;
  --mdb-datatable-tbody-tr-transition: all .3s ease-in;
  --mdb-datatable-tbody-tr-last-child-height: 71px;
  --mdb-datatable-tbody-loader-height: 2px;
  --mdb-datatable-tbody-progress-animation: datatableProgress 3s ease-in-out;
  --mdb-datatable-tbody-progress-width: 45%;
  --mdb-datatable-tbody-progress-opacity: .5;
  --mdb-datatable-tbody-progress-border-radius: 1px;
  --mdb-datatable-pagination-padding-y: .5rem;
  --mdb-datatable-pagination-border-width: 1px;
  --mdb-datatable-pagination-nav-font-size: .9rem;
  --mdb-datatable-pagination-buttons-margin-left: 2rem;
  --mdb-datatable-pagination-button-padding-x: 1rem;
  --mdb-datatable-sort-icon-transition-duration: .3s;
  --mdb-datatable-sort-icon-left: .4rem;
  --mdb-datatable-sort-icon-top: calc(50% - .5rem);
  --mdb-datatable-select-wrapper-font-size: .9rem;
  --mdb-datatable-select-wrapper-font-weight: 300;
  --mdb-datatable-sm-th-td-padding-y: .5rem;
  --mdb-datatable-sm-th-td-padding-x: 1.4rem;
  --mdb-datatable-sm-tbody-tr-last-child-height: 55px;
  --mdb-datatable-sm-pagination-padding: .2rem;
  --mdb-datatable-bordered-th-td-border-width: 1px;
  --mdb-datatable-hover-tbody-tr-transition: background-color .2s ease-in;
  font-size: .9rem;
  font-size: var(--mdb-datatable-font-size);
  color: var(--mdb-datatable-color);
  background-color: #fff;
  background-color: var(--mdb-datatable-background-color);
}

.datatable-inner {
  background-color: inherit;
}

.datatable table {
  font-size: var(--mdb-datatable-font-size);
  color: var(--mdb-datatable-color);
  background-color: inherit;
  border-color: var(--mdb-datatable-border-color);
  margin-bottom: 0;
}

.datatable table th, .datatable table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: var(--mdb-datatable-table-th-td-max-width);
  padding: var(--mdb-datatable-table-th-td-padding-y) var(--mdb-datatable-table-th-td-padding-x);
  overflow: hidden;
}

.datatable thead {
  background-color: inherit;
}

.datatable thead tr {
  background-color: inherit;
  border-bottom: var(--mdb-datatable-thead-tr-border-width) solid var(--mdb-datatable-border-color);
}

.datatable thead th {
  font-weight: var(--mdb-datatable-thead-th-font-weight);
  border-bottom: none;
  position: relative;
}

.datatable thead th:hover .datatable-sort-icon {
  opacity: 1;
}

.datatable thead .fixed-cell {
  z-index: 3;
  box-shadow: var(--mdb-datatable-border-color) 0px 1px;
  background-color: var(--mdb-datatable-thead-fixed-cell-background-color);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.datatable tbody {
  font-weight: var(--mdb-datatable-tbody-font-weight);
  background-color: inherit;
}

.datatable tbody .fixed-cell {
  z-index: 1;
  background-color: inherit;
  position: -webkit-sticky;
  position: sticky;
}

.datatable tbody tr {
  background-color: inherit;
  transition: var(--mdb-datatable-tbody-tr-transition);
}

.datatable tbody tr:last-child {
  height: var(--mdb-datatable-tbody-tr-last-child-height);
  border-bottom: #0000;
}

.datatable tbody tr:focus {
  outline: none;
}

.datatable tbody tr.active {
  background-color: var(--mdb-datatable-active-color);
}

.datatable tbody td:focus {
  outline: none;
}

.datatable-loader {
  height: var(--mdb-datatable-tbody-loader-height);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.datatable-loader-inner {
  height: 100%;
  display: block;
}

.datatable-progress {
  animation: var(--mdb-datatable-tbody-progress-animation);
  width: var(--mdb-datatable-tbody-progress-width);
  opacity: var(--mdb-datatable-tbody-progress-opacity);
  border-radius: var(--mdb-datatable-tbody-progress-border-radius);
  height: 100%;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  display: block;
  position: relative;
}

@keyframes datatableProgress {
  0% {
    left: -45%;
  }

  100% {
    left: 100%;
  }
}

.datatable-pagination {
  padding: var(--mdb-datatable-pagination-padding-y) 0;
  border-top: var(--mdb-datatable-pagination-border-width) solid var(--mdb-datatable-border-color);
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.datatable-pagination-nav {
  font-size: var(--mdb-datatable-pagination-nav-font-size);
  direction: ltr;
  padding: 0;
}

.datatable-pagination-buttons {
  margin-left: var(--mdb-datatable-pagination-buttons-margin-left);
}

.datatable-pagination-button {
  padding-left: var(--mdb-datatable-pagination-button-padding-x);
  padding-right: var(--mdb-datatable-pagination-button-padding-x);
  color: var(--mdb-datatable-color);
}

.datatable-sort-icon {
  opacity: 0;
  color: var(--mdb-datatable-muted-color);
  cursor: pointer;
  transition-property: opacity, transform;
  transition-duration: var(--mdb-datatable-sort-icon-transition-duration);
  left: var(--mdb-datatable-sort-icon-left);
  top: var(--mdb-datatable-sort-icon-top);
  transition-timing-function: linear;
  position: absolute;
}

.datatable-sort-icon.active {
  opacity: 1;
  color: var(--mdb-datatable-color);
}

.datatable-select-wrapper {
  font-size: var(--mdb-datatable-select-wrapper-font-size);
  font-weight: var(--mdb-datatable-select-wrapper-font-weight);
  align-items: center;
  padding: 0 1rem;
  display: flex;
}

.datatable-select-wrapper .select-input {
  max-width: 70px;
}

.datatable-select-text {
  margin: 0 1rem;
}

.datatable.datatable-sm th, .datatable.datatable-sm td {
  padding: var(--mdb-datatable-sm-th-td-padding-y) var(--mdb-datatable-sm-th-td-padding-x);
}

.datatable.datatable-sm tbody tr:last-child {
  height: var(--mdb-datatable-sm-tbody-tr-last-child-height);
  border-bottom: #0000;
}

.datatable.datatable-sm .datatable-pagination {
  padding: var(--mdb-datatable-sm-pagination-padding) 0;
}

.datatable.datatable-bordered th, .datatable.datatable-bordered td {
  border-width: var(--mdb-datatable-bordered-th-td-border-width);
}

.datatable.datatable-bordered .datatable-pagination {
  border: var(--mdb-datatable-bordered-th-td-border-width) solid var(--mdb-datatable-border-color);
}

.datatable.datatable-striped tbody tr:nth-of-type(odd) {
  background-color: var(--mdb-datatable-accent-bg);
  color: var(--mdb-datatable-striped-color);
}

.datatable.datatable-striped tbody tr:nth-of-type(odd) td {
  background-color: var(--mdb-datatable-accent-bg);
}

.datatable.datatable-hover tbody tr {
  transition: var(--mdb-datatable-hover-tbody-tr-transition) !important;
}

.datatable.datatable-hover tbody tr:hover {
  background-color: var(--mdb-datatable-hover-bg) !important;
}

.datatable.datatable-hover tbody tr:hover td {
  background-color: var(--mdb-table-hover-bg);
}

.datatable.datatable-clickable-rows tbody tr {
  cursor: pointer;
}

.datatable.datatable-clickable-rows tbody tr.active {
  background-color: var(--mdb-datatable-hover-bg);
}

.datatable.datatable-clickable-rows tbody tr.active td {
  background-color: inherit;
  color: inherit;
}

.datatable.datatable-borderless {
  --mdb-datatable-border-color: transparent;
}

.datatable.datatable-loading, .datatable.datatable-loading th {
  color: var(--mdb-datatable-muted-color);
}

.datatable.datatable-loading .datatable-sort-icon {
  display: none;
}

@media (width <= 520px) {
  .datatable-pagination {
    flex-direction: column-reverse;
  }

  .datatable-pagination-nav {
    margin: .8rem 0;
  }

  .datatable-pagination-button {
    font-size: 1rem;
  }

  .datatable-pagination-buttons {
    margin: .8rem 0;
  }
}

.steps, .stepper {
  --mdb-steps-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out;
  transition: var(--mdb-steps-transition);
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.stepper {
  --mdb-stepper-padding-x: 1rem;
  --mdb-stepper-step-height: 4.5rem;
  --mdb-stepper-step-head-padding-left: 1.5rem;
  --mdb-stepper-step-head-padding-right: 1.5rem;
  --mdb-stepper-step-head-height: 1px;
  --mdb-stepper-step-head-bg: rgba(var(--mdb-emphasis-color-rgb), .1);
  --mdb-stepper-step-head-margin-right: .5rem;
  --mdb-stepper-step-head-margin-left: .5rem;
  --mdb-stepper-head-icon-margin-y: 1.5rem;
  --mdb-stepper-head-icon-margin-right: .5rem;
  --mdb-stepper-vertical-step-top: 3.25rem;
  --mdb-stepper-vertical-step-height: calc(100% - 2.45rem);
  --mdb-stepper-vertical-content-padding-left: 3.75rem;
  --mdb-stepper-vertical-content-transition: height .3s ease-in-out, margin-top .3s ease-in-out, margin-bottom .3s ease-in-out, padding-top .3s ease-in-out, padding-bottom .3s ease-in-out;
  --mdb-stepper-vertical-head-padding-bottom: 1.5rem;
  --mdb-stepper-mobile-step-margin-y: 1rem;
  --mdb-stepper-mobile-step-head-padding-x: .25rem;
  --mdb-stepper-mobile-head-icon-height: .5rem;
  --mdb-stepper-mobile-head-icon-width: .5rem;
  --mdb-stepper-mobile-content-top: 2.56rem;
  --mdb-stepper-mobile-active-head-icon-bg: var(--mdb-primary);
  --mdb-stepper-mobile-completed-head-icon-bg: var(--mdb-success);
  --mdb-stepper-head-icon-bg: var(--mdb-surface-inverted-bg);
  --mdb-stepper-head-icon-color: var(--mdb-surface-inverted-color);
  --mdb-stepper-completed-head-icon-bg: var(--mdb-success-bg-subtle);
  --mdb-stepper-completed-head-icon-color: var(--mdb-success-text-emphasis);
  --mdb-stepper-active-head-icon-bg: var(--mdb-primary-bg-subtle);
  --mdb-stepper-active-head-icon-color: var(--mdb-primary-text-emphasis);
  --mdb-stepper-invalid-head-icon-bg: var(--mdb-danger-bg-subtle);
  --mdb-stepper-invalid-head-icon-color: var(--mdb-danger-text-emphasis);
  --mdb-stepper-disabled-head-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-stepper-disabled-head-icon-bg: var(--mdb-surface-inverted-bg);
  --mdb-stepper-disabled-head-icon-color: rgba(var(--mdb-surface-inverted-color-rgb), .55);
  --mdb-stepper-mobile-head-padding-y: .5rem;
  --mdb-stepper-mobile-head-padding-x: 1rem;
  --mdb-stepper-mobile-footer-height: 2.5rem;
  --mdb-stepper-back-btn-i-margin-right: .5rem;
  --mdb-stepper-next-btn-i-margin-left: .5rem;
  --mdb-stepper-mobile-progress-bar-height: .3rem;
  --mdb-stepper-mobile-progress-height: .3rem;
  --mdb-stepper-mobile-progress-background-color: var(--mdb-secondary-bg);
  --mdb-stepper-mobile-active-progress-bar-color: var(--mdb-primary);
  --mdb-stepper-mobile-footer-bg: var(--mdb-stepper-mobile-bg);
  --mdb-stepper-mobile-head-bg: var(--mdb-stepper-mobile-bg);
  --mdb-stepper-mobile-invalid-icon-bg: var(--mdb-danger);
}

.stepper:not(.stepper-vertical) {
  justify-content: space-between;
  display: flex;
}

.stepper-form {
  display: inherit;
  justify-content: inherit;
  width: inherit;
  position: inherit;
}

.sticky.animation {
  animation-duration: .2s;
}

.select-dropdown .form-check-input label {
  display: block;
}

select.select-initialized {
  display: none !important;
}

.select-wrapper {
  --mdb-form-outline-select-arrow-color: var(--mdb-surface-color);
  --mdb-form-outline-select-arrow-font-size: 16px;
  --mdb-form-outline-select-arrow-top: 7px;
  --mdb-form-outline-select-arrow-right: 16px;
  --mdb-form-outline-select-valid-color: #00b74a;
  --mdb-form-outline-select-invalid-color: #f93154;
  --mdb-form-outline-select-clear-btn-color: var(--mdb-surface-color);
  --mdb-form-outline-select-clear-btn-font-size: 1rem;
  --mdb-form-outline-select-clear-btn-top: 7px;
  --mdb-form-outline-select-clear-btn-right: 27px;
  --mdb-form-outline-select-clear-btn-focus-color: #3b71ca;
  --mdb-form-outline-select-sm-clear-btn-font-size: .8rem;
  --mdb-form-outline-select-sm-clear-btn-top: 4px;
  --mdb-form-outline-select-lg-clear-btn-top: 11px;
  --mdb-form-outline-select-label-max-width: 80%;
  --mdb-form-outline-select-label-active-transform: translateY(-1rem) translateY(.1rem) scale(.8);
  --mdb-form-outline-select-lg-label-active-transform: translateY(-1.25rem) translateY(.1rem) scale(.8);
  --mdb-form-outline-select-sm-label-active-transform: translateY(-.83rem) translateY(.1rem) scale(.8);
  --mdb-form-outline-select-input-focused-color: var(--mdb-surface-color);
  --mdb-form-outline-select-label-color: #3b71ca;
  --mdb-form-outline-select-notch-border-color: #3b71ca;
  --mdb-form-outline-select-white-notch-border-color: #fff;
  --mdb-form-outline-select-input-focused-arrow-color: #3b71ca;
  --mdb-form-outline-select-white-focus-arrow-color: #fff;
  --mdb-form-outline-select-white-arrow-color: #fff;
  --mdb-form-outline-select-white-clear-btn: #fff;
  --mdb-form-outline-select-sm-arrow-top: 3px;
  --mdb-form-outline-select-lg-arrow-top: 11px;
  --mdb-form-outline-form-notch-border-top: 1px solid transparent;
}

.select-arrow {
  color: var(--mdb-form-outline-select-arrow-color);
  text-align: center;
  font-size: var(--mdb-form-outline-select-arrow-font-size);
  top: var(--mdb-form-outline-select-arrow-top);
  right: var(--mdb-form-outline-select-arrow-right);
  position: absolute;
}

.select-arrow:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.select-arrow:empty:after {
  margin-left: 0;
}

.was-validated .form-control:valid ~ .select-arrow {
  color: var(--mdb-form-outline-select-valid-color);
}

.was-validated .form-control:invalid ~ .select-arrow {
  color: var(--mdb-form-outline-select-invalid-color);
}

.select-clear-btn {
  color: var(--mdb-form-outline-select-clear-btn-color);
  font-size: var(--mdb-form-outline-select-clear-btn-font-size);
  top: var(--mdb-form-outline-select-clear-btn-top);
  right: var(--mdb-form-outline-select-clear-btn-right);
  cursor: pointer;
  position: absolute;
}

.select-clear-btn:focus {
  color: var(--mdb-form-outline-select-clear-btn-focus-color);
  outline: none;
}

.form-control-sm ~ .select-clear-btn {
  font-size: var(--mdb-form-outline-select-sm-clear-btn-font-size);
  top: var(--mdb-form-outline-select-sm-clear-btn-top);
}

.form-control-lg ~ .select-clear-btn {
  top: var(--mdb-form-outline-select-lg-clear-btn-top);
}

.select-dropdown-container {
  --mdb-form-outline-select-dropdown-container-z-index: 1070;
  --mdb-form-outline-select-dropdown-bg: var(--mdb-surface-bg);
  --mdb-form-outline-select-dropdown-box-shadow: 0 2px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .16), 0 2px 10px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  --mdb-form-outline-select-dropdown-min-width: 100px;
  --mdb-form-outline-select-dropdown-transform: scaleY(.8);
  --mdb-form-outline-select-dropdown-transition: all .2s;
  --mdb-form-outline-select-dropdown-open-transform: scaleY(1);
  --mdb-form-outline-select-dropdown-input-group-padding: 10px;
  --mdb-form-outline-select-options-wrapper-scrollbar-width: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-height: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-thumb-height: 50px;
  --mdb-form-outline-select-options-wrapper-scrollbar-thumb-bg: var(--mdb-scrollbar-thumb-bg);
  --mdb-form-outline-select-options-wrapper-scrollbar-thumb-border-radius: 4px;
  --mdb-form-outline-select-no-results-padding-left: 16px;
  --mdb-form-outline-select-no-results-padding-right: 16px;
  z-index: 1070;
  z-index: var(--mdb-form-outline-select-dropdown-container-z-index);
}

.select-dropdown {
  background-color: var(--mdb-form-outline-select-dropdown-bg);
  box-shadow: var(--mdb-form-outline-select-dropdown-box-shadow);
  min-width: var(--mdb-form-outline-select-dropdown-min-width);
  transform: var(--mdb-form-outline-select-dropdown-transform);
  opacity: 0;
  transition: var(--mdb-form-outline-select-dropdown-transition);
  outline: 0;
  margin: 0;
  position: relative;
}

.select-dropdown.open {
  transform: var(--mdb-form-outline-select-dropdown-open-transform);
  opacity: 1;
}

.select-dropdown > .input-group {
  padding: var(--mdb-form-outline-select-dropdown-input-group-padding);
}

.select-label {
  max-width: var(--mdb-form-outline-select-label-max-width);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select-label.active {
  transform: var(--mdb-form-outline-select-label-active-transform);
}

.form-control-lg ~ .select-label.active {
  transform: var(--mdb-form-outline-select-lg-label-active-transform);
}

.form-control-sm ~ .select-label.active {
  transform: var(--mdb-form-outline-select-sm-label-active-transform);
}

.form-outline .select-label.active ~ .form-notch .form-notch-middle {
  border-left: none;
  border-right: none;
  border-top: var(--mdb-form-outline-form-notch-border-top);
}

.select-input.focused, .form-outline .form-control.select-input:focus {
  color: var(--mdb-form-outline-select-input-focused-color);
  outline: 0;
}

.select-input.focused ~ .select-label, .form-outline .form-control.select-input:focus ~ .select-label {
  color: var(--mdb-form-outline-select-label-color);
}

.select-input.focused::placeholder, .form-outline .form-control.select-input:focus::placeholder {
  opacity: 1;
}

.select-input.focused ~ .form-notch .form-notch-leading, .form-outline .form-control.select-input:focus ~ .form-notch .form-notch-leading {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: -1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.select-input.focused ~ .form-notch .form-notch-trailing, .form-outline .form-control.select-input:focus ~ .form-notch .form-notch-trailing {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: var(--mdb-form-outline-form-notch-border-top);
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.select-input.focused ~ .select-arrow {
  color: var(--mdb-form-outline-select-input-focused-arrow-color);
}

.form-control-sm ~ .select-arrow {
  top: var(--mdb-form-outline-select-sm-arrow-top);
}

.form-control-lg ~ .select-arrow {
  top: var(--mdb-form-outline-select-lg-arrow-top);
}

.select-options-wrapper {
  overflow-y: auto;
}

.select-options-wrapper::-webkit-scrollbar {
  width: var(--mdb-form-outline-select-options-wrapper-scrollbar-width);
  height: var(--mdb-form-outline-select-options-wrapper-scrollbar-height);
}

.select-options-wrapper::-webkit-scrollbar-button:start:decrement {
  background-color: #0000;
  height: 0;
  display: block;
}

.select-options-wrapper::-webkit-scrollbar-button:end:increment {
  background-color: #0000;
  height: 0;
  display: block;
}

.select-options-wrapper::-webkit-scrollbar-track-piece {
  border-radius: 0;
  border-bottom-right-radius: var(--mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius);
  border-bottom-left-radius: var(--mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius);
  background-color: #0000;
}

.select-options-wrapper::-webkit-scrollbar-thumb:vertical {
  height: var(--mdb-form-outline-select-options-wrapper-scrollbar-thumb-height);
  background-color: var(--mdb-form-outline-select-options-wrapper-scrollbar-thumb-bg);
  border-radius: var(--mdb-form-outline-select-options-wrapper-scrollbar-thumb-border-radius);
}

.select-options-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select-option-group-label {
  --mdb-form-outline-select-option-group-label-padding-left: 16px;
  --mdb-form-outline-select-option-group-label-padding-right: 16px;
  --mdb-form-outline-select-option-group-label-font-size: 1rem;
  --mdb-form-outline-select-option-group-label-font-weight: 400;
  --mdb-form-outline-select-option-group-label-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 16px;
  padding-left: var(--mdb-form-outline-select-option-group-label-padding-left);
  padding-right: 16px;
  padding-right: var(--mdb-form-outline-select-option-group-label-padding-right);
  font-size: 1rem;
  font-size: var(--mdb-form-outline-select-option-group-label-font-size);
  font-weight: 400;
  font-weight: var(--mdb-form-outline-select-option-group-label-font-weight);
  color: var(--mdb-form-outline-select-option-group-label-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #0000;
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.select-option-group > .select-option {
  --mdb-form-outline-select-option-group-select-option-padding-left: 26px;
  padding-left: 26px;
  padding-left: var(--mdb-form-outline-select-option-group-select-option-padding-left);
}

.select-option {
  --mdb-form-outline-select-option-color: var(--mdb-surface-color);
  --mdb-form-outline-select-option-padding-left: 16px;
  --mdb-form-outline-select-option-padding-right: 16px;
  --mdb-form-outline-select-option-font-size: 1rem;
  --mdb-form-outline-select-option-font-weight: 400;
  --mdb-form-outline-select-option-hover-not-disabled-bg: var(--mdb-highlight-bg-color);
  --mdb-form-outline-select-option-active-bg: var(--mdb-highlight-bg-color);
  --mdb-form-outline-select-option-selected-active-bg: #3b71ca73;
  --mdb-form-outline-select-option-selected-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-form-outline-select-option-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-form-outline-select-option-text-form-check-input-margin-right: 10px;
  --mdb-form-outline-select-option-secondary-text-font-size: .8rem;
  --mdb-form-outline-select-option-secondary-text-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-form-outline-select-option-icon-width: 28px;
  --mdb-form-outline-select-option-icon-height: 28px;
  --mdb-form-outline-select-white-arrow: #fff;
  --mdb-form-outline-select-option-disabled-secondary-text-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-form-outline-select-option-selected-bg: #3b71ca4d;
  --mdb-form-outline-select-option-selected-hover-bg: #3b71ca73;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--mdb-form-outline-select-option-color);
  padding-left: 16px;
  padding-left: var(--mdb-form-outline-select-option-padding-left);
  padding-right: 16px;
  padding-right: var(--mdb-form-outline-select-option-padding-right);
  font-size: 1rem;
  font-size: var(--mdb-form-outline-select-option-font-size);
  font-weight: 400;
  font-weight: var(--mdb-form-outline-select-option-font-weight);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #0000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.select-option:hover:not(.disabled) {
  background-color: var(--mdb-form-outline-select-option-hover-not-disabled-bg);
}

.select-option.active {
  background-color: var(--mdb-form-outline-select-option-active-bg);
}

.select-option.selected.active {
  background-color: var(--mdb-form-outline-select-option-selected-active-bg);
}

.select-option.selected:hover:not(.disabled) {
  background-color: var(--mdb-form-outline-select-option-selected-hover-bg);
}

.select-option.selected {
  background-color: var(--mdb-form-outline-select-option-selected-bg);
}

.select-option.selected.disabled {
  cursor: default;
  color: var(--mdb-form-outline-select-option-selected-disabled-color);
  background-color: #0000;
}

.select-option.disabled {
  cursor: default;
  color: var(--mdb-form-outline-select-option-disabled-color);
}

.select-option.disabled .select-option-secondary-text {
  color: var(--mdb-form-outline-select-option-disabled-secondary-text-color);
}

.select-option-text .form-check-input {
  margin-right: var(--mdb-form-outline-select-option-text-form-check-input-margin-right);
}

.select-option-secondary-text {
  font-size: var(--mdb-form-outline-select-option-secondary-text-font-size);
  color: var(--mdb-form-outline-select-option-secondary-text-color);
  line-height: normal;
  display: block;
}

.select-option-icon {
  width: var(--mdb-form-outline-select-option-icon-width);
  height: var(--mdb-form-outline-select-option-icon-height);
}

.select-custom-content {
  --mdb-form-outline-select-custom-content-padding: 16px;
  padding: 16px;
  padding: var(--mdb-form-outline-select-custom-content-padding);
}

.select-no-results {
  padding-left: var(--mdb-form-outline-select-no-results-padding-left);
  padding-right: var(--mdb-form-outline-select-no-results-padding-right);
  align-items: center;
  display: flex;
}

.form-white .select-input.focused ~ .select-arrow {
  color: var(--mdb-form-outline-select-white-arrow-color);
}

.form-white .select-input:focus ~ .select-arrow {
  color: var(--mdb-form-outline-select-white-focus-arrow-color);
}

.form-white .select-arrow {
  color: var(--mdb-form-outline-select-white-arrow-color);
}

.form-white .select-clear-btn {
  color: var(--mdb-form-outline-select-white-clear-btn);
}

.form-white .select-input.focused, .form-white .form-control.select-input:focus, .form-white .select-input.focused ~ .select-label, .form-white .form-control.select-input:focus ~ .select-label {
  color: #fff;
}

.form-white .select-input.focused ~ .form-notch .form-notch-leading, .form-white .form-control.select-input:focus ~ .form-notch .form-notch-leading {
  border-color: var(--mdb-form-outline-select-white-notch-border-color);
  box-shadow: -1px 0 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-white-notch-border-color);
}

.form-white .select-input.focused ~ .form-notch .form-notch-trailing, .form-white .form-control.select-input:focus ~ .form-notch .form-notch-trailing {
  border-color: var(--mdb-form-outline-select-white-notch-border-color);
  box-shadow: 1px 0 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-white-notch-border-color);
}

.form-outline .form-control ~ .form-label.select-fake-value, .form-outline .form-control:focus ~ .form-label.select-fake-value, .form-outline .form-control.active ~ .form-label.select-fake-value {
  display: none;
  transform: none;
}

.form-outline .form-control ~ .form-label.select-fake-value.active, .form-outline .form-control:focus ~ .form-label.select-fake-value.active, .form-outline .form-control.active ~ .form-label.select-fake-value.active {
  display: block;
}

.loading-spinner {
  --mdb-loading-spinner-top: 50%;
  --mdb-loading-spinner-left: 50%;
  --mdb-loading-spinner-transform: translate(-50%, -50%);
  --mdb-loading-spinner-color: #3b71ca;
  top: 50%;
  top: var(--mdb-loading-spinner-top);
  left: 50%;
  left: var(--mdb-loading-spinner-left);
  transform: translate(-50%, -50%);
  transform: var(--mdb-loading-spinner-transform);
  color: var(--mdb-loading-spinner-color);
  z-index: 1056;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.loading-backdrop {
  background-color: #0006;
  background-color: var(--mdb-datepicker-backdrop-background-color);
  z-index: 1065;
  z-index: var(--mdb-datepicker-zindex);
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.autocomplete-label {
  --mdb-autocomplete-label-max-width: 80%;
  --mdb-autocomplete-label-active-transform: translateY(-1rem) translateY(.1rem) scale(.8);
  --mdb-autocomplete-label-color: #3b71ca;
  max-width: var(--mdb-autocomplete-label-max-width);
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
}

.autocomplete-label.active {
  transform: var(--mdb-autocomplete-label-active-transform);
}

.form-outline .autocomplete-label.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-left: none;
  border-right: none;
}

.select-input {
  cursor: pointer;
}

.select-input[disabled] {
  cursor: default;
}

.form-outline {
  --mdb-form-outline-select-notch-border-color: #3b71ca;
}

.autocomplete-input.focused {
  --mdb-autocomplete-input-focused-color: var(--mdb-surface-color);
  color: var(--mdb-autocomplete-input-focused-color);
  outline: 0;
}

.autocomplete-input.focused ~ .autocomplete-label {
  color: var(--mdb-autocomplete-label-color);
}

.autocomplete-input.focused::placeholder {
  opacity: 1;
}

.autocomplete-input.focused ~ .form-notch .form-notch-leading {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: -1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.autocomplete-input.focused ~ .form-notch .form-notch-middle {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
  border-top: 1px solid #0000;
}

.autocomplete-input.focused ~ .form-notch .form-notch-trailing {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.autocomplete-dropdown-container {
  --mdb-autocomplete-dropdown-container-zindex: 1065;
  --mdb-autocomplete-dropdown-background-color: var(--mdb-surface-bg);
  --mdb-autocomplete-dropdown-box-shadow: 0 2px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .16), 0 2px 10px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  --mdb-autocomplete-dropdown-margin: 0;
  --mdb-autocomplete-dropdown-transform: scaleY(.8);
  --mdb-autocomplete-dropdown-transition: all .2s;
  --mdb-autocomplete-dropdown-open-transform: scaleY(1);
  --mdb-autocomplete-item-color: var(--mdb-surface-color);
  --mdb-autocomplete-item-padding: 6.5px 16px;
  --mdb-autocomplete-item-font-size: 1rem;
  --mdb-autocomplete-item-font-weight: 400;
  --mdb-autocomplete-item-hover-background-color: var(--mdb-highlight-bg-color);
  --mdb-autocomplete-item-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-autocomplete-scrollbar-thumb-background-color: var(--mdb-scrollbar-thumb-bg);
  z-index: 1065;
  z-index: var(--mdb-autocomplete-dropdown-container-zindex);
}

.autocomplete-dropdown {
  background-color: var(--mdb-autocomplete-dropdown-background-color);
  box-shadow: var(--mdb-autocomplete-dropdown-box-shadow);
  margin: var(--mdb-autocomplete-dropdown-margin);
  transform: var(--mdb-autocomplete-dropdown-transform);
  opacity: 0;
  transition: var(--mdb-autocomplete-dropdown-transition);
  outline: 0;
  position: relative;
}

.autocomplete-dropdown.open {
  transform: var(--mdb-autocomplete-dropdown-open-transform);
  opacity: 1;
}

.autocomplete-items-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.autocomplete-items-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.autocomplete-items-list::-webkit-scrollbar-button:start:decrement {
  background-color: #0000;
  height: 0;
  display: block;
}

.autocomplete-items-list::-webkit-scrollbar-button:end:increment {
  background-color: #0000;
  height: 0;
  display: block;
}

.autocomplete-items-list::-webkit-scrollbar-track-piece {
  background-color: #0000;
  border-radius: 0 0 4px 4px;
}

.autocomplete-items-list::-webkit-scrollbar-thumb:vertical {
  background-color: var(--mdb-autocomplete-scrollbar-thumb-background-color);
  border-radius: 4px;
  height: 50px;
}

.autocomplete-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--mdb-autocomplete-item-color);
  padding: var(--mdb-autocomplete-item-padding);
  font-size: var(--mdb-autocomplete-item-font-size);
  font-weight: var(--mdb-autocomplete-item-font-weight);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #0000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.autocomplete-item:hover:not(.disabled), .autocomplete-item.active {
  background-color: var(--mdb-autocomplete-item-hover-background-color);
}

.autocomplete-item.disabled {
  cursor: default;
  color: var(--mdb-autocomplete-item-disabled-color);
}

.autocomplete-loader {
  border-width: .15em;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 8px;
  right: 5px;
}

.form-outline .autocomplete-input.form-control.focusing ~ .form-notch .form-notch-middle {
  transition: none;
}

.chip {
  --mdb-chip-height: 32px;
  --mdb-chip-line-height: 2;
  --mdb-chip-padding-right: 12px;
  --mdb-chip-margin-y: 5px;
  --mdb-chip-margin-right: 1rem;
  --mdb-chip-font-size: 13px;
  --mdb-chip-font-weight: 400;
  --mdb-chip-font-color: var(--mdb-surface-color);
  --mdb-chip-bg: var(--mdb-tertiary-bg);
  --mdb-chip-border-radius: 16px;
  --mdb-chip-transition-opacity: .3s linear;
  --mdb-chip-img-margin-right: 8px;
  --mdb-chip-img-margin-left: -12px;
  --mdb-chip-close-padding-left: 8px;
  --mdb-chip-close-font-size: 16px;
  --mdb-chip-close-opacity: .53;
  --mdb-chip-outline-border-width: 1px;
  --mdb-chip-md-height: 42px;
  --mdb-chip-md-br: 21px;
  --mdb-chip-lg-height: 52px;
  --mdb-chip-lg-br: 26px;
  --mdb-chip-contenteditable-border-width: 3px;
  --mdb-chip-contenteditable-border-color: var(--mdb-border-color);
  --mdb-chip-icon-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-chip-icon-transition: .2s ease-in-out;
  --mdb-chip-icon-hover-color: rgba(var(--mdb-emphasis-color-rgb), .4);
  height: var(--mdb-chip-height);
  line-height: 2;
  line-height: var(--mdb-chip-line-height);
  padding: 0 12px;
  padding: 0 var(--mdb-chip-padding-right);
  margin-top: 5px;
  margin-top: var(--mdb-chip-margin-y);
  margin-bottom: 5px;
  margin-bottom: var(--mdb-chip-margin-y);
  margin-right: 1rem;
  margin-right: var(--mdb-chip-margin-right);
  font-size: 13px;
  font-size: var(--mdb-chip-font-size);
  font-weight: 400;
  font-weight: var(--mdb-chip-font-weight);
  color: var(--mdb-chip-font-color);
  cursor: pointer;
  background-color: #fbfbfb;
  background-color: var(--mdb-chip-bg);
  border-radius: 16px;
  border-radius: var(--mdb-chip-border-radius);
  transition: opacity .3s linear;
  transition: opacity var(--mdb-chip-transition-opacity);
  word-wrap: break-word;
  box-shadow: none;
  text-transform: none;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  display: flex;
}

.chip:hover {
  box-shadow: none !important;
}

.chip:hover:not(.chip-outline) {
  background-color: var(--mdb-chip-bg);
  box-shadow: none !important;
}

.chip:active, .chip.active {
  background-color: var(--mdb-chip-bg);
}

.chip img {
  width: inherit;
  height: inherit;
  margin: 0 var(--mdb-chip-img-margin-right) 0 var(--mdb-chip-img-margin-left);
  border-radius: 100%;
}

.chip .close {
  float: right;
  padding-left: var(--mdb-chip-close-padding-left);
  font-size: var(--mdb-chip-close-font-size);
  opacity: var(--mdb-chip-close-opacity);
  cursor: pointer;
}

.chip .close:hover {
  color: #332d2d;
}

.chip .close-opacity {
  opacity: 0;
}

.chip[contenteditable="true"] {
  border: var(--mdb-chip-contenteditable-border-width) solid var(--mdb-chip-contenteditable-border-color);
  background-color: #fff;
  outline: none;
}

.chip .far, .chip .fas {
  color: var(--mdb-chip-icon-color);
  transition: all var(--mdb-chip-icon-transition);
}

.chip .far:hover, .chip .fas:hover {
  color: var(--mdb-chip-icon-hover-color);
}

.chips {
  --mdb-chips-min-height: 45px;
  --mdb-chips-padding-bottom: 1rem;
  --mdb-chips-margin-bottom: 30px;
  --mdb-chips-transition: .3s ease;
  --mdb-chips-padding-padding: 5px;
  --mdb-chips-input-width: 150px;
  min-height: var(--mdb-chips-min-height);
  padding-bottom: 1rem;
  padding-bottom: var(--mdb-chips-padding-bottom);
  margin-bottom: 30px;
  margin-bottom: var(--mdb-chips-margin-bottom);
  box-shadow: none;
  transition: all .3s;
  transition: all var(--mdb-chips-transition);
  border: none;
  outline: none;
  min-height: 45px;
}

.chips:hover {
  cursor: text;
}

.chips .tag.selected {
  color: #fff;
}

.chip-opacity {
  opacity: 0;
}

.form-control.chips-input.active {
  box-shadow: none;
}

.multi-range-slider {
  --mdb-multi-range-sliderhand-focus-box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #3b71ca40;
  --mdb-multi-range-slider-horizontal-height: .25rem;
  --mdb-multi-range-hand-width: 1rem;
  --mdb-multi-range-hand-height: 1rem;
  --mdb-multi-range-hand-bg: #3b71ca;
  --mdb-multi-range-hand-border-radius: 50% 50% 50% 0;
  --mdb-multi-range-hand-box-shadow: 0 .1rem .25rem #0000001a;
  --mdb-multi-range-hand-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  --mdb-multi-range-hand-active-bg: #c4d4ef;
  --mdb-multi-range-track-bg: var(--mdb-secondary-bg);
  --mdb-multi-range-tooltip-value-color: #fff;
  --mdb-multi-range-tooltip-transition: top .2s, transform .2s, border-radius .2s;
  --mdb-multi-range-tooltip-border-radius: 50% 50% 50% 0;
  --mdb-multi-range-tooltip-transform: rotate(-45deg) translate(-5px, -4px) scale(0);
  --mdb-multi-range-tooltip-transform-active: rotate(-45deg) translate(-5px, -4px) scale(1);
  --mdb-multi-range-tooltip-content-size: 30px;
  --mdb-multi-range-tooltip-content-transform: rotate(45deg) translateY(25%);
  --mdb-multi-range-tooltip-content-font-size: 10px;
  --mdb-multi-range-tooltip-position-top: -18px;
  cursor: pointer;
  border: 1px solid #eee;
  border: 1px solid var(--mdb-multi-range-track-bg);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #0000;
  outline: none;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  position: relative;
}

.multi-range-slider:focus {
  box-shadow: var(--mdb-multi-range-sliderhand-focus-box-shadow);
  outline: none;
}

.multi-range-slider::-moz-focus-outer {
  border: 0;
}

.multi-range-slider-connects {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.multi-range-slider-horizontal {
  height: var(--mdb-multi-range-slider-horizontal-height);
}

.multi-range-slider-hand {
  width: var(--mdb-multi-range-hand-width);
  height: var(--mdb-multi-range-hand-height);
  background-color: var(--mdb-multi-range-hand-bg);
  transition: var(--mdb-multi-range-thumb-transition);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 5;
  cursor: pointer;
  will-change: transform;
  transform-origin: 0 0;
  transform-style: flat;
  border: 0;
  border-radius: 50%;
  margin-top: -.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-25%);
}

@media (prefers-reduced-motion: reduce) {
  .multi-range-slider-hand {
    transition: none;
  }
}

.multi-range-slider-hand:active, .multi-range-slider-hand.active {
  z-index: 6;
  background-color: var(--mdb-multi-range-hand-active-bg);
}

.multi-range-slider-connect {
  background-color: var(--mdb-multi-range-track-bg);
  will-change: transform;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.multi-range-slider:focus {
  outline: none;
}

.multi-range-slider-tooltip {
  top: var(--mdb-multi-range-tooltip-position-top);
  transform-origin: 50%;
  transition: var(--mdb-multi-range-tooltip-transition);
  color: var(--mdb-multi-range-tooltip-value-color);
  border-radius: var(--mdb-multi-range-tooltip-border-radius);
  transform: var(--mdb-multi-range-tooltip-transform);
  background-color: #3b71ca;
  border: none;
  position: absolute;
}

.multi-range-slider-tooltip-value {
  transform: var(--mdb-multi-range-tooltip-content-transform);
  color: #fff;
  height: var(--mdb-multi-range-tooltip-content-size);
  font-size: var(--mdb-multi-range-tooltip-content-font-size);
  width: var(--mdb-multi-range-tooltip-content-size);
  text-align: center;
  display: block;
}

.multi-range-slider-tooltip.active {
  transform: var(--mdb-multi-range-tooltip-transform-active);
  top: -38px;
}

.buttons-container {
  --mdb-datetimepicker-buttons-container-background-color: var(--mdb-picker-header-bg);
  --mdb-datetimepicker-button-toggle-width: 50%;
  --mdb-datetimepicker-button-toggle-color: white;
  --mdb-datetimepicker-button-toggle-font-size: 23px;
  --mdb-datetimepicker-button-toggle-border-radius: 10px;
  --mdb-datetimepicker-button-toggle-min-height: 40px;
  --mdb-datetimepicker-button-toggle-hover-background-color: #00000026;
  background-color: #3b71ca;
  background-color: var(--mdb-datetimepicker-buttons-container-background-color);
  justify-content: space-evenly;
  align-items: flex-end;
  display: flex;
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .buttons-container {
    flex-grow: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .timepicker-elements .buttons-container {
    flex-direction: column;
    align-items: center;
    padding-right: var(--mdb-timepicker-head-media-padding-right) !important;
  }

  .timepicker-elements .buttons-container .datepicker-button-toggle, .timepicker-elements .buttons-container .timepicker-button-toggle {
    width: 100%;
  }
}

.datepicker-button-toggle, .timepicker-button-toggle {
  width: var(--mdb-datetimepicker-button-toggle-width);
  color: var(--mdb-datetimepicker-button-toggle-color);
  font-size: var(--mdb-datetimepicker-button-toggle-font-size);
  border-radius: var(--mdb-datetimepicker-button-toggle-border-radius);
  min-height: var(--mdb-datetimepicker-button-toggle-min-height);
  background-color: #0000;
  border: none;
  outline: none;
}

.datepicker-button-toggle:hover, .timepicker-button-toggle:hover {
  background-color: var(--mdb-datetimepicker-button-toggle-hover-background-color);
  outline: none;
}

.datepicker-button-toggle i, .timepicker-button-toggle i {
  pointer-events: none;
}

.datetimepicker-toggle-button {
  --mdb-datetimepicker-toggle-button-transform: translate(-50%, -50%);
  right: -10px;
  right: var(--mdb-datepicker-toggle-right);
  top: 50%;
  top: var(--mdb-datepicker-toggle-top);
  transform: translate(-50%, -50%);
  transform: var(--mdb-datetimepicker-toggle-button-transform);
  background-color: #0000;
  border: none;
  outline: none;
  position: absolute;
}

.datetimepicker-toggle-button:focus, .datetimepicker-toggle-button:hover {
  color: var(--mdb-datepicker-toggle-focus-color);
}

.multi-carousel {
  position: relative;
}

.multi-carousel .multi-carousel-inner {
  white-space: nowrap;
  width: 100%;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.multi-carousel .multi-carousel-inner .multi-carousel-item {
  width: 33.3%;
  padding: 0 3px;
  font-size: 16px;
  transition: all .3s ease-out;
  display: inline-block;
}

.multi-carousel .multi-carousel-inner .multi-carousel-item img {
  object-fit: cover;
  max-height: 200px;
}

.multi-carousel.vertical {
  height: 350px;
}

@media (width <= 768px) {
  .multi-carousel.vertical {
    height: 150px;
  }
}

.multi-carousel.vertical .multi-carousel-inner {
  height: 100%;
}

.multi-carousel.vertical .multi-carousel-inner .multi-carousel-item {
  width: 100%;
  padding: 2px 0;
}

.multi-carousel.vertical .multi-carousel-inner img {
  max-height: none;
}

.multi-carousel.vertical .carousel-control-prev {
  width: 100%;
  height: 15%;
}

.multi-carousel.vertical .carousel-control-next {
  width: 100%;
  height: 15%;
  top: auto;
}

.multi-carousel.vertical .carousel-control-prev span, .multi-carousel.vertical .carousel-control-next span {
  transform: rotate(90deg);
}

.popover {
  min-width: 320px;
}

.popover:focus {
  outline: none;
}

.transfer {
  --mdb-transfer-text-color: var(--mdb-surface-color);
  --mdb-transfer-container-bg: var(--mdb-surface-bg);
  --mdb-transfer-divider-color: var(--mdb-divider-color);
  --mdb-transfer-text-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-transfer-box-shadow-color-rgb: var(--mdb-box-shadow-color-rgb);
  color: var(--mdb-transfer-text-color);
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  display: flex;
}

:root {
  --bs-body-bg: #f7f7f7;
  --bs-orange-rgb: #fd7e14;
}

:root ol, :root ul {
  margin-bottom: 0;
  padding-left: 0;
}

:root .xs-small {
  font-size: .8rem;
}

.header-middle li {
  list-style: none;
}

.call a {
  color: #54b4d3;
  text-decoration: none;
}

.chat a {
  color: #47a447;
  text-decoration: none;
}

@media (width >= 576px) {
  .mt-sm-n45 {
    margin-top: -4.5rem !important;
  }
}

.footer-nav {
  margin-top: 15px;
  padding: 0;
}

.footer-nav li {
  list-style: none;
  display: inline;
}

.footer-nav li a {
  color: #fff;
  margin-right: 5px;
  font-size: .85em;
  line-height: .7;
  text-decoration: none;
  transition: color .2s linear;
}

.footer-nav li a:hover {
  color: #fd7e14;
}

.navbar-dark .navbar-toggler-icon {
  background-image: var(--mdb-navbar-toggler-icon-bg);
}
/*# sourceMappingURL=index.0893514d.css.map */
